import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { UpcomingEventStep } from '@features/Quiz/components/UpcomingEventStep';
import { FormattedMessage } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './when-event.module.scss';

const WhenEventDisclaimer = () => (
  <div className={styles.disclaimerContainer}>
    <Image src="woman-no-emoji.webp" alt="" width={40} height={40} />
    <p>
      <FormattedMessage
        defaultMessage="We are not sharing this data with any third parties."
        id="Onboarding.WhenEvent.Tip"
      />
    </p>
  </div>
);

export const WhenEvent = observer(
  ({ goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { whenEvent, setWhenEvent },
    } = useStores();

    const dateValue = whenEvent;

    const handleNextPress = useCallback(
      (value: Date | null) => {
        handleEventSend &&
          handleEventSend({
            eventName: 'onboarding_when_event_chosen',
            options: { answer: value === null ? 'Skip' : value },
          });
        setWhenEvent(value);
        goToNextStep();
      },
      [goToNextStep, handleEventSend, setWhenEvent],
    );

    return (
      <StepContainer
        isQuestionStep
        contentContainerClassName={styles.container}
      >
        <UpcomingEventStep
          onChange={handleNextPress}
          value={dateValue}
          extraContent={<WhenEventDisclaimer />}
        />
      </StepContainer>
    );
  },
);
