import { WeightProgress, usePersonalPlan } from '@features/PersonalPlan';
import { IconScales } from '@features/PersonalPlan/icons/IconScales';
import { IconTarget } from '@features/PersonalPlan/icons/IconTarget';

import { StartTargetPersonal } from '../../components/StartTargetPersonal';
import styles from './Main.module.scss';

export const Main = () => {
  const { weightProgress, goalLabel } = usePersonalPlan();

  return (
    <>
      <StartTargetPersonal />
      <h2 className={styles.title}>
        Your Personal Pilates Workout Plan is ready!
      </h2>

      <div className={styles.total}>
        <span className={styles.verticalLine} />
        <div className={styles.item}>
          <IconTarget />
          <span>
            Optimized for{' '}
            <strong className={styles.highlighted}>{goalLabel}</strong>
          </span>
        </div>

        <div className={styles.item}>
          <IconScales />
          <span>
            Your progress{' '}
            <strong className={styles.highlighted}>{weightProgress}</strong>
          </span>
        </div>
      </div>

      <WeightProgress />
    </>
  );
};
