import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';

import { TpQuizStepQuestionProps } from '../../types';

export const HowFastReachGoal = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setHowFastReachGoal },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOption
        stepName={stepName}
        name="how-fast-reach-goal"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setHowFastReachGoal}
        goToNextStep={goToNextStep}
        eventName="onboarding_how_fast_reach_goal_chosen"
      />
    );
  },
);
