import classNames from 'classnames';
import React from 'react';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { FormattedMessage, TpMessageKeys } from '@features/intl';

import styles from './BreaksWithLoader.module.scss';

type Props = {
  currentCard: {
    image: React.ReactNode;
    titleId: TpMessageKeys;
    defaultTitleMessage: string;
    subtitleId: TpMessageKeys;
    defaultSubtitleMessage: string;
    titleIdMark: TpMessageKeys;
    defaultTitleMessageMark: string;
    loaderTextId: TpMessageKeys;
    loaderTextDefaultMessage: string;
  };
  progress: number;
  extraClassname?: string;
};

export const BreaksWithLoader = ({
  currentCard,
  progress,
  extraClassname,
}: Props) => (
  <>
    <StepContainer isQuestionStep={false}>
      <>
        <div className={classNames(styles.imageWrapper, extraClassname)}>
          {currentCard.image}
        </div>
        <div className={classNames(styles.content, extraClassname)}>
          <h2 className={styles.title}>
            <FormattedMessage
              defaultMessage={currentCard.defaultTitleMessage}
              id={currentCard.titleId}
              values={{
                br: (
                  <b style={{ color: '#875FF4' }}>
                    <FormattedMessage
                      defaultMessage={currentCard.defaultTitleMessageMark}
                      id={currentCard.titleIdMark}
                    />
                  </b>
                ),
              }}
            />
          </h2>
          <h3 className={styles.subtitle}>
            <FormattedMessage
              defaultMessage={currentCard.defaultSubtitleMessage}
              id={currentCard.subtitleId}
            />
          </h3>
        </div>
      </>
    </StepContainer>
    <div className={classNames(styles.bottomContent, extraClassname)}>
      <div className={styles.progressContainer}>
        <div
          className={styles.progressFill}
          style={{ width: `${progress}%` }}
        />
      </div>

      <p className={styles.additionalBlock}>
        <span className={styles.loader} />
        <FormattedMessage
          defaultMessage={currentCard.loaderTextDefaultMessage}
          id={currentCard.loaderTextId}
        />
      </p>
    </div>
  </>
);
