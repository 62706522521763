import { ParentSize } from '@visx/responsive';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import GraphImage from 'src/components/main/quiz/graph/graph-image/graph-image';
import { uniformGoalWeight } from 'src/utils/weight-units';
import { UiKitTooltip } from 'src/widgets/ui-kit-tooltip';

import { WeightUnits } from '@features/Quiz/constants';
import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import styles from './weight-target-graph-last-plan.module.scss';

const LABEL_COLORS = ['#F7544A', '#FFDE85', '#A6E4F4'];
const startLabel = {
  backgroundProps: {
    rx: 8,
    fill: '#fff',
    stroke: 'var(--gray-middle, #E7E7EC)',
    strokeWidth: 1,
  },
  titleFontWeight: '400',
  fontColor: '#000',
  backgroundPadding: { left: 12, right: 12, top: 6, bottom: 6 },
};

const labelGradient = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="239"
    height="189"
    viewBox="0 0 239 189"
    fill="none"
  >
    <defs>
      <linearGradient
        id="paint0_linear_5964_19318"
        x1="-15.6951"
        y1="-31.2094"
        x2="280.903"
        y2="-15.5455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF564B" />
        <stop offset="0.536458" stopColor="#FFE789" />
        <stop offset="1" stopColor="#A2E3F9" />
      </linearGradient>
    </defs>
  </svg>
);

const eventsImportantMap = new Map([
  ['Wedding', 'Onboarding.Important.Option1'],
  ['Vacation', 'Onboarding.Important.Option2'],
  ['Holiday', 'Onboarding.Important.Option3'],
  ['Sports competition', 'Onboarding.Important.Option4'],
  ['Reunion', 'Onboarding.Important.Option5'],
  ['Birthday', 'Onboarding.Important.Option6'],
  ['Other', 'Onboarding.Important.Option6:30'],
]);

const WeightTargetGraphLastPlan = observer(
  ({ isOnPaywall = false }: { isOnPaywall?: boolean }) => {
    const intl = useIntl();
    const { quizStore, authStore } = useStores();
    const {
      weightValue,
      weightUnits,
      goalWeightValue,
      goalWeightUnits,
      whenEvent,
      eventComing,
      setWeightPerDay,
    } = quizStore;
    const { user } = authStore;

    const userWeightValue = weightValue || user?.weight_value;
    const userWeightUnits = weightUnits || (user?.weight_units as WeightUnits);
    const userGoalWeightValue = goalWeightValue || user?.goal_weight_value;
    const userGoalWeightUnits =
      goalWeightUnits || (user?.goal_weight_units as WeightUnits);

    if (
      userWeightValue == null ||
      userWeightUnits == null ||
      userGoalWeightValue == null ||
      userGoalWeightUnits == null
    ) {
      throw new Error('Weight and goal weight steps must be completed first');
    }

    const goalWeight = uniformGoalWeight({
      weightUnits: userWeightUnits,
      goalWeightUnits: userGoalWeightUnits,
      goalWeightValue: userGoalWeightValue,
    });

    const differenceDays = dayjs(whenEvent).diff(dayjs(), 'days');
    const formattedToday = dayjs().format('DD.MM.YYYY');

    const formattedMemoized = useMemo(() => {
      const formattedWhenEvent = dayjs(whenEvent).format('DD.MM.YYYY');

      let formattedForHeaderWhenEvent: string;

      let minWeight = userGoalWeightValue;
      const maxWeight = userWeightValue;
      let middleWeight = 0,
        firstDate,
        secondDate,
        thirdDate,
        weightPerDay = 0;

      if (whenEvent !== null && formattedWhenEvent !== formattedToday) {
        firstDate = dayjs();
        thirdDate = dayjs(whenEvent);
        formattedForHeaderWhenEvent = thirdDate.format('MMMM DD');
        weightPerDay = +(
          (maxWeight - minWeight) /
          thirdDate.diff(firstDate, 'days')
        ).toFixed(2);
        middleWeight = +((maxWeight + minWeight) / 2).toFixed(0);

        if (differenceDays > 90) {
          secondDate = dayjs(firstDate).add(1, 'month');
        } else if (differenceDays > 30) {
          secondDate = dayjs().add(1, 'month');
        } else {
          const addDays = thirdDate.diff(firstDate, 'days') / 2;
          secondDate = dayjs(firstDate).add(addDays, 'days');
        }
      } else if (whenEvent === null || formattedWhenEvent === formattedToday) {
        firstDate = dayjs();
        secondDate = dayjs(firstDate).add(1, 'month');
        thirdDate = dayjs(secondDate).add(1, 'month');
        formattedForHeaderWhenEvent = thirdDate.format('MMMM DD');
        middleWeight = +((maxWeight + minWeight) / 2).toFixed(0);
        weightPerDay = 0.2;
      }
      return {
        // @ts-ignore
        formattedForHeaderWhenEvent,
        minWeight,
        maxWeight,
        middleWeight,
        firstDate,
        secondDate,
        thirdDate,
        weightPerDay,
      };
    }, [
      whenEvent,
      userGoalWeightValue,
      userWeightValue,
      formattedToday,
      differenceDays,
    ]);

    useEffect(() => {
      setWeightPerDay(formattedMemoized.weightPerDay);
    }, [formattedMemoized.weightPerDay, setWeightPerDay]);

    let formattedFirstDate, formattedSecondDate, formattedThirdDate;

    if (isOnPaywall) {
      if (whenEvent === null) {
        formattedMemoized.middleWeight = +(
          formattedMemoized.maxWeight -
          formattedMemoized.weightPerDay * 30
        ).toFixed(0);
        formattedMemoized.minWeight = +(
          formattedMemoized.maxWeight -
          formattedMemoized.weightPerDay * 60
        ).toFixed(0);
      } else {
        if (differenceDays < 30 || differenceDays < 60) {
          formattedMemoized.minWeight = goalWeight;
          formattedMemoized.middleWeight = +(
            (formattedMemoized.maxWeight + formattedMemoized.minWeight) /
            2
          ).toFixed(0);
        }
      }
      formattedFirstDate = dayjs().format('MMMM');
      formattedSecondDate = dayjs().add(1, 'month').format('MMMM');
      formattedThirdDate = dayjs().add(2, 'month').format('MMMM');
    } else {
      formattedFirstDate = dayjs(formattedMemoized.firstDate).format('DD.MM');
      formattedSecondDate = dayjs(formattedMemoized.secondDate).format('DD.MM');
      formattedThirdDate = dayjs(formattedMemoized.thirdDate).format('DD.MM');
    }

    const rangeMonth = [
      formattedFirstDate,
      formattedSecondDate,
      formattedThirdDate,
    ];

    const lineData = rangeMonth.map((_, unitIndex) => {
      const value =
        unitIndex === 0
          ? formattedMemoized.maxWeight
          : unitIndex === 1
            ? formattedMemoized.middleWeight
            : formattedMemoized.minWeight;
      const labelValue = value;
      const stylesLabel = startLabel;
      return {
        unitIndex,
        value,
        labelValue,
        stylesLabel,
      };
    });

    return (
      <figure className={classNames(styles.container)}>
        <ParentSize>
          {({ width, height }) => (
            <>
              <div className={styles.header}>
                <p className={styles.title}>
                  <FormattedMessage
                    id="Onboarding.OnlyPlan.Subtitle1"
                    defaultMessage="We predict you'll be"
                  />{' '}
                  <br />
                  <b>
                    <FormattedMessage
                      defaultMessage="{mark1} {mark2} by *{mark3}"
                      id="Onboarding.OnlyPlan.Date"
                      values={{
                        mark1: <b>{formattedMemoized.minWeight}</b>,
                        mark2: <b>{weightUnits}</b>,
                        mark3: (
                          <b>
                            {intl.formatDate(
                              formattedMemoized.formattedForHeaderWhenEvent,
                              {
                                month: 'long',
                                day: 'numeric',
                              },
                            )}
                          </b>
                        ),
                      }}
                    />
                  </b>
                </p>

                {eventComing !== 'no_special_events' && (
                  <UiKitTooltip
                    className={styles.tooltip}
                    contentClassName={styles.contentClassName}
                    content={
                      <>
                        <FormattedMessage
                          defaultMessage="Just in time for the "
                          id="Onboarding.OnlyPlan.Subtitle2"
                        />{' '}
                        <b>
                          {intl.formatMessage({
                            id: eventsImportantMap.get(
                              eventComing as string,
                            ) as TpMessageKeys,
                            defaultMessage: eventComing,
                          })}
                        </b>
                      </>
                    }
                  />
                )}
              </div>
              <GraphImage
                lineData={lineData}
                unitLabels={rangeMonth}
                minWeight={formattedMemoized.minWeight}
                maxWeight={userWeightValue}
                weightUnits={weightUnits!}
                width={width}
                height={272}
                labelColors={LABEL_COLORS}
                labelGradient={labelGradient}
                weightPerDay={formattedMemoized.weightPerDay}
              />
              <p className={styles.subtext}>
                <FormattedMessage
                  id="Onboarding.OnlyPlan.Footnote"
                  defaultMessage="*Based on the data of users who log their progress in the app. Consult your physician first. The chart is a non-customized illustration"
                />
              </p>
            </>
          )}
        </ParentSize>
      </figure>
    );
  },
);

export default WeightTargetGraphLastPlan;
