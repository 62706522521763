import { Annotation, Label } from '@visx/annotation';
import { AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import type { TextProps } from '@visx/text';
import { Text } from '@visx/text';
import { curveBumpX } from 'd3-shape';
import { ComponentProps, Fragment, ReactElement, useMemo } from 'react';

import { formatNumber } from 'src/utils/number-format';

import { WeightUnits } from '@features/Quiz/constants';
import { useIntl } from '@features/intl';

import styles from './graph-image.module.scss';

const GRAPH_PADDING_LEFT = 50;
const GRAPH_PADDING_RIGHT = 50;
const GRAPH_PADDING_TOP = 68;
const GRAPH_PADDING_BOTTOM = 24;
const GRAPH_AXIS_PADDING = 8;
const GRAPH_AXIS_HEIGHT = 28;

const UNIT_COLORS = ['#FF564B', '#FFE789', '#A2F9BF'];

const getGraphTickLabelProps = (): Partial<TextProps> => ({
  textAnchor: 'middle',
  fontSize: 16,
  fill: '#8d8d9c',
});

interface LineDatum {
  unitIndex: number;
  value: number;
  labelValue: number | string;
  stylesLabel?: ComponentProps<typeof Label>;
  dx?: number;
  dy?: number;
  transform?: number;
}

const GraphImage = ({
  lineData,
  unitLabels,
  minWeight,
  maxWeight,
  weightUnits,
  width,
  height,
  labelColors = UNIT_COLORS,
  labelGradient,
  weightPerDay,
  rightUpperCornerText,
  graphPaddingLeft,
  graphPaddingRight,
}: {
  lineData: LineDatum[];
  unitLabels: string[];
  labelColors?: string[];
  minWeight: number;
  maxWeight: number;
  weightUnits: WeightUnits;
  width: number;
  height: number;
  labelGradient?: ReactElement<SVGLinearGradientElement>;
  weightPerDay?: number;
  rightUpperCornerText?: string;
  graphPaddingLeft?: number;
  graphPaddingRight?: number;
}) => {
  const intl = useIntl();
  const CURRENT_GRAPH_PADDING_LEFT = graphPaddingLeft
    ? graphPaddingLeft
    : GRAPH_PADDING_LEFT;
  const CURRENT_GRAPH_PADDING_RIGHT = graphPaddingRight
    ? graphPaddingRight
    : GRAPH_PADDING_RIGHT;

  const xScale = scaleLinear({
    domain: [0, unitLabels.length - 1],
    range: [CURRENT_GRAPH_PADDING_LEFT, width - CURRENT_GRAPH_PADDING_RIGHT],
  });

  const yScale = scaleLinear({
    domain: [minWeight, maxWeight],
    range: [
      height - GRAPH_PADDING_BOTTOM - GRAPH_AXIS_HEIGHT,
      GRAPH_PADDING_TOP,
    ],
  });

  const perDayMessage = useMemo(
    () =>
      intl.formatMessage({
        asString: true,
        id: 'Onboarding.OnlyPlan.Day',
        defaultMessage: 'per day',
      }),
    [intl],
  );

  return (
    <svg
      width={width}
      height={height}
      style={{
        position: 'relative',
        marginBottom: `${weightPerDay ? '68px' : '0'}`,
      }}
    >
      <AxisBottom
        scale={xScale}
        top={height - GRAPH_AXIS_HEIGHT - GRAPH_AXIS_PADDING}
        hideAxisLine={true}
        hideTicks={true}
        tickLabelProps={getGraphTickLabelProps}
        tickFormat={(value) => unitLabels[+value]}
      />
      <GridColumns
        scale={xScale}
        numTicks={lineData.length - 1}
        top={GRAPH_PADDING_TOP}
        width={width - CURRENT_GRAPH_PADDING_LEFT - CURRENT_GRAPH_PADDING_RIGHT}
        height={
          height - GRAPH_PADDING_TOP - GRAPH_PADDING_BOTTOM - GRAPH_AXIS_HEIGHT
        }
        stroke="#e7e7ec"
        strokeDasharray="10 10"
      />
      <LinePath
        data={lineData}
        x={(datum) => xScale(datum.unitIndex)}
        y={(datum) => yScale(datum.value)}
        curve={curveBumpX}
        stroke="url(#lineGradient)"
        strokeWidth={4}
        className={styles['lineAnimation']}
      />
      {lineData.map(
        (
          { unitIndex, value, labelValue, stylesLabel, dx, dy, transform },
          index,
        ) => (
          <Fragment key={index}>
            <circle
              key={index}
              cx={xScale(unitIndex)}
              cy={yScale(value)}
              r={10}
              fill={labelColors[index]}
              stroke="#fff"
              strokeWidth={4}
              filter="url(#markerShadow)"
              className={styles['circleAnimation']}
            />
            <g transform={`rotate(${transform ?? 0})`}>
              <Annotation
                x={xScale(unitIndex)}
                y={yScale(value)}
                dy={dy ?? -20}
                dx={dx ?? 0}
              >
                <Label
                  title={
                    typeof labelValue === 'string'
                      ? labelValue
                      : `${formatNumber(labelValue)} ${weightUnits}`
                  }
                  horizontalAnchor="middle"
                  verticalAnchor="end"
                  backgroundPadding={{ top: 8, right: 12, bottom: 8, left: 12 }}
                  showAnchorLine={false}
                  backgroundProps={{
                    rx: 8,
                    fill: '#fff',
                    stroke: '#e7e7ec',
                    strokeWidth: 1,
                  }}
                  titleFontSize={16}
                  titleFontWeight="normal"
                  fontColor="#161616"
                  className={styles['labelAnimation']}
                  {...stylesLabel}
                />
                {labelGradient}
              </Annotation>
            </g>
          </Fragment>
        ),
      )}
      <linearGradient id="lineGradient">
        {UNIT_COLORS.map((color, index) => (
          <stop
            key={index}
            offset={`${(index * 100) / (UNIT_COLORS.length - 1)}%`}
            stopColor={color}
          />
        ))}
      </linearGradient>

      <filter id="markerShadow" x={-7.8} y={-7.8} width={39.6} height={39.6}>
        <feDropShadow dx="0" dy="3.6" stdDeviation="2.8" floodOpacity={0.08} />
      </filter>
      {weightPerDay && (
        <Text
          x={width - 20}
          y={GRAPH_PADDING_TOP - 32}
          fontSize={14}
          fill="#EE793A"
          textAnchor="end"
          fontFamily="Inter"
          fontWeight={700}
          lineHeight="140%"
        >
          {`🔥 ${weightPerDay} ${weightUnits} ${perDayMessage}`}
        </Text>
      )}
      {rightUpperCornerText && (
        <Text
          x={width - 20}
          y={GRAPH_PADDING_TOP - 32}
          fontSize={14}
          fill="#737377"
          textAnchor="end"
          fontFamily="Inter"
          fontWeight={400}
          lineHeight="140%"
        >
          {rightUpperCornerText}
        </Text>
      )}
    </svg>
  );
};

export default GraphImage;
