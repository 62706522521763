import Cookies from 'js-cookie';

import { axiosMicroservice } from 'src/api/axios';
import { TEST_EVENT_CODE } from 'src/constants/api';
import { getDataFromSessionStorage } from 'src/utils';

import type { TpFacebookPostParameters } from '@features/Analytics/types/facebook';

export const postFacebookEvent = async ({
  externalId,
  eventId,
  eventName,
  fbp,
  options,
  customData,
}: TpFacebookPostParameters & { fbp: string }) => {
  const fbc = Cookies.get('_fbc');

  const pixelVariants: Record<string, string> = {
    '680910920662417': 'youth',
    '485684030586998': 'pilates_creo',
  };

  const currentPixelId = getDataFromSessionStorage({
    store: 'analyticsStore',
    key: 'fbPixelId',
  });

  try {
    const { data } = await axiosMicroservice.post('fb_event', {
      fbp,
      fbc,
      event_name: eventName,
      event_id: eventId,
      external_id: externalId,
      test_event_code: TEST_EVENT_CODE,
      origin: pixelVariants[currentPixelId || '680910920662417'],
      ...options,
      custom_data: { ...customData },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
