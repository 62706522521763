import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { WeightUnits } from '@features/Quiz/constants';

import TargetWeightStep from './TargetWeightForm/targetWeight';

type TpQuizStepQuestionProps = {
  goToNextStep: (eventValue?: any, eventUnit?: string) => void;
};
export const TargetWeight = observer(
  ({ goToNextStep }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { goalWeightValue, goalWeightUnits, setGoalWeight },
    } = useStores();

    const selectedValue = goalWeightValue;
    const selectedUnit = goalWeightUnits;

    const onChange = (value: number, unit: WeightUnits) => {
      setGoalWeight(value, unit);
    };

    return (
      <StepContainer isQuestionStep>
        <TargetWeightStep
          value={selectedValue}
          units={selectedUnit}
          onChange={onChange}
          onNextPress={(value, units) =>
            goToNextStep({
              eventName: 'onboarding_target_weight_chosen',
              properties: { answer: value, unit: units },
            })
          }
        />
      </StepContainer>
    );
  },
);
