import { useCallback } from 'react';

import { Image } from 'src/components/common/Image';

import {
  tikTokAdvancedMatching,
  tikTokEvent,
  useAnalytics,
} from '@features/Analytics';
import { twitterEvent } from '@features/Analytics/services/twitter-pixel';
import { EmailForm } from '@features/Quiz/components/EmailForm';
import { FormattedMessage, TpMessageKeys } from '@features/intl';

import { TpPilatesQuizStep } from '../../types';
import styles from './EnterEmail.module.scss';

const EnterEmail = ({
  step,
  goToNextStep,
}: {
  step: TpPilatesQuizStep;
  goToNextStep: () => void;
}) => {
  const { trackFacebookEvent, trackGoogleEvent, trackPinterestEvent } =
    useAnalytics();
  const handleSubmit = useCallback(
    ({
      email,
      shouldGoToNext = true,
    }: {
      email: string;
      shouldGoToNext?: boolean;
    }) => {
      trackGoogleEvent({
        eventName: 'onboarding_email_tapped',
        options: {
          email,
        },
      });
      trackFacebookEvent({
        eventName: 'CompleteRegistration',
        options: {
          email,
        },
      });
      trackPinterestEvent({ eventName: 'Signup' });

      //TODO: заменить инвент на валидный ивнет для твиттера
      twitterEvent('example1', { email_address: email });
      tikTokAdvancedMatching(email);
      tikTokEvent('CompleteRegistration');

      shouldGoToNext && goToNextStep();
    },
    [goToNextStep, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent],
  );

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.title}>
          <FormattedMessage
            id={step.titleId as TpMessageKeys}
            defaultMessage={step.defaultTitleMessage || ''}
            values={{
              mark: (
                <p className={styles.subtitle}>
                  <FormattedMessage
                    id={step.subtitleId as TpMessageKeys}
                    defaultMessage={step.defaultSubtitleMessage || ''}
                    values={{
                      br: <br />,
                    }}
                  />
                </p>
              ),
            }}
          />
        </h2>
      </div>

      <EmailForm
        containerClassName={styles.container}
        handleSubmitExtended={handleSubmit}
      >
        <div className={styles.members}>
          <Image src="members.png" width={76} height={32} alt="" />
          <span className={styles.membersHint}>
            <span>
              <FormattedMessage
                defaultMessage="{mark} members have joined us"
                id="Onboarding.Email.Million"
                values={{
                  mark: (
                    <strong>
                      <FormattedMessage
                        defaultMessage="15 million+"
                        id="Onboarding.Email.Million.Mark"
                      />{' '}
                    </strong>
                  ),
                }}
              />
            </span>
          </span>
        </div>
        <div className={styles.disclaimer}>
          <Image width={32} height={32} src="lock-emoji.webp" alt="" />
          <FormattedMessage
            defaultMessage="We'll send your personal plan and weight loss tips via email, and will not share your data with other parties. We also provide a 5-minute special offer for those, who enter a valid email address"
            id="Onboarding.Email.Footnote"
          />
        </div>
      </EmailForm>
    </>
  );
};

export default EnterEmail;
