import { Image } from 'src/components/common/Image';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { FormattedMessage } from '@features/intl';

import styles from './ChooseYouth.module.scss';

export const ChooseYouth = ({
  handleNextStep,
}: {
  handleNextStep: () => void;
}) => (
  <StepContainer
    isQuestionStep={false}
    contentContainerClassName={styles.container}
    onNextPress={handleNextStep}
    nextButton={true}
  >
    <h1 className={styles.title}>
      <FormattedMessage
        defaultMessage="15 million people"
        id="Onboarding.MillionPeople.Title"
      />
    </h1>
    <p className={styles.subtitle}>
      <FormattedMessage
        defaultMessage="have chosen Youth"
        id="Onboarding.MillionPeople.Subtitle"
      />
    </p>
    <div className={styles.imageWrapper}>
      <Image
        src="trio-pilates.webp"
        alt="Choose youth"
        width={327}
        height={353}
      />
    </div>
  </StepContainer>
);
