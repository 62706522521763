export const MIN_HEIGHT_FEET = 3;
export const MAX_HEIGHT_FEET = 7;
export const MIN_HEIGHT_INCHES = 0;
export const MAX_HEIGHT_INCHES = 11;
export const MIN_HEIGHT_CM = 115;
export const MAX_HEIGHT_CM = 220;

export const MIN_WEIGHT_LBS = 66;
export const MAX_WEIGHT_LBS = 329;
export const MIN_WEIGHT_KG = 40;
export const MAX_WEIGHT_KG = 300;

export const FLOAT_KG = 4;
export const FLOAT_LBS = 8.5;
