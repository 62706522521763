import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOptionDoubleImage } from '@features/Quiz/components/SingleOptionDoubleImage';

import { TpQuizStepQuestionProps } from '../../types';

export const FlexiblePilatesV2 = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setFlexiblePilates },
    } = useStores();

    const { stepName, options } = step;

    return (
      <SingleOptionDoubleImage
        stepName={stepName}
        handleEventSend={handleEventSend}
        goToNextStep={goToNextStep}
        options={options}
        name="flexible-pilates"
        setterFunction={setFlexiblePilates}
        leftImage="flexible-pilates-left.webp"
        eventName="onboarding_flexible_chosen"
        customBgImageProperty="flexible-pilates-bg.webp"
      />
    );
  },
);
