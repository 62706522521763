import LanguageDetector from 'i18next-browser-languagedetector';

export const languageDetector = new LanguageDetector(
  { languageUtils: {} },
  {
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
      'htmlTag',
    ],
  },
);
