import { PAYMENT_ENDPOINT } from '@features/Paywall/api/payments';

import { post } from './api';

export const sendFeedback = async ({
  email,
  message,
  userId,
  isSubscriber,
  fromLabel = 'Youth Web',
}: {
  email: string;
  fromLabel?: string;
  message: string;
  userId?: number;
  isSubscriber?: boolean;
}): Promise<void> =>
  await post({
    endpoint: PAYMENT_ENDPOINT,
    path: 'feedback',
    body: {
      from_label: fromLabel,
      user_email: email,
      user_id: userId,
      form_name: 'Youth',
      message: message,
      is_subscriber: isSubscriber,
    },
  });
