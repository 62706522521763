import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';

import { TpSummaryStepProps } from '../../types';

export const ProvenEffectiveness = ({
  step,
  goToNextStep,
}: TpSummaryStepProps) => (
  <BreaksScreen
    step={step}
    goToNextStep={goToNextStep}
    gaEventQuery="proven_effectiveness"
  />
);
