import { Typography } from '@mentalgrowth/ui-kit-web';

import { UiKitButton } from 'src/components/common/ui-kit-button';

import styles from './cookie-agreement.module.scss';

type TpCookieAgreementProps = {
  onAgree: () => void;
};
export const CookieAgreement = (props: TpCookieAgreementProps) => {
  const { onAgree } = props;
  return (
    <div className={styles.host}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography as="h2" className={styles.title}>
            Level up your fitness routine
          </Typography>
          <p className={styles.text}>
            We require your consent to access the health data you share with us
            to create a personalized fitness program.
          </p>
          <p className={styles.text}>
            Your consent helps us improve your experience, and make it safe,
            unique and beneficial.
          </p>
          <UiKitButton fullWidth onClick={onAgree}>
            Agree & Continue
          </UiKitButton>
          <a
            className={styles.link}
            href="https://legal.mentalgrowth.app/youth/privacy.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};
