import { Dropdown, TpDropdownProps } from '@mentalgrowth/ui-kit-web';
import classNames from 'classnames';

import styles from './ui-kit-dropdown.module.scss';

const defaultPopperConfig = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10],
      },
    },
  ],
};

export const UiKitDropdown = (props: TpDropdownProps) => {
  const menuClassNameCombined = classNames(
    styles.dropdownMenu,
    props.menuClassName,
  );

  const popperConfigCombined = {
    ...defaultPopperConfig,
    ...props.popperConfig,
  };

  return (
    <Dropdown
      {...props}
      menuClassName={menuClassNameCombined}
      popperConfig={popperConfigCombined}
    />
  );
};
