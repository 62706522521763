import { HeightUnits, WeightUnits } from '@features/Quiz/constants';

import { feetToCm } from './height-units';
import { lbsToKg } from './weight-units';

type BMI = {
  weightUnits: string;
  weightValue: number | undefined;
  heightUnits: `${HeightUnits}`;
  heightValue: number;
};

type Calories = { age: number } & BMI;

type GetWater = Pick<BMI, 'weightValue' | 'weightUnits'>;

const LITERS = 0.03;

const weightKg = (weightValue: number, weightUnits: string) => {
  const object: { [key: string]: number } = {
    [WeightUnits.KG]: weightValue,
    [WeightUnits.LBS]: lbsToKg(weightValue),
  };
  return object[weightUnits];
};

const heightCm = (heightValue: number, heightUnits: `${HeightUnits}`) => {
  const object = {
    [HeightUnits.CM]: heightValue,
    [HeightUnits.FEET]: feetToCm(heightValue),
  };
  return object[heightUnits];
};

export const getBMI = ({
  weightUnits,
  weightValue,
  heightUnits,
  heightValue,
}: BMI) => {
  if (weightValue) {
    const weight = weightKg(weightValue, weightUnits);
    const height = heightCm(heightValue, heightUnits);

    return +(weight / (height / 100) ** 2).toFixed(1);
  }
  return null;
};

export const getCaloriesDaily = ({
  weightUnits,
  weightValue,
  heightUnits,
  heightValue,
  age,
}: Calories) => {
  if (weightValue) {
    const weight = weightKg(weightValue, weightUnits);
    const height = heightCm(heightValue, heightUnits);

    return Math.round((10 * weight + 6.25 * height - 5 * age - 161) * 1.2);
  }
  return null;
};

export const getWaterDaily = ({ weightValue, weightUnits }: GetWater) => {
  if (weightValue) {
    const weight = weightKg(weightValue, weightUnits);

    return Math.round(weight * LITERS * 10) / 10;
  }
  return null;
};
