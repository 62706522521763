import classNames from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';

import styles from './circle-button.module.scss';

export interface Props extends ComponentProps<typeof Button> {}

const CircleButton = forwardRef<typeof Button, Props>(
  ({ className, ...props }: Props, ref) => (
    <Button
      ref={ref}
      className={classNames(styles.container, className)}
      variant="secondary"
      {...props}
    />
  ),
);

CircleButton.displayName = 'CircleButton';
export default CircleButton;
