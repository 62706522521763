import * as Sentry from '@sentry/react';

export const ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT as string;

export class RequestError extends Error {
  name = 'RequestError';

  constructor(
    public statusCode: number,
    public statusText: string,
    public data: any,
  ) {
    super(`${statusCode} ${statusText} ${data}`);
  }
}

export const request = async (
  endpoint: string,
  path: string,
  options: RequestInit,
) => {
  const url = new URL(path, endpoint).href;

  const response = await fetch(url, {
    credentials: 'include',
    ...options,
  });
  if (!response.ok) {
    const data = await response.json();
    const error = new RequestError(response.status, response.statusText, data);
    Sentry.withScope((scope) => {
      scope.setExtras({
        url,
        method: options.method,
        headers: options.headers,
        body: options.body,
        status: error.statusCode,
        response: error.data,
      });
      Sentry.captureException(error);
    });

    throw error;
  }

  if (response.headers.get('Content-Type') === 'application/json') {
    return await response.json();
  }
  return undefined;
};

export const get = async ({
  endpoint = ENDPOINT,
  path,
  params,
  headers,
  abortSignal,
}: {
  endpoint?: string;
  path: string;
  params?: Record<string, string>;
  headers?: HeadersInit;
  abortSignal?: AbortSignal;
}) =>
  await request(
    endpoint,
    params ? `${path}?${new URLSearchParams(params)}` : path,
    {
      headers: headers,
      signal: abortSignal,
    },
  );

export const post = async ({
  endpoint = ENDPOINT,
  path,
  body,
  abortSignal,
}: {
  endpoint?: string;
  path: string;
  body: Record<string, any>;
  abortSignal?: AbortSignal;
}) =>
  await request(endpoint, path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    signal: abortSignal,
  });
