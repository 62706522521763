import { Image } from 'src/components/common/Image';

import {
  SingleOption,
  TpSingleOptionProps,
} from '@features/Quiz/components/SingleOption';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import styles from './SingleOptionDoubleImage.module.scss';

export const SingleOptionDoubleImage = ({
  stepName,
  goToNextStep,
  handleEventSend,
  options,
  name,
  setterFunction,
  leftImage,
  eventName,
  customBgImageProperty,
}: TpSingleOptionProps & {
  leftImage: string;
  customBgImageProperty?: string;
}) => (
  <StepContainer
    contentContainerClassName={styles.host}
    customBgImageProperty={customBgImageProperty}
  >
    <SingleOption
      stepName={stepName}
      name={name}
      options={options}
      handleEventSend={handleEventSend}
      setterFunction={setterFunction}
      goToNextStep={goToNextStep}
      labelHalfWidth
      eventName={eventName}
    />

    <Image
      src={leftImage}
      alt="left-image"
      width={375}
      height={558}
      priority
      className={styles.leftImage}
    />
  </StepContainer>
);
