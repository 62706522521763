import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpSummaryStepProps } from '../../types';
import styles from './AboutYouBreaks.module.scss';

export const AboutYouBreaks = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <StepContainer
    isQuestionStep={false}
    background="LIGHT_PURPLE"
    contentContainerClassName={styles.host}
  >
    <BreaksScreen
      step={step}
      goToNextStep={goToNextStep}
      gaEventQuery="about_you_breaks"
    />
  </StepContainer>
);
