import { Instance, applySnapshot, cast, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import * as payments from '@features/Paywall/api/payments';

import {
  SubscriptionPlanModel,
  SubscriptionPlanSnapshot,
} from '../subscription-plan/subscription-plan';

export enum PaymentMethod {
  CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'paypal',
}

export const PaymentsStoreModel = types
  .model('PaymentsStore')
  .props({
    paymentMethod: types.maybe(
      types.enumeration<PaymentMethod>(Object.values(PaymentMethod)),
    ),
    allSubscriptionPlans: types.maybe(types.array(SubscriptionPlanModel)),
    isDiscount: types.optional(types.boolean, false),
    wasPaymentClosed: types.maybe(types.boolean),
    isPurchaseEventsSend: types.optional(types.boolean, false),
    wasPaymentModalClosed: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setAllSubscriptionPlans: (plans: SubscriptionPlanSnapshot[]) => {
      if (self.allSubscriptionPlans) {
        applySnapshot(self.allSubscriptionPlans, plans);
      } else {
        self.allSubscriptionPlans = cast(plans);
      }
    },
  }))
  .actions((self) => ({
    fetchSubscriptionPlans: flow(function* ({
      abortSignal,
      group,
    }: {
      abortSignal?: AbortSignal;
      group?: string;
    } = {}) {
      const plans = yield payments.fetchSubscriptionPlans({
        abortSignal,
        group,
      });
      self.setAllSubscriptionPlans(plans);
    }),
  }))
  .actions((self) => ({
    setDiscount(discount: boolean) {
      self.isDiscount = discount;
    },
    setPurchaseEventsSend(isSent: boolean) {
      self.isPurchaseEventsSend = isSent;
    },
    setWasPaymentModalClosed(wasClosed: boolean) {
      self.wasPaymentModalClosed = wasClosed;
    },
    setWasPaymentClosed: (wasClosed: boolean) => {
      self.wasPaymentClosed = wasClosed;
    },
    setPaymentMethod: (paymentMethod: PaymentMethod) => {
      self.paymentMethod = paymentMethod;
    },
  }))
  .actions((self) => ({
    resetPayment: () => {
      self.isDiscount = false;
      self.paymentMethod = undefined;
      self.isPurchaseEventsSend = false;
      self.wasPaymentModalClosed = false;
      self.wasPaymentClosed = false;
      self.paymentMethod = undefined;
      self.allSubscriptionPlans = undefined;
    },
  }))
  .views((self) => ({
    get subscriptionPlansDiscountPrice() {
      if (!self.allSubscriptionPlans) {
        return [];
      }

      const map = new Map();

      for (const plan of self.allSubscriptionPlans) {
        map.set(plan.name, { ...plan });

        if (plan.specialOffer) {
          const planFullPrice = self.allSubscriptionPlans.find(
            (item) => item.name === plan.name && !item.specialOffer,
          );

          const searchPlan = map.get(plan.name);

          if (searchPlan.specialOffer) {
            map.set(plan.name, {
              ...plan,
              order: planFullPrice ? planFullPrice.order : plan.order,
            });
          } else {
            map.delete(searchPlan.name);
          }
        }
      }
      return [...map.values()].sort((a, b) => a.order - b.order);
    },

    get discountPlan() {
      return self.allSubscriptionPlans?.find((plan) => plan.specialOffer);
    },
  }))
  .views((self) => ({
    get subscriptionPlans() {
      if (!self.isDiscount) {
        return self.allSubscriptionPlans?.filter((plan) => !plan.specialOffer);
      }
      return self.subscriptionPlansDiscountPrice;
    },
    getDefaultDiscountPercent(currentPlanName: string) {
      return self.allSubscriptionPlans!.find(
        (item) => item.name === currentPlanName && !item.specialOffer,
      )!.discountPercentage;
    },
  }));

type PaymentsStoreType = Instance<typeof PaymentsStoreModel>;

export interface PaymentsStore extends PaymentsStoreType {}
