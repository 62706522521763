import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import WeightTargetGraphLastPlan from 'src/components/main/quiz/graph-pilates/weight-target-graph-last-plan';

import { StepContainer } from '@features/Quiz/components/StepContainer';

import { Image } from 'src/components/common/Image';
import { TpSummaryStepProps } from '../../types';
import styles from './LastPlanLong.module.scss';

const TITLE_DATA = [
  {
    value: 'lose_weight',
    text: 'lose weight',
  },
  {
    value: 'build_muscle_strength',
    text: 'build muscle',
  },
  {
    value: 'develop_flexibility',
    text: 'increase flexibility',
  },
  {
    value: 'reduce_stress_anxiety',
    text: 'reduce stress',
  },
  {
    value: 'improve_posture',
    text: 'improve posture',
  },
];

const TOOLTIP_DATA = [
  {
    text: '117 exercises adapted to your fitness level',
    subtext: 'on 28 days ',
    image: 'dumbbell.webp',
    color: '#C8D4D7',
  },
  {
    text: '8 different meal plans to choose from',
    subtext: 'Over 100+ recipes',
    image: 'spodt-bottle.webp',
    color: '#E7DDF9',
  },
  {
    text: 'A ready-to-use workout calendar',
    subtext: 'fitted to your lifestyle',
    image: 'calendar-prepaywall.webp',
    color: '#F3E3E2',
  },
];

const STEPS_DATA = [
  {
    boldText: 'Group Challenges',
    text: '- An effective way to reach your goals alongside with more than 10,000 women.',
    image: 'group-challenges.webp',
  },
  {
    boldText: 'Express Workout',
    text: '- A regularly updated library of exercises tailored just for you.',
    image: 'express-workout.webp',
  },
  {
    boldText: 'Targeted Workout',
    text: '- Discover 6 additional intensive programs for specific body areas.',
    image: 'targeted-workout.webp',
  },
  {
    boldText: 'Motivation Tracker',
    text: '- Monitor your progress, and we’ll help you stay on track to achieve your goals.',
    image: 'motivation-tracker.webp',
  },
];

export const LastPlanLong = observer(({ goToNextStep }: TpSummaryStepProps) => {
  const {
    quizStore: { goalPilates },
  } = useStores();

  const selectedGoal = TITLE_DATA.find((item) => item.value === goalPilates);

  return (
    <>
      <StepContainer
        className={styles.container}
        isQuestionStep={false}
        background="PINK"
      >
        <h2 className={styles.title}>
          The <b className={styles.boldTitle}>only plan</b> you’ll ever need to{' '}
          <b className={styles.boldTitle}>
            {selectedGoal?.text || 'get in shape'}
          </b>
        </h2>
        <WeightTargetGraphLastPlan />

        <div className={styles.tooltipContainer}>
          <h1 className={styles.tooltipTitle}>
            Based on your answers,  we include in your plan
          </h1>

          <div className={styles.tooltip}>
            {TOOLTIP_DATA.map((item) => (
              <div
                key={item.text}
                className={styles.tooltipItem}
                style={{ background: item.color }}
              >
                <div>
                  <p className={styles.tooltipText}>{item.text}</p>
                  <span className={styles.tooltipSubtext}>{item.subtext}</span>
                </div>
                <Image src={item.image} alt="" width={112} height={98} />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.stepsContainer}>
          <h1 className={styles.tooltipTitle}>
            By using Youth Personal Plan, you also get access to:{' '}
          </h1>
          <div className={styles.steps}>
            {STEPS_DATA.map((step, index) => (
              <div key={index} className={styles.step}>
                <Image src={step.image} alt="" width={40} height={40} />
                <span className={styles.stepText}>
                  <b>{step.boldText}</b>
                  {step.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </StepContainer>
      <UiKitButton
        className={styles.submitButton}
        type="submit"
        fullWidth
        onClick={() =>
          goToNextStep({ eventName: 'onboarding_chart_tapped_DEV_867' })
        }
        data-testid="next-button"
      >
        Optimize this plan for my goal!
      </UiKitButton>
    </>
  );
});
