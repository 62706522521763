import { UiKitButton } from 'src/components/common/ui-kit-button';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { TpGoToNextStep } from '@features/Quiz/types';

import { usePersonalPlan } from '@features/PersonalPlan';
import { WomenCompleted } from '@features/Quiz/PilatesOnboarding/steps/WomenCompleted';
import GraphLastPlanPrepaywall from '@features/Quiz/components/GraphLastPlanPrepaywall';
import { ProgramsProgress } from '@features/Quiz/components/program-progress';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Image } from 'src/components/common/Image';
import { SlickSlider } from 'src/components/common/SlickSlider';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import styles from './PilatesPlanReadyChartLong.module.scss';

type TpQuizStepQuestionProps = {
  goToNextStep: TpGoToNextStep;
};

const SLIDER_DATA = [
  {
    image: 'plan-ready-1.webp',
    text: 'Flat and Toned Belly',
    value: 'flat_belly',
  },
  { image: 'plan-ready-7.webp', text: 'Femininity and Elegance Arms' },
  { image: 'plan-ready-3.webp', text: 'Defined and Slim Waist' },
  {
    image: 'plan-ready-6.webp',
    text: 'Slim and Toned Legs',
    value: 'toned_legs',
  },
  { image: 'plan-ready-4.webp', text: 'Back Arch Reduction' },
  {
    image: 'plan-ready-5.webp',
    text: ' Perky and Lifted Breasts',
    value: 'perky_breasts',
  },
  {
    image: 'plan-ready-2.webp',
    text: 'Lifted and Firm Butt',
    value: 'toned_butt',
  },
  { image: 'plan-ready-8.webp', text: 'Increased Body Flexibility' },
];

export const PilatesPlanReadyChartLong = observer(
  ({ goToNextStep }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { userName, dreamBodyPilates, targetZones },
    } = useStores();

    const { weightProgress } = usePersonalPlan();

    const resultsDate = dayjs().add(28, 'days').format('MMMM D, YYYY');
    const resultsDate2 = dayjs().add(84, 'days').format('MMMM D, YYYY');

    const RESULTS_DATA = [
      {
        text: (
          <>
            See <b>first visible results</b> and <b>feel great changes</b> in{' '}
            {resultsDate}
          </>
        ),
        icon: 'emoji-star2.webp',
      },
      {
        text: (
          <>
            Get <b>{dreamBodyPilates?.replace(/_/g, ' ') ?? 'dream body'}</b>{' '}
            body and lose <b>{weightProgress}</b> until {resultsDate2}
          </>
        ),
        icon: 'emoji-arm-strong.webp',
      },
      {
        text: (
          <>
            Feel <b>confident in your body</b> and{' '}
            <b>fit in your clothes better</b>
          </>
        ),
        icon: 'emoji-relieved.webp',
      },
    ];

    const sortedSliderData = SLIDER_DATA.sort((a, b) => {
      if (!targetZones) return 0;

      const indexA = targetZones.indexOf(a.value || '');
      const indexB = targetZones.indexOf(b.value || '');

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      return 0;
    });

    const chunkSize = 4;
    const chunkedData = Array.from(
      { length: Math.ceil(sortedSliderData.length / chunkSize) },
      (_, index) =>
        sortedSliderData.slice(
          index * chunkSize,
          index * chunkSize + chunkSize,
        ),
    );

    const sliderSettings = {
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <StepContainer className={styles.container} isQuestionStep={false}>
        <h2 className={styles.title}>
          <b className={styles.boldTitle}>{userName},</b>
          <br />
          your 4-week Pilates Workout Plan is ready!
        </h2>
        <GraphLastPlanPrepaywall />

        <div className={styles.resultsContainer}>
          {RESULTS_DATA.map((item) => (
            <div key={item.icon} className={styles.result}>
              <Image src={item.icon} alt="" width={24} height={24} />
              <span className={styles.resultText}>{item.text}</span>
            </div>
          ))}
        </div>
        <h1 className={styles.subtitle}>
          What changes will you see and feel after 28 days
        </h1>
        <SlickSlider
          sliderContainer={styles.sliderContainer}
          settings={sliderSettings}
        >
          {chunkedData.map((group, index) => (
            <div key={index}>
              <div className={styles.gridContainer}>
                {group.map(({ text, image }) => (
                  <div key={text} className={styles.item}>
                    <Image
                      fill
                      src={image}
                      alt="image"
                      className={styles.image}
                    />
                    <p className={styles.sliderText}>{text}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </SlickSlider>
        <ProgramsProgress />

        <h1 className={styles.subtitle}>
          We guarantee the elimination of such risks as:
        </h1>
        <div className={styles.imageWrapper}>
          <Image
            src="comparison-prepaywall.webp"
            fill
            style={{ objectFit: 'contain' }}
            alt="image"
          />
        </div>
        <p className={styles.subtext}>Based on Youth users reviews</p>

        <WomenCompleted />

        <UiKitButton
          data-testid="last-plan-step-continue"
          className={styles.button}
          fullWidth
          onClick={() =>
            goToNextStep({ eventName: 'onboarding_chart_tapped_DEV_867' })
          }
        >
          I am ready to get my dream body!
        </UiKitButton>
      </StepContainer>
    );
  },
);
