import { Typography } from '@mentalgrowth/ui-kit-web';

import { Image } from 'src/components/common/Image';
import { UiKitButton } from 'src/components/common/ui-kit-button';

import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpSummaryStepProps } from '../../types';
import styles from './GotGreatNews.module.scss';

export const GotGreatNews = ({ step, goToNextStep }: TpSummaryStepProps) => {
  const { nextButtonText, title } = step;
  return (
    <>
      <StepContainer background="LIGHT_PURPLE" isQuestionStep={false}>
        <Typography as="h2" className={styles.title}>
          {title}
        </Typography>

        <Typography as="p" className={styles.description}>
          You can reach your goal and see the first changes in just 28 days,
          just <strong>like 15 million women</strong> have done with Youth
          Pilates.
        </Typography>

        <Image
          src="got-great-news.webp"
          alt=""
          width={327}
          height={240}
          priority
          className={styles.image}
        />

        <Typography as="h3" variant="body1-bold" className={styles.subTitle}>
          Let`&apos;s customize your personal plan
        </Typography>

        <Typography as="p" className={styles.description}>
          according to your fitness level and preferences
        </Typography>
      </StepContainer>
      <UiKitButton
        className={styles.submitButton}
        onClick={() =>
          goToNextStep({
            eventName: 'onboarding_got_great_news_tapped',
          })
        }
        fullWidth
        data-testid="next-button"
      >
        {nextButtonText}
      </UiKitButton>
    </>
  );
};
