import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import * as yup from 'yup';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import InputStep from '@features/Quiz/components/InputStep';
import { StepContainer } from '@features/Quiz/components/StepContainer';
import { useIntl } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';

export const NameStep = observer(
  ({ goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { userName, setUserName },
    } = useStores();
    const intl = useIntl();

    const validationSchema = yup.object().shape({
      inputField: yup.string().required(
        intl.formatMessage({
          asString: true,
          id: 'Onboarding.Name.Сlue.Error',
          defaultMessage: 'Name is required',
        }) as string,
      ),
    });

    const handleNextPress = useCallback(
      (value: string) => {
        handleEventSend &&
          handleEventSend({
            eventName: 'onboarding_name_chosen',
            options: { answer: value },
          });
        setUserName(value);
        goToNextStep();
      },
      [goToNextStep, handleEventSend, setUserName],
    );

    return (
      <StepContainer isQuestionStep>
        <InputStep
          value={userName}
          validationSchema={validationSchema}
          onChange={handleNextPress}
          inputType="text"
          submitButtonId="Onboarding.Name.Button"
          placeholder={
            intl.formatMessage({
              asString: true,
              id: 'Onboarding.Name.Сlue',
              defaultMessage: 'Name',
            }) as string
          }
        />
      </StepContainer>
    );
  },
);
