import classNames from 'classnames';

import { PolicyLink } from 'src/widgets/policy/components/PolicyLink';
import { RefundLink } from 'src/widgets/policy/components/RefundLink';
import { TermsLink } from 'src/widgets/policy/components/TermsLink';

import styles from './policy.module.scss';

export type TpPolicyProps = {
  className?: string;
  place: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal';
};

export const Policy = ({ className, place }: TpPolicyProps) => (
  <span className={classNames(styles.container, className)}>
    <p>
      By continuing you accept the <TermsLink place={place} />,{' '}
      <PolicyLink place={place} />, <RefundLink place={place} />
    </p>
  </span>
);
