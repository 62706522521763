import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useMemo } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import {
  FACEBOOK_PIXEL_ID,
  FACEBOOK_PIXEL__PILATES_V2_ID,
} from '../../constants';

const FbPixel = observer(() => {
  const storedExternalId = Cookies.get('extId');
  const randomInt = Math.floor(Math.random() * 100_000_000);
  const generateId = () => {
    Cookies.set('extId', `${randomInt}`, { expires: 365 });
    return `${randomInt}`;
  };

  const {
    authStore: { variant },
    analyticsStore: { setFbPixelId },
  } = useStores();

  const { isReady, query } = useRouter();

  const fbPixelId = useMemo(() => {
    if (isReady && variant === 'PilatesVideo' && query['reset'] === undefined) {
      return FACEBOOK_PIXEL__PILATES_V2_ID;
    }
    return isReady && query['reset'] === undefined ? FACEBOOK_PIXEL_ID : null;
  }, [isReady, query, variant]);

  useEffect(() => {
    if (fbPixelId != null) {
      setFbPixelId(fbPixelId);
    }
  }, [fbPixelId, setFbPixelId]);

  const externalId = storedExternalId || generateId();

  return FACEBOOK_PIXEL_ID || FACEBOOK_PIXEL__PILATES_V2_ID ? (
    <Script id="facebook-pixel">
      {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq.disablePushState = true;
        fbq('init', ${fbPixelId}, {'extern_id': ${externalId}});
        fbq('track', 'PageView');`}
    </Script>
  ) : null;
});

export default FbPixel;
