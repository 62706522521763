import { HeightUnits, WeightUnits } from '@features/Quiz/constants';

import { UserSnapshot } from '../models/user/user';
import { RequestError, get, post } from './api';

const responseToUser = (response: any): UserSnapshot => ({
  serverId: response['server_id'],
  email: response['email'],
  isSubscriptionActive: response['is_subscription_active'],
  isPasswordSet: response['is_web_password_set'],
  gender: response['gender'] ?? undefined,
  useMetricSystem: response['use_metric_system'],
  token: response['token'] ?? undefined,
  accessCode: response['access_code'],
  goal: response['goal'] ?? undefined,
  goal_weight_units: response['goal_weight_units'] ?? undefined,
  goal_weight_value: response['goal_weight_value'] ?? undefined,
  height_units: response['height_units'] ?? undefined,
  height_value: response['height_value'] ?? undefined,
  weight_units: response['weight_units'] ?? undefined,
  weight_value: response['weight_value'] ?? undefined,
  user_data: response['user_data'] ?? undefined,
});

export const fetchUser = async ({
  token,
  abortSignal,
}: {
  token?: string;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot | null> => {
  if (!token) return null;

  const headers = token
    ? new Headers({ Authorization: `Token ${token}` })
    : undefined;
  try {
    return responseToUser(
      await get({
        path: 'user_profile',
        headers: headers,
        abortSignal: abortSignal,
      }),
    );
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 403) {
      return null;
    }
    throw error;
  }
};

export const register = async ({
  email,
  goal,
  quizHeightUnits,
  quizHeightValue,
  quizWeightUnits,
  quizWeightValue,
  quizGoalWeightUnits,
  quizGoalWeightValue,
  variant,
  contentType,
  userQuizData,
}: {
  email: string;
  goal: number;
  quizHeightUnits: HeightUnits;
  quizHeightValue: number;
  quizWeightUnits: WeightUnits;
  quizWeightValue: number;
  quizGoalWeightUnits: WeightUnits;
  quizGoalWeightValue: number;
  variant: string | undefined;
  contentType?: 'pilates';
  userQuizData: unknown;
}): Promise<UserSnapshot> =>
  responseToUser(
    await post({
      path: 'register',
      body: {
        email: email,
        main_goal: goal,
        height_units: quizHeightUnits,
        height_value: quizHeightValue,
        weight_units: quizWeightUnits,
        weight_value: quizWeightValue,
        goal_weight_units: quizGoalWeightUnits,
        goal_weight_value: quizGoalWeightValue,
        recipes_ingredients_exclude: [],
        variant: variant,
        content_type: contentType,
        data_version: process.env.PACKAGE_VERSION,
        user_data: userQuizData,
      },
    }),
  );

export const logOut = async () => {
  try {
    await post({
      path: 'user_logout',
      body: {},
    });
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 403) {
      return;
    }
    throw error;
  }
};

export const getSafariAuthToken = async (): Promise<string> => {
  const responseObject = await post({
    path: 'get_safari_auth_token',
    body: {},
  });
  return responseObject['temp_token'];
};

export const loginBySafariAuthToken = async ({
  safariAuthToken,
}: {
  safariAuthToken: string;
}) =>
  responseToUser(
    await post({
      path: 'perform_login_by_safari_auth_token',
      body: {
        temp_token: safariAuthToken,
      },
    }),
  );

export const loginWithEmailToken = async ({
  emailToken,
  abortSignal,
}: {
  emailToken: string;
  abortSignal?: AbortSignal;
}): Promise<UserSnapshot> =>
  responseToUser(
    await post({
      path: 'perform_login_with_email_token',
      body: { email_token: emailToken },
      abortSignal,
    }),
  );
