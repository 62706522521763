import { useEffect, useRef, useState } from 'react';

type Props = {
  started: boolean;
  progressDurations: number[];
  steps: number[];
  onComplete?: () => void;
};

export const useProgressSteps = ({
  started,
  progressDurations,
  steps,
  onComplete,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [previousProgress, setPreviousProgress] = useState(0);
  const [previousStep, setPreviousStep] = useState(0); // Добавляем предыдущий шаг
  const timeElapsed = useRef(0);

  useEffect(() => {
    if (started && currentStep < steps.length) {
      const startTime = Date.now();
      let running = true;
      const handleAnimationFrame = () => {
        if (!running) {
          return;
        }
        const stepDuration = progressDurations[currentStep];
        const t = Math.min(
          (timeElapsed.current + Date.now() - startTime) / stepDuration,
          1,
        );
        const currentStepProgress = steps[currentStep] - previousStep; // Разница между текущим и предыдущим шагом
        setProgress(previousProgress + currentStepProgress * t);
        if (t === 1) {
          setCurrentStep((previousStep_) => previousStep_ + 1);
          timeElapsed.current = 0;
          setPreviousProgress(previousProgress + currentStepProgress); // Обновляем предыдущий прогресс
          setPreviousStep(steps[currentStep]); // Обновляем предыдущий шаг
        } else {
          requestAnimationFrame(handleAnimationFrame);
        }
      };
      requestAnimationFrame(handleAnimationFrame);
      return () => {
        running = false;
        timeElapsed.current += Date.now() - startTime;
      };
    } else if (currentStep >= steps.length) {
      onComplete?.();
    }
    return undefined;
  }, [
    started,
    currentStep,
    progressDurations,
    steps,
    onComplete,
    previousProgress,
    previousStep,
  ]);

  return { progress, currentStep };
};
