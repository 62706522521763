import { ComponentProps, ReactNode } from 'react';

import { UiKitButton } from 'src/components/common/ui-kit-button';

import { TpMessageKeys, useIntl } from '@features/intl';

import Alert from '../../../common/modal/alert/alert';
import styles from './alert-with-close-button.module.scss';

const AlertWithCloseButton = ({
  closeButtonText = 'OK',
  titleId,
  closeButtonId,
  footer,
  ...props
}: {
  closeButtonText?: ReactNode;
  titleId?: TpMessageKeys;
  closeButtonId?: TpMessageKeys;
  footer?: ReactNode;
} & ComponentProps<typeof Alert>) => {
  const { onHide } = props;
  const intl = useIntl();
  return (
    <Alert
      titleId={titleId}
      closeButtonId={closeButtonId}
      footer={
        <>
          {footer}
          <UiKitButton
            className={styles.button}
            fullWidth
            type="button"
            onClick={onHide}
            data-testid="alert-handle-click-button"
          >
            {closeButtonId
              ? intl.formatMessage({ id: closeButtonId, defaultMessage: 'OK' })
              : closeButtonText}
          </UiKitButton>
        </>
      }
      {...props}
    />
  );
};

export default AlertWithCloseButton;
