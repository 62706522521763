import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpSummaryStepProps } from '../../types';
import styles from './AdaptPlan.module.scss';

export const AdaptPlan = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <StepContainer
    isQuestionStep={false}
    background="LIGHT_PURPLE"
    contentContainerClassName={styles.host}
  >
    <BreaksScreen
      step={step}
      goToNextStep={goToNextStep}
      gaEventQuery="adapt_your_plan"
    />
  </StepContainer>
);
