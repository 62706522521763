import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import { Image } from 'src/components/common/Image';
import InfoHamburgerMenu from 'src/components/common/info-hamburger-menu/info-hamburger-menu';
import { UiKitOnboardingHeader } from 'src/components/common/ui-kit-onboarding-header';
import ContactFormModal from 'src/components/main/contact-form-modal/contact-form-modal';

import type { TpPilatesQuizSection } from '@features/Quiz';
import { TpMessageKeys, useIntl } from '@features/intl';

import ChevronLeft from '../../../../assets/icons/chevron-left.svg';
import SingleStar from '../../../../assets/icons/star.svg';
import TrackNext from '../../../../assets/icons/track-next.svg';
import styles from './OnboardingHeaderPilates.module.scss';

const sectionNames: {
  sectionName: TpPilatesQuizSection;
  sectionId: TpMessageKeys;
}[] = [
  { sectionName: 'Your Goal', sectionId: 'Onboarding.FirstScreen.Goal' },
  {
    sectionName: 'Current Level',
    sectionId: 'Onboarding.Header.Level',
  },
  {
    sectionName: 'Lifestyle',
    sectionId: 'Onboarding.Header.Lifestyle',
  },
  { sectionName: 'Nutrition', sectionId: 'Onboarding.Header.Nutrition' },
  {
    sectionName: 'About You',
    sectionId: 'Onboarding.Header.AboutYou',
  },
];

type Props = {
  onPreviousPress: () => void;
  onSkipPress: undefined | (() => void);
  showProgressBar?: boolean;
  showBurgerMenu: boolean;
  country?: string;
  calculateProgress: ({
    quizSection,
  }: {
    quizSection: TpPilatesQuizSection;
  }) => number;
  sectionName: string;
  hideTopBar?: boolean;
};
export const OnboardingHeader = ({
  onPreviousPress,
  onSkipPress,
  showProgressBar = true,
  showBurgerMenu,
  country,
  calculateProgress,
  sectionName,
  hideTopBar = false,
}: Props) => {
  const intl = useIntl();

  const handleScrollToRating = useCallback(() => {
    const element = document.querySelector('#rating-section');
    element != null && element.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const createDataSection = useCallback(
    (sectionName: TpPilatesQuizSection) => ({
      sectionName,
      value: calculateProgress({ quizSection: sectionName }),
    }),
    [calculateProgress],
  );

  const DATA_SECTIONS = useMemo(
    () => sectionNames.map((element) => createDataSection(element.sectionName)),
    [createDataSection],
  );

  const [showContactFormModal, setShowContactFormModal] = useState(false);

  const handleContactFormModalHide = useCallback(() => {
    setShowContactFormModal(false);
  }, []);

  const titleText = intl.formatMessage(
    {
      id: 'Onboarding.Header.Country',
      defaultMessage: `Top app in ${country}`,
    },
    {
      mark: country,
    },
  );

  const screenSectionName = useCallback(
    (sectionName: string) => {
      const section = sectionNames.find(
        (element) => element.sectionName === sectionName,
      );

      if (section && section.sectionId) {
        return intl.formatMessage({
          id: section?.sectionId as TpMessageKeys,
          defaultMessage: section?.sectionName as string,
        });
      }

      return '';
    },
    [intl],
  );

  return (
    <>
      <UiKitOnboardingHeader
        className={classNames({
          [styles.hiddenTopBar]: hideTopBar,
        })}
        title={titleText as string}
        logoIcon={
          <Image src="top.svg" width={24} height={24} alt="top rated" />
        }
        logoIconClassName={styles.headerButtonUiKit}
        handleScrollToRating={handleScrollToRating}
        startIcon={<SingleStar width={13} height={13} />}
        rating="4.5"
        showPreviousButton={!showBurgerMenu}
        infoHamburgerMenuElement={
          showBurgerMenu ? (
            <InfoHamburgerMenu
              toggleClassName={styles.headerButton}
              handleShowFeedback={() => setShowContactFormModal(true)}
            />
          ) : null
        }
        sectionName={screenSectionName(sectionName) as string}
        onPreviousPress={onPreviousPress}
        additionalButtonHandle={onSkipPress}
        additionalButtonIcon={<TrackNext />}
        showProgressBar={showProgressBar}
        chevronLeftIcon={<ChevronLeft />}
        chevronLeftIconClassName={styles.navigationButton}
        sectionsArray={DATA_SECTIONS}
      />
      <ContactFormModal
        fromLabel="Youth web - pilates welcome screen"
        isVisible={showContactFormModal}
        setIsVisible={handleContactFormModalHide}
      />
    </>
  );
};
