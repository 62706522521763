import React, { useEffect, useState } from 'react';

import { Image } from 'src/components/common/Image';
import { getLocaleImage, useProgressSteps } from 'src/utils';

import { BreaksWithLoader } from '@features/Quiz/components/BreaksWithLoader';
import { TpMessageKeys } from '@features/intl';

import { TpSummaryStepProps } from '../../types';
import styles from './YourCommunity.module.scss';

const LOADER_DATA: {
  image: React.ReactNode;
  titleId: TpMessageKeys;
  defaultTitleMessage: string;
  subtitleId: TpMessageKeys;
  defaultSubtitleMessage: string;
  titleIdMark: TpMessageKeys;
  defaultTitleMessageMark: string;
  loaderTextId: TpMessageKeys;
  loaderTextDefaultMessage: string;
}[] = [
  {
    image: (
      <Image
        src={`cutaway-your-community${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Find',
    defaultTitleMessage: 'Find {br}',
    titleIdMark: 'Onboarding.Breaks.Find.Br',
    defaultTitleMessageMark: 'your community',
    subtitleId: 'Onboarding.Breaks.Find.Text',
    defaultSubtitleMessage:
      'Join over 15 million users who lost weight and transformed their lives with our highly rated app"',
    loaderTextId: 'Onboarding.Breaks.Find.Loading',
    loaderTextDefaultMessage: 'Youth is syncing your answers with data',
  },
  {
    image: (
      <Image
        src={`cutaway-available${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Available',
    defaultTitleMessage: 'Available {br}',
    titleIdMark: 'Onboarding.Breaks.Available.Br',
    defaultTitleMessageMark: '24/7',
    subtitleId: 'Onboarding.Breaks.Available.Text',
    defaultSubtitleMessage:
      'Achieve great and consistent results without committing to harsh and energy-draining diets',
    loaderTextId: 'Onboarding.Breaks.Available.Loading',
    loaderTextDefaultMessage: 'Youth is looking for your personal assistant',
  },
  {
    image: (
      <Image
        src={`cutaway-reliable${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Reliable',
    defaultTitleMessage: 'Reliable and {br}',
    titleIdMark: 'Onboarding.Breaks.Reliable.Br',
    defaultTitleMessageMark: 'trusted',
    subtitleId: 'Onboarding.Breaks.Reliable.Text',
    defaultSubtitleMessage:
      'We base our research on up-to-date studies and collaborate with best experts on the market',
    loaderTextId: 'Onboarding.Breaks.Reliable.Loading',
    loaderTextDefaultMessage: 'Youth is adjusting recommendations',
  },
  {
    image: <Image src="cutaway-doctors.webp" alt="" fill={true} />,
    titleId: 'Onboarding.Breaks.Doctors',
    defaultTitleMessage: 'Doctors {br}',
    titleIdMark: 'Onboarding.Breaks.Doctors.Br',
    defaultTitleMessageMark: 'recommend',
    subtitleId: 'Onboarding.Breaks.Doctors.Text',
    defaultSubtitleMessage:
      'Health professionals claim that pilates is great for flexibility, strength and mind-body awareness',
    loaderTextId: 'Onboarding.Breaks.Doctors.Loading',
    loaderTextDefaultMessage: 'Youth is almost done',
  },
];

export const YourCommunity = ({ goToNextStep }: TpSummaryStepProps) => {
  const { progress } = useProgressSteps({
    started: true,
    progressDurations: [2000, 2000, 2500, 2000],
    steps: [20, 50, 80, 100],
  });

  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const totalSteps = LOADER_DATA.length;
    const progressPerStep = 100 / totalSteps;

    const index = Math.floor(progress / progressPerStep);
    setCurrentCardIndex(index >= totalSteps ? totalSteps - 1 : index);

    if (progress >= 100) {
      goToNextStep();
    }
  }, [goToNextStep, progress]);

  const currentCard = LOADER_DATA[currentCardIndex];

  return (
    <BreaksWithLoader
      currentCard={currentCard}
      progress={progress}
      extraClassname={styles.yourCommunity}
    />
  );
};
