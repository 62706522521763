import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitInput } from 'src/components/common/ui-kit-input';

import { useAnalytics } from '@features/Analytics';
import { FormattedMessage, useIntl } from '@features/intl';

import * as commonApi from '../../../api/common';
import Button from '../../common/button/button';
import Alert from '../../common/modal/alert/alert';
import FormModal from '../../common/modal/form-modal/form-modal';
import styles from './contact-form-modal.module.scss';

const formInitialValues = {
  email: '',
  message: '',
};

export interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  fromLabel?: string;
}

const ContactFormModal = observer(
  ({ isVisible, fromLabel, setIsVisible }: Props) => {
    const [isSubmittedAlertShown, setIsSubmittedAlertShown] = useState(false);
    const intl = useIntl();
    const {
      authStore: { user },
    } = useStores();

    const { trackGoogleEvent } = useAnalytics();

    const titleMessage = intl.formatMessage({
      id: 'Onboarding.SupportPage.Support',
      defaultMessage: 'Support',
      asString: true,
    });

    const leaveRequestMessage = intl.formatMessage({
      id: 'Onboarding.SupportPage.Message',
      defaultMessage: 'Leave your request here',
      asString: true,
    });

    const emailFieldMessage = intl.formatMessage({
      id: 'Onboarding.SupportPage.EnterEmail',
      defaultMessage: 'Email is a required field',
      asString: true,
    });

    const messageFieldMessage = intl.formatMessage({
      id: 'Onboarding.SupportPage.MessageField',
      defaultMessage: 'Message is a required field',
      asString: true,
    });

    const enterEmailMessage = intl.formatMessage({
      id: 'Onboarding.SupportPage.EnterEmail',
      defaultMessage: 'Enter your email',
      asString: true,
    });

    const formSchema = yup.object().shape({
      email: yup
        .string()
        .trim()
        .required(emailFieldMessage as string)
        .email(
          intl.formatMessage({
            asString: true,
            id: 'Onboarding.Email.Сlue.Error',
            defaultMessage: 'Something went wrong',
          }) as string,
        ),
      message: yup
        .string()
        .trim()
        .required(messageFieldMessage as string),
    });

    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      resetForm,
      touched,
      errors,
      isSubmitting,
      isValid,
    } = useFormik({
      initialValues: formInitialValues,
      validationSchema: formSchema,
      onSubmit: async (values) => {
        trackGoogleEvent({
          eventName: 'help_tapped',
          options: {
            place: fromLabel,
            email: values.email,
          },
        });

        await commonApi.sendFeedback({
          email: values.email,
          message: values.message,
          userId: user?.serverId,
          isSubscriber: user?.isSubscriptionActive,
          fromLabel: fromLabel,
        });

        setIsSubmittedAlertShown(true);
        setIsVisible(false);
      },
    });

    useEffect(() => {
      if (isVisible) {
        resetForm();
        setIsSubmittedAlertShown(false);
        trackGoogleEvent({
          eventName: 'help_opened',
          options: {
            place: fromLabel,
            email: user?.email,
          },
        });
      }
    }, [fromLabel, isVisible, resetForm, trackGoogleEvent, user?.email]);

    return (
      <>
        <FormModal
          show={isVisible}
          onHide={() => setIsVisible(false)}
          title={titleMessage}
          description={
            <FormattedMessage
              id="Onboarding.SupportPage.Issue"
              defaultMessage="Having an issue?"
            />
          }
          onSubmit={handleSubmit}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submitButtonText={intl.formatMessage({
            id: 'Onboarding.SupportPage.SendButton',
            defaultMessage: 'Send',
          })}
        >
          <div className={styles.inputContainer}>
            <UiKitInput
              fullWidth
              type="email"
              autoComplete="email"
              placeholder={enterEmailMessage as string}
              required={true}
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && errors.email != null}
              errorText={errors.email}
              errorTextClassName={styles.errorText}
            />
          </div>
          <div>
            <UiKitInput
              fullWidth
              className={styles.messageInput}
              as="textarea"
              placeholder={leaveRequestMessage as string}
              required={true}
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.message && errors.message != null}
              errorText={errors.message}
              errorTextClassName={styles.errorText}
            />
          </div>
        </FormModal>
        <Alert
          show={isSubmittedAlertShown}
          onHide={() => setIsSubmittedAlertShown(false)}
          image={
            <Image
              src="victory-hand.webp"
              width={80}
              height={80}
              alt="victory"
            />
          }
          title={
            <FormattedMessage
              defaultMessage="Thanks!"
              id="Onboarding.Checkout.Popup.Thanks"
            />
          }
          description={
            <FormattedMessage
              defaultMessage="Your request has been sent. We will contact you shortly with an update."
              id="Onboarding.Checkout.Popup.ThanksMore"
            />
          }
          footer={
            <Button handleClick={() => setIsSubmittedAlertShown(false)}>
              <FormattedMessage
                defaultMessage="Thank you"
                id="Onboarding.Checkout.Popup.ThanksButton"
              />
            </Button>
          }
        />
      </>
    );
  },
);

export default ContactFormModal;
