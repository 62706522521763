import classNames from 'classnames';
import React from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { getBMI } from 'src/utils/utils-health';

import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import styles from './tooltipBMI.module.scss';

type TooltipProps = {
  currentWeight?: number | undefined;
  unit: string;
  currentBMI?: number;
  isOnFinalScreen?: boolean;
};

type CategoryData = {
  category: string;
  categoryId: string;
  min: number;
  max: number;
  textMessage: string;
  style: string;
};
export const Tooltip: React.FC<TooltipProps> = ({
  currentWeight,
  unit,
  currentBMI,
  isOnFinalScreen = false,
}) => {
  const {
    quizStore: { setCurrentBMI, heightValue, heightUnits },
  } = useStores();
  const intl = useIntl();
  const currentUnit = unit === 'lbs' ? 'pounds' : 'kilograms';
  const someOrThese = unit === 'lbs' ? 'some' : 'these';

  const bmiCategories = [
    {
      category: 'Underweight',
      categoryId: 'Onboarding.Weight.Tip1.Title2',
      min: 0,
      max: 18.4,
      textMessage: (
        <FormattedMessage
          defaultMessage="There’s no need to lose weight! You need to gain more muscles"
          id="Onboarding.Weight.Tip1.Text"
        />
      ),
      text: 'There’s no need to lose weight! You need to gain more muscles',
      style: 'purple',
    },
    {
      category: 'Normal',
      categoryId: 'Onboarding.Weight.Tip2.Title2',
      min: 18.5,
      max: 22.9,
      textMessage: (
        <FormattedMessage
          defaultMessage={`Great! You have a normal BMI, it will be easy to lose a few ${currentUnit}🔥`}
          id="Onboarding.Weight.Tip2.Text"
          values={{
            currentUnit,
          }}
        />
      ),
      text: `Great! You have a normal BMI, it will be easy to lose a few ${currentUnit}🔥`,
      style: 'green',
    },
    {
      category: 'Overweight',
      categoryId: 'Onboarding.Weight.Tip3.Title2',
      min: 23,
      max: 24.9,
      textMessage: (
        <FormattedMessage
          defaultMessage={`Let’s achieve your goals together and lose ${someOrThese} ${currentUnit}!`}
          id="Onboarding.Weight.Tip3.Text"
          values={{
            someOrThese,
            currentUnit,
          }}
        />
      ),
      text: `Let’s achieve your goals together and lose ${someOrThese} ${currentUnit}!`,
      style: 'yellow',
    },
    {
      category: 'Moderately obese',
      categoryId: 'Onboarding.Weight.Tip4.Title2',
      min: 25,
      max: 29.9,
      textMessage: (
        <FormattedMessage
          defaultMessage={`Let’s achieve your goals together and lose ${someOrThese} ${currentUnit}!`}
          id="Onboarding.Weight.Tip4.Text"
          values={{
            someOrThese,
            currentUnit,
          }}
        />
      ),
      style: 'orange',
    },
    {
      category: 'Severely obese',
      categoryId: 'Onboarding.Weight.Tip5.Title2',
      min: 30,
      max: Number.POSITIVE_INFINITY,
      textMessage: (
        <FormattedMessage
          defaultMessage={`Let’s achieve your goals together and lose ${someOrThese} ${currentUnit}!`}
          id="Onboarding.Weight.Tip5.Text"
          values={{
            someOrThese,
            currentUnit,
          }}
        />
      ),
      style: 'red',
    },
  ];

  let valueBMI;

  if (isOnFinalScreen) {
    valueBMI = currentBMI;
  } else {
    valueBMI = getBMI({
      weightUnits: unit,
      weightValue: currentWeight,
      heightUnits: heightUnits!,
      heightValue: heightValue!,
    });
    if (valueBMI) {
      setCurrentBMI(valueBMI);
    }
  }

  const getCategoryData = (bmi: number): CategoryData | undefined =>
    // @ts-ignore
    bmiCategories.find(
      (category) => bmi >= category.min && bmi <= category.max,
    );

  const categoryData = getCategoryData(valueBMI || 0);

  const tooltipClassName = classNames(
    styles.tooltip,
    styles[categoryData!.style],
    {
      [styles.tooltipOnFinalScreen]: isOnFinalScreen,
    },
  );

  return (
    <div className={tooltipClassName}>
      <div className={styles.header}>
        <b className={styles.title}>
          <FormattedMessage
            id="Onboarding.Weight.Tip1.Title1"
            defaultMessage="Your BMI:"
          />
        </b>
        <span className={classNames(styles.bmi, styles[categoryData!.style])}>
          {valueBMI}
        </span>
        <div
          className={classNames(styles.category, styles[categoryData!.style])}
        >
          {intl.formatMessage({
            id: categoryData!.categoryId as TpMessageKeys,
            defaultMessage: categoryData!.category,
          })}
        </div>
      </div>
      <p className={styles.subTitle}>{categoryData?.textMessage}</p>
    </div>
  );
};
