export { languageDetector } from './languageDetector';

export { getMessages } from './service';

export { IntlProvider } from './provider';
export { useIntl } from './useFormatMessage';
export { FormattedMessage } from './components/FormattedMessage';
export type {
  TpLocale,
  TpMessages,
  TpMessageKeys,
  TpFormattedMessage,
} from './types';
