import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import { FormattedMessage } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './Welcome.module.scss';

export const Welcome = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { getSingleOptionSelectStepValue, setAgePilates },
    } = useStores();
    const { options, stepName } = step;
    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setAgePilates(event.target.value);
        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_start_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [handleEventSend, setAgePilates],
    );

    const RenderSubtitle = useCallback(
      () => (
        <>
          <h1 className={styles.header}>
            <FormattedMessage
              defaultMessage="PILATES {br} WORKOUTS"
              id="Onboarding.FirstScreen.Title"
              values={{ br: <br /> }}
            />
          </h1>
          <p className={styles.subtitle}>
            <span>
              <FormattedMessage
                defaultMessage="According to your {mark}"
                id="Onboarding.FirstScreen.Subtitle"
                values={{
                  mark: <b>age</b>,
                }}
              />
            </span>
            <span>
              <FormattedMessage
                defaultMessage="{mark} quiz"
                id="Onboarding.FirstScreen.Subtitle.Quiz"
                values={{
                  mark: <b>1-minute</b>,
                }}
              />
            </span>
          </p>
        </>
      ),
      [],
    );

    return (
      <div className={styles.mainContainer}>
        <div>
          <RenderSubtitle />
        </div>
        <div className={styles.container}>
          <UiKitRadioGroup
            name="welcome-pilates"
            defaultValue={value}
            onChange={handleRadioChange}
            variant="square"
            onClick={goToNextStep}
          >
            {options.map(({ value, label, image }) => (
              <UiKitRadioOption
                key={`${stepName}_${value}`}
                label={label}
                value={value}
                startIcon={image}
                //TODO: refactor singleoption without unnecessary padding
                labelClassName={styles.label}
                startIconClassName={styles.image}
                textClassName={styles.text}
              />
            ))}
          </UiKitRadioGroup>
        </div>
      </div>
    );
  },
);
