import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpQuizStepQuestionProps } from '../../types';

export const TypicalDay = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setTypicalDay },
    } = useStores();
    const { options, stepName } = step;

    return (
      <StepContainer>
        <SingleOption
          stepName={stepName}
          name="typical-day"
          options={options}
          handleEventSend={handleEventSend}
          setterFunction={setTypicalDay}
          goToNextStep={goToNextStep}
          eventName="onboarding_describe_typical_day_chosen"
        />
      </StepContainer>
    );
  },
);
