import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useCallback, useMemo } from 'react';

import { isDevelopment, retry } from 'src/utils';

import { postFacebookEvent } from '@features/Analytics/api/facebook';
import type { TpFacebookParameters } from '@features/Analytics/types';

export const useFacebookEvent = () => {
  const externalId = Cookies.get('extId');
  const fbp = Cookies.get('_fbp');

  const { mutateAsync: postEvent } = useMutation({
    mutationFn: postFacebookEvent,
  });

  const conditionCheckFunction = useCallback(
    async () =>
      // @ts-ignore
      window?.fbq != null,
    [],
  );

  const fetchClientEventWithRetry = useCallback(
    async ({ eventName, customData, options }: TpFacebookParameters) => {
      const isCustomEvent = eventName === 'SecondScreen';
      const eventId = `${eventName}.${fbp}`;
      const actionFunction = async () => {
        window?.fbq?.(
          isCustomEvent ? 'trackCustom' : 'track',
          eventName,
          { ...options, ...customData },
          {
            eventID: eventId,
            extern_id: externalId,
          },
        );
      };

      await retry({
        conditionCheckFunction,
        actionFunction,
        retryDelay: 500,
      });

      if (!isDevelopment) {
        await postEvent({
          eventId,
          eventName,
          customData,
          fbp: fbp ?? '',
          options,
          ...(externalId != null && { externalId }),
        });
      }
    },
    [conditionCheckFunction, externalId, fbp, postEvent],
  );

  return useMemo(
    () => ({
      clientEvent: ({
        eventName,
        customData,
        options,
      }: TpFacebookParameters) => {
        fbp &&
          fetchClientEventWithRetry({
            eventName,
            customData,
            options,
          });
      },
    }),
    [fbp, fetchClientEventWithRetry],
  );
};
