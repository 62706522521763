import { ReactElement, memo, useCallback, useEffect, useMemo } from 'react';

import { Image } from 'src/components/common/Image';
import { CircleAnimatedProgressPause } from 'src/components/main/quiz/circle-animated-progress-pause/circle-animated-progress-pause';
import { useTimer } from 'src/utils/hooks/use-timer';

import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import { TpSummaryStepProps } from '../../types';
import { Review } from './Review';
import styles from './million-users.module.scss';

const DURATION = 8000;

type TpRating = {
  image: ReactElement;
  rating: number;
  content: string;
  contentId: string;
};
const RATING_DATA = [
  {
    image: (
      <Image
        src="app-store-circle.svg"
        width={32}
        height={32}
        alt="app-store-circle"
      />
    ),
    rating: 4.9,
    content: 'rating',
    contentId: 'Onboarding.Loader.Rating1',
  },
  {
    image: (
      <Image src="play-market.svg" width={32} height={32} alt="play-market" />
    ),
    rating: 4.8,
    content: 'rating',
    contentId: 'Onboarding.Loader.Rating1',
  },
  {
    image: (
      <Image src="star-rating.svg" width={32} height={32} alt="star-rating" />
    ),
    rating: 4.8,
    content: '4 837 reviews',
    contentId: 'Onboarding.Loader.Rating3',
  },
];

const RatingItem = memo(({ rating }: { rating: TpRating }) => (
  <li>
    <div className={styles.ratingContainer}>
      <div>{rating.image}</div>
      <div>
        <h3 className={styles.ratingText}>{rating.rating}</h3>
        <h4 className={styles.content}>
          <FormattedMessage
            defaultMessage="rating"
            id={rating.contentId as TpMessageKeys}
          />
        </h4>
      </div>
    </div>
  </li>
));

RatingItem.displayName = 'RatingItem';

export const MillionUsers = ({ goToNextStep, step }: TpSummaryStepProps) => {
  const { titleId, defaultTitleMessage } = step;
  const intl = useIntl();
  const { timer } = useTimer({
    delay: 100,
    startImmediately: true,
    callback: (timer) => {
      if (timer === DURATION) handleNextStep();
    },
  });

  const progressPercent = useMemo(() => timer / DURATION, [timer]);

  useEffect(() => {
    clearTimeout(DURATION);
  }, []);

  const handleNextStep = useCallback(() => {
    goToNextStep({ eventName: 'onboarding_final_summary_opened' });
  }, [goToNextStep]);

  return (
    <div className={styles.container}>
      <div className={styles.containerCircle}>
        <CircleAnimatedProgressPause progressPercent={progressPercent} />
      </div>
      <p className={styles.subtitle}>
        <FormattedMessage
          defaultMessage="Creating your personal program.."
          id="Onboarding.Loader.Loader"
        />
      </p>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: titleId,
          defaultMessage: defaultTitleMessage,
        })}
      </h2>
      <ul className={styles.rating}>
        {RATING_DATA.map((rating, index) => (
          <RatingItem key={index} rating={rating} />
        ))}
      </ul>
      <Review />
    </div>
  );
};
