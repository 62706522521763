import { Image } from 'src/components/common/Image';

import styles from './AdvantagesList.module.scss';

const ADVANTAGES = [
  {
    title: '50+ pilates exercises focused on your problem zones',
    icon: 'advantage-1.webp',
  },
  {
    title: '10+ personalized meal plans to nourish your dream body',
    icon: 'advantage-2.webp',
  },
  {
    title: '300+ easy recipes with ingredients from your store',
    icon: 'advantage-3.webp',
  },
  {
    title: 'Efficient water tracker to help you stay hydrated',
    icon: 'advantage-4.webp',
  },
  {
    title: 'Wide selection of 10-minute quick daily workouts',
    icon: 'advantage-5.webp',
  },
];

export const AdvantagesList = () => (
  <ul>
    {ADVANTAGES.map((advantage) => (
      <li key={advantage.title} className={styles.item}>
        <Image
          src={advantage.icon}
          width={24}
          height={24}
          alt={advantage.icon}
          className={styles.icon}
        />
        <p>{advantage.title}</p>
      </li>
    ))}
  </ul>
);
