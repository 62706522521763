import classNames from 'classnames';
import { ComponentProps, ReactNode, useCallback, useEffect } from 'react';

import Close from '../../../../assets/icons/close.svg';
import CircleButton from '../../circle-button/circle-button';
import MobileHeader from '../../header/mobile-header/mobile-header';
import ModalContainer from '../modal-container/modal-container';
import styles from './modal.module.scss';

export interface Props extends ComponentProps<typeof ModalContainer> {
  showCloseButton?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  handleCloseModal?: () => void | null;
}

const CloseButton = (props: ComponentProps<typeof CircleButton>) => (
  <CircleButton {...props}>
    <Close />
  </CircleButton>
);

const Modal = ({
  className,
  backdropClassName,
  dialogClassName,
  showCloseButton = true,
  title,
  description,
  footer,
  children,
  handleCloseModal,
  ...props
}: Props) => {
  const { show, onHide, animation } = props;
  const handleClose = useCallback(() => {
    if (handleCloseModal) {
      handleCloseModal();
    }
    if (onHide) {
      onHide();
    }
  }, [handleCloseModal, onHide]);

  // Making the content outside the modal slide to the left.
  // The counter is necessary so that the transform value is restored only
  // once all the nested modals close.
  useEffect(() => {
    if (show && (animation == null || animation)) {
      const body = document.body;
      body.dataset.animatedModalsOpen =
        +(body.dataset.animatedModalsOpen ?? 0) + 1 + '';
      return () => {
        body.dataset.animatedModalsOpen =
          +body.dataset.animatedModalsOpen! - 1 + '';
      };
    }
    return undefined;
  }, [show, animation]);

  return (
    <ModalContainer
      backdropClassName={classNames(styles.backdrop, backdropClassName)}
      className={classNames(styles.container, className)}
      dialogClassName={classNames(styles.modal, dialogClassName)}
      backdrop={showCloseButton ? undefined : 'static'}
      {...props}
    >
      {title && (
        <MobileHeader
          className={styles.mobileHeader}
          TitleComponent="h2"
          title={title}
          right={
            showCloseButton && (
              <CloseButton
                className={styles.headerCloseButton}
                onClick={handleClose}
              />
            )
          }
        />
      )}
      {showCloseButton && (
        <CloseButton className={styles.closeButton} onClick={onHide} />
      )}
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p className={styles.description}>{description}</p>}
      {children}
      {footer && <div className={styles.footerContainer}>{footer}</div>}
    </ModalContainer>
  );
};

export default Modal;
