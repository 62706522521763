import React from 'react';

import { UiKitInput } from 'src/components/common/ui-kit-input';

const DecimalNumberFormControl = (
  props: React.InputHTMLAttributes<HTMLInputElement> & {
    isInvalid?: boolean;
  },
) => (
  // @ts-ignore
  <UiKitInput
    fullWidth
    type="number"
    inputMode="decimal"
    pattern="[0-9]*"
    {...props}
  />
);

export default DecimalNumberFormControl;
