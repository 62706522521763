import Head from 'next/head';

import { IMAGE_SOURCE } from 'src/utils/constants';

export const QuizHead = () => (
  <Head>
    <link rel="preload" as="image" href={IMAGE_SOURCE + '18-35-pilates.webp'} />
    <link rel="preload" as="image" href={IMAGE_SOURCE + '35-45-pilates.webp'} />
    <link rel="preload" as="image" href={IMAGE_SOURCE + '45-55-pilates.webp'} />
    <link
      rel="preload"
      as="image"
      href={IMAGE_SOURCE + '55plus-pilates.webp'}
    />
  </Head>
);
