import { memo } from 'react';
import Slider from 'react-slick';

import { Image } from 'src/components/common/Image';

import { FormattedMessage, TpMessageKeys } from '@features/intl';

import styles from './review.module.scss';

const REVIEW_ARRAY = [
  {
    subtitle: '13 hours ago',
    subtitleId: 'Onboarding.Loader.Card.Subtitle',
    avatar: 'JA',
    content:
      'Seeing changes in the mirror after just a week!!!!️ Excited to keep going and achieve my dream body in 3 months 😊 ',
    contentId: 'Onboarding.Loader.Card.Content',
  },
  {
    subtitle: '18 hours ago',
    subtitleId: 'Onboarding.Loader.Card2.Subtitle',
    avatar: 'KL',
    content:
      'Incredible results! Down several pounds and feeling fantastic. Highly recommend.',
    contentId: 'Onboarding.Loader.Card2.Content',
  },
  {
    subtitle: '2 days ago',
    subtitleId: 'Onboarding.Loader.Card3.Subtitle',
    avatar: 'AN',
    content:
      'A must-try. Personal program and easy recipes in this app have helped me lose weight. Thank you!',
    contentId: 'Onboarding.Loader.Card3.Content',
  },
];

const sliderSettings = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const Review = memo(() => (
  <Slider className={styles.sliderContainer} {...sliderSettings}>
    {REVIEW_ARRAY.map((item, index) => (
      <div key={index} className={styles.review}>
        <p className={styles.reviewSubtitle}>
          <FormattedMessage
            defaultMessage={item.subtitle}
            id={item.subtitleId as TpMessageKeys}
          />
        </p>
        <div className={styles.reviewContainer}>
          <div className={styles.reviewAvatar}>{item.avatar}</div>
          <div className={styles.reviewStars}>
            {Array.from({ length: 5 }, (_, index) => (
              <Image
                key={index}
                src="review-star.svg"
                width={16}
                height={16}
                alt="review-sta"
              />
            ))}
          </div>
        </div>
        <p className={styles.reviewContent}>
          <FormattedMessage
            defaultMessage={item.content}
            id={item.contentId as TpMessageKeys}
          />{' '}
        </p>
      </div>
    ))}
  </Slider>
));

Review.displayName = 'Review';
