import { get, post } from 'src/api/api';
import {
  MobileOperatingSystem,
  getMobileOperatingSystem,
} from 'src/utils/get-mobile-operating-system';

import { ANALYTICS_ENDPOINT_V2 } from '@features/Analytics/constants';
import { createTestSegmentFromQueryParameters } from '@features/Analytics/utils';

export const sendAnalyticsId = async ({
  googlePseudoId,
  userPseudoIdApplePay,
  userPseudoIdWinback,
  mg,
}: {
  googlePseudoId?: string;
  userPseudoIdApplePay?: string;
  userPseudoIdWinback?: string;
  mg: number;
}) =>
  await post({
    endpoint: ANALYTICS_ENDPOINT_V2,
    path: 'id_aggregator',
    body: {
      user_pseudo_id: googlePseudoId,
      origin: 'youth',
      mg: mg,
      user_pseudo_id_apple_pay: userPseudoIdApplePay,
      user_pseudo_id_winback: userPseudoIdWinback,
    },
  });

export const getAbTests = async ({
  variant,
  utm_source,
  utm_campaign,
  region,
  abortSignal,
}: {
  variant: string | undefined;
  utm_source: string | undefined;
  utm_campaign: string | undefined;
  region: string | undefined;
  abortSignal?: AbortSignal;
}) => {
  const isAndroidDevice =
    getMobileOperatingSystem() === MobileOperatingSystem.ANDROID
      ? 'android'
      : '';
  const isIosDevise =
    getMobileOperatingSystem() === MobileOperatingSystem.IOS ? 'ios' : '';
  const basePath = 'ab_tests';
  const fullUrl = `${basePath}?app=youth&build=${
    process.env.NEXT_PUBLIC_ENV === 'development' ? 'test' : 'prod'
  }&os=${
    isAndroidDevice || isIosDevise
  }&variant=${variant}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&region=${region}`;

  const pseudoData = createTestSegmentFromQueryParameters();

  return (
    pseudoData ??
    (await get({
      endpoint: ANALYTICS_ENDPOINT_V2,
      path: fullUrl,
      abortSignal,
    }))
  );
};
