import React, { useEffect, useState } from 'react';

import { Image } from 'src/components/common/Image';
import { getLocaleImage, useProgressSteps } from 'src/utils';

import { BreaksWithLoader } from '@features/Quiz/components/BreaksWithLoader';
import { TpMessageKeys } from '@features/intl';

import { TpSummaryStepProps } from '../../types';

const LOADER_DATA: {
  image: React.ReactNode;
  titleId: TpMessageKeys;
  defaultTitleMessage: string;
  subtitleId: TpMessageKeys;
  defaultSubtitleMessage: string;
  titleIdMark: TpMessageKeys;
  defaultTitleMessageMark: string;
  loaderTextId: TpMessageKeys;
  loaderTextDefaultMessage: string;
}[] = [
  {
    image: (
      <Image
        src={`cutaway-sleep-hygiene${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Improve',
    defaultTitleMessage: 'Improve {br}',
    titleIdMark: 'Onboarding.Breaks.Improve.Br',
    defaultTitleMessageMark: 'sleep hygiene',
    subtitleId: 'Onboarding.Breaks.Improve.Text',
    defaultSubtitleMessage:
      'Stable and consistent sleeping schedule is key for your youthful look and feeling good',
    loaderTextId: 'Onboarding.Breaks.Improve.Loading',
    loaderTextDefaultMessage: 'Youth is analyzing your body data',
  },
  {
    image: (
      <Image
        src={`cutaway-your-home${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Home',
    defaultTitleMessage: 'Your home {br}',
    titleIdMark: 'Onboarding.Breaks.Home.Br',
    defaultTitleMessageMark: 'is your gym',
    subtitleId: 'Onboarding.Breaks.Home.Text',
    defaultSubtitleMessage:
      'Lose weight using simple, at-home solutions that are good for you and the planet',
    loaderTextId: 'Onboarding.Breaks.Home.Loading',
    loaderTextDefaultMessage: 'Youth is adapting to your preferences',
  },
  {
    image: (
      <Image
        src={`cutaway-stay-motivated${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    titleId: 'Onboarding.Breaks.Stay',
    defaultTitleMessage: 'Stay {br}',
    titleIdMark: 'Onboarding.Breaks.Stay.Br',
    defaultTitleMessageMark: 'motivated',
    subtitleId: 'Onboarding.Breaks.Stay.Text',
    defaultSubtitleMessage:
      'Feel energized and confident every day, with your personal plan to keep you on track',
    loaderTextId: 'Onboarding.Breaks.Stay.Loading',
    loaderTextDefaultMessage: 'Youth is choosing the right pace',
  },
];

export const SleepHygiene = ({ goToNextStep }: TpSummaryStepProps) => {
  const { progress } = useProgressSteps({
    started: true,
    progressDurations: [2000, 3000, 2500],
    steps: [20, 80, 100],
  });

  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const totalSteps = LOADER_DATA.length;
    const progressPerStep = 100 / totalSteps;

    const index = Math.floor(progress / progressPerStep);
    setCurrentCardIndex(index >= totalSteps ? totalSteps - 1 : index);

    if (progress >= 100) {
      goToNextStep();
    }
  }, [goToNextStep, progress]);

  const currentCard = LOADER_DATA[currentCardIndex];

  return <BreaksWithLoader currentCard={currentCard} progress={progress} />;
};
