// Once more localization features are required,
// we should use react-intl instead

export const formatNumber = (value: number) =>
  new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  }).format(value);

export const formatMoney = (value: number, currency: string) =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: Number.isInteger(value) ? 0 : undefined,
  }).format(value);

export const currencyReplacer = (currency: string) => {
  switch (currency) {
    case 'USD':
    case 'BRL':
    case 'AUD':
    case 'CAD':
    case 'NZD':
    case 'SGD':
    case 'HKD':
    case 'MXN': {
      return '$';
    }
    case 'EUR': {
      return '€';
    }
    case 'GBP': {
      return '£';
    }
    case 'RUB': {
      return '₽';
    }
    case 'CNY': {
      return '¥';
    }
    case 'JPY': {
      return '¥';
    }
    case 'PLN': {
      return 'zł';
    }
    case 'SEK': {
      return 'kr';
    }
    case 'ZAR': {
      return 'R';
    }
    case 'CZK': {
      return 'Kč';
    }
    case 'HUF': {
      return 'Ft';
    }
    case 'DKK': {
      return 'kr';
    }
    case 'INR': {
      return '₹';
    }
    case 'KRW': {
      return '₩';
    }
    case 'TWD': {
      return 'NT$';
    }
    case 'NOK': {
      return 'kr';
    }
    case 'ILS': {
      return '₪';
    }
    case 'THB': {
      return '฿';
    }
    case 'UAH': {
      return '₴';
    }
    default: {
      return currency;
    }
  }
};
