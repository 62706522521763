import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { UiKitButton } from 'src/components/common/ui-kit-button';
import { ALL_VARIANTS } from 'src/constants/variants';
import { getDataFromSessionStorage, groupBy } from 'src/utils';

import { useAnalyticsDebugger } from '@features/Analytics/hooks/useAnalytics';

import styles from './debug-ui.module.scss';

type TpSource = 'PT' | 'GA' | 'FB';

export const DebugUi = () => {
  const [activeTab, setActiveTab] = useState<TpSource>('GA');
  const [hasMounted, setHasMounted] = useState(false);
  const variantFromStorage =
    getDataFromSessionStorage({
      store: 'authStore',
      key: 'variant',
    }) ?? 'Pilates';
  const [activeVariant, setActiveVariant] = useState(variantFromStorage);

  const { events, clearEvents, eventsNumberVisible, setEventsNumberVisible } =
    useAnalyticsDebugger();

  const toggleEventsNumber = useCallback(
    () => setEventsNumberVisible(!eventsNumberVisible),
    [eventsNumberVisible, setEventsNumberVisible],
  );

  const grouped = useMemo(() => groupBy(events, 'source'), [events]);

  const filteredByGroup = useMemo(
    () => grouped[activeTab],
    [activeTab, grouped],
  );

  const handleButtonClick = useCallback((value: TpSource) => {
    setActiveTab(value);
  }, []);

  const clearHandler = useCallback(() => {
    clearEvents();
  }, [clearEvents]);

  const handleVariantChange = useCallback((event: any) => {
    setActiveVariant(event.target.value);
  }, []);

  const ABTest = useCallback(() => {
    // @ts-ignore
    const abTestFromStorage: { case_name: string; test_name: string }[] =
      getDataFromSessionStorage({
        store: 'analyticsStore',
        key: 'abTests',
      }) ?? [];

    return abTestFromStorage.length > 0 ? (
      <div>
        {abTestFromStorage[0]?.case_name} {abTestFromStorage[0]?.test_name}
      </div>
    ) : null;
  }, []);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <div
      className={classNames(styles.host, {
        [styles.expanded]: eventsNumberVisible,
      })}
    >
      <div className={styles.row}>
        <UiKitButton
          className={styles.smallButton}
          onClick={toggleEventsNumber}
        >
          Log
        </UiKitButton>
        {hasMounted && (
          <select
            className={styles.select}
            name="variant-select"
            id="select"
            onChange={handleVariantChange}
            defaultValue={
              ALL_VARIANTS[
                ALL_VARIANTS.findIndex(
                  (item) => item.variant_name === variantFromStorage,
                )
              ]?.variant_name
            }
          >
            {ALL_VARIANTS.map((variant) => (
              <option key={variant.variant_name} value={variant.variant_name}>
                {variant.variant_name}
              </option>
            ))}
          </select>
        )}
        <UiKitButton
          className={styles.smallButton}
          onClick={() =>
            window.location.replace(
              `/quiz?hard_reset=1&variant=${activeVariant}`,
            )
          }
        >
          Reset
        </UiKitButton>
        {eventsNumberVisible && (
          <>
            <UiKitButton className={styles.smallButton} onClick={clearHandler}>
              Clear
            </UiKitButton>
            {Object.keys(grouped).map((key) => (
              <UiKitButton
                key={key}
                className={styles.smallButton}
                onClick={() => handleButtonClick(key as TpSource)}
              >
                {key}
              </UiKitButton>
            ))}
          </>
        )}
      </div>
      {eventsNumberVisible && (
        <>
          {hasMounted && <ABTest />}
          <div className={styles.list}>
            {filteredByGroup != null &&
              filteredByGroup.map((item: { date: string }) => (
                <div key={item.date} className={styles.item}>
                  <span>{JSON.stringify(item, null, 2)}</span>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
