export const createTestSegmentFromQueryParameters = () => {
  const url = typeof window !== undefined ? window.location.href : '';
  const urlParameters = new URLSearchParams(new URL(url).search);

  const segmentName = urlParameters.get('segment_name');
  const testName = urlParameters.get('test_name');
  const caseName = urlParameters.get('case_name');

  if (segmentName && testName && caseName) {
    return {
      segment_name: decodeURI(segmentName),
      tests: [
        {
          test_name: decodeURI(testName),
          case_name: decodeURI(caseName),
        },
      ],
    };
  }

  return null;
};
