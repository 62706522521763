import { observer } from 'mobx-react-lite';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { getBMI } from 'src/utils/utils-health';

import { NowGoalBody } from '@features/Paywall/components/now-goal-body';
import { HeightUnits, WeightUnits } from '@features/Quiz/constants';
import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import styles from './start-target.module.scss';

const LOW_BMI_THRESHOLD = 18.4;
const NORMAL_BMI_THRESHOLD = 22.9;

const BODY_FAT_MESSAGES = {
  LOW: {
    defaultMessage: 'Low',
    id: 'Onboarding.Purchase.OptionLow',
    idBefore: 'Onboarding.Purchase.OptionNormal',
    idAfter: 'Onboarding.Purchase.OptionLow',
    imageBefore: 'fitness-level-before-normal.webp',
    imageAfter: 'fitness-level-after-low.webp',
  },
  NORMAL: {
    defaultMessage: 'Normal',
    id: 'Onboarding.Purchase.OptionNormal',
    idBefore: 'Onboarding.Purchase.OptionNormal',
    idAfter: 'Onboarding.Purchase.OptionNormal',
    imageBefore: 'fitness-level-before-normal.webp',
    imageAfter: 'fitness-level-after-normal.webp',
  },
  HIGH: {
    defaultMessage: 'High',
    id: 'Onboarding.Purchase.OptionHigh',
    idBefore: 'Onboarding.Purchase.OptionHigh',
    idAfter: 'Onboarding.Purchase.OptionNormal',
    imageBefore: 'fitness-level-before-high.webp',
    imageAfter: 'fitness-level-after-normal.webp',
  },
};

const getBodyFatMessageId = (BMI: number) => {
  if (BMI < LOW_BMI_THRESHOLD) {
    return BODY_FAT_MESSAGES.LOW;
  } else if (BMI <= NORMAL_BMI_THRESHOLD) {
    return BODY_FAT_MESSAGES.NORMAL;
  } else {
    return BODY_FAT_MESSAGES.HIGH;
  }
};

const calculateBodyFat = (BMI: number) => {
  const { defaultMessage, id, imageAfter, imageBefore, idAfter, idBefore } =
    getBodyFatMessageId(BMI);
  return {
    idAfter,
    idBefore,
    imageAfter,
    imageBefore,
    message: (
      <FormattedMessage
        defaultMessage={defaultMessage}
        id={id as TpMessageKeys}
      />
    ),
  };
};

export const StartTargetPersonal = observer(() => {
  const {
    quizStore: {
      currentBMI,
      goalWeightUnits,
      goalWeightValue,
      heightValue,
      heightUnits,
    },
  } = useStores();

  const intl = useIntl();

  const targetBMI = getBMI({
    weightUnits: goalWeightUnits ?? WeightUnits.KG,
    weightValue: goalWeightValue ?? 70,
    heightUnits: heightUnits ?? HeightUnits.CM,
    heightValue: heightValue ?? 175,
  });

  const { imageBefore, idBefore } = calculateBodyFat(currentBMI as number);
  const { imageAfter, idAfter } = calculateBodyFat(targetBMI as number);

  return (
    <div className={styles.container}>
      <NowGoalBody />
      <div className={styles.secondContainer}>
        <div className={styles.side}>
          <div className={styles.row}>
            <h2 className={styles.titleBlock}>
              <FormattedMessage
                defaultMessage="Body fat"
                id="Onboarding.FirstScreen.Fat1"
              />
            </h2>
            <h6 className={styles.description}>
              {intl.formatMessage({
                defaultMessage: 'Normal',
                id: idBefore as TpMessageKeys,
              })}
            </h6>
          </div>

          <div>
            <Image
              width={164}
              height={8}
              src={imageBefore}
              alt="fitness-level-before"
            />
          </div>
        </div>
        <div className={styles.side}>
          <div className={styles.row}>
            <h2 className={styles.titleBlock}>
              <FormattedMessage
                defaultMessage="Body fat"
                id="Onboarding.FirstScreen.Fat1"
              />
            </h2>
            <h6 className={styles.description}>
              {intl.formatMessage({
                defaultMessage: 'Normal',
                id: idAfter as TpMessageKeys,
              })}
            </h6>
          </div>

          <div>
            <Image
              width={164}
              height={8}
              src={imageAfter}
              alt="fitness-level-after"
            />
          </div>
        </div>
      </div>
    </div>
  );
});
