type EventName =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'ClickButton'
  | 'CompletePayment'
  | 'CompleteRegistration'
  | 'Contact'
  | 'Download'
  | 'InitiateCheckout'
  | 'PlaceAnOrder'
  | 'Search'
  | 'SubmitForm'
  | 'Subscribe'
  | 'ViewContent';

export const tikTokEvent = (
  name: EventName,
  options?: { [key: string]: any },
) => {
  // @ts-ignore

  typeof window !== 'undefined' && window.ttq?.track(name, options);
};

export const tikTokAdvancedMatching = (email: string) => {
  typeof window !== 'undefined' &&
    // @ts-ignore
    window?.ttq?.identify({
      email: email,
    });
};
