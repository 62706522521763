import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { UiKitButton } from 'src/components/common/ui-kit-button';
import { UiKitInput } from 'src/components/common/ui-kit-input';

import { FormattedMessage } from '@features/intl';

import styles from './input-step.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

type TpInputStepProps = {
  value?: Date | undefined | null;
  onChange: (value: Date | null) => void;
  onNextPress?: (eventValue?: any) => void;
  extraContent?: ReactNode;
};

export const UpcomingEventStep = ({
  value,
  onChange,
  onNextPress,
  extraContent,
}: TpInputStepProps) => {
  const formSchema = useMemo(
    () =>
      yup.object().shape({
        dateEvent: yup
          .date()
          .transform((value) => dayjs(value).toDate())
          .min(dayjs().startOf('day'), 'Please, enter a valid date')
          .required(),
      }),
    [],
  );

  const today = dayjs().format('DD.MM.YYYY');

  const { values, resetForm, handleChange, handleSubmit, handleBlur, isValid } =
    useFormik<{
      dateEvent: string;
    }>({
      initialValues: {
        dateEvent:
          value === undefined || value === null
            ? ''
            : dayjs().format(DATE_FORMAT),
      },
      validationSchema: formSchema,
      onSubmit: (values) => {
        onChange(dayjs(values.dateEvent).toDate());
      },
    });

  const handleClick = useCallback(() => {
    handleSubmit();
    if (onNextPress) {
      onNextPress();
    }
  }, [handleSubmit, onNextPress]);

  const skipHandleClick = useCallback(() => {
    onChange(null);
    if (onNextPress) {
      onNextPress();
    }
  }, [onChange, onNextPress]);

  const [fieldClicked, setFieldClicked] = useState(false);

  const handleFieldTouch = () => {
    if (!fieldClicked) {
      setFieldClicked(true);
    }
  };

  const handleFieldClick = () => {
    if (!fieldClicked) {
      setFieldClicked(true);
    }
  };

  useEffect(() => {
    resetForm(
      value == null
        ? undefined
        : { values: { dateEvent: dayjs(value).format(DATE_FORMAT) } },
    );
  }, [value, resetForm]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div>
        <UiKitInput
          fullWidth
          className={styles.control}
          type={values.dateEvent ? 'date' : fieldClicked ? 'date' : 'text'}
          name="dateEvent"
          value={values.dateEvent}
          onChange={handleChange}
          placeholder={today}
          onBlur={handleBlur}
          onFocus={handleFieldClick}
          isInvalid={!isValid}
          onTouchStart={handleFieldTouch}
          errorText={!isValid ? 'Please, enter a valid date' : ''}
          errorTextClassName={styles.errorText}
        />
      </div>
      {extraContent}
      <div className={styles.buttonGroup}>
        <UiKitButton
          color="secondary"
          className={styles.skipButton}
          fullWidth
          onClick={skipHandleClick}
        >
          <FormattedMessage
            defaultMessage="Skip this question"
            id="Onboarding.WhenEven.Button.Skip"
          />
        </UiKitButton>
        <UiKitButton
          className={styles.nextButton}
          disabled={!isValid || !values.dateEvent}
          onClick={handleClick}
          fullWidth
          data-testid="next-button"
        >
          <FormattedMessage
            id="Onboarding.WhenEven.Button"
            defaultMessage="See my results"
          />
        </UiKitButton>
      </div>
    </form>
  );
};
