import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';

import { TpSummaryStepProps } from '../../types';

export const MadeForYou = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <BreaksScreen
    step={step}
    goToNextStep={goToNextStep}
    gaEventQuery="just_for_you"
    backgroundColor="LIGHT_PURPLE"
  />
);
