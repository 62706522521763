import { useFormik } from 'formik';
import React from 'react';

import { UiKitButton } from 'src/components/common/ui-kit-button';
import { UiKitInput } from 'src/components/common/ui-kit-input';

import { FormattedMessage, TpMessageKeys } from '@features/intl';

import styles from './InputStep.module.scss';

interface InputStepProps {
  inputType: 'text' | 'number' | 'email' | 'password' | 'date';
  onChange: (value: any) => void;
  value: string | number | undefined;
  submitButtonText?: string;
  submitButtonId?: TpMessageKeys;
  placeholder: string;
  validationSchema: any;
  extraContent?: React.ReactNode;
}

const InputStep: React.FC<InputStepProps> = ({
  inputType,
  onChange,
  value,
  submitButtonText,
  submitButtonId,
  placeholder,
  validationSchema,
  extraContent,
}) => {
  const {
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isValid,
    //@ts-ignore - variable is used
    validateOnMount,
  } = useFormik({
    initialValues: {
      inputField: value,
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      onChange(values.inputField);
    },
  });

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div>
        <UiKitInput
          fullWidth
          type={inputType}
          name="inputField"
          placeholder={placeholder}
          value={values.inputField}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.inputField && errors.inputField !== undefined}
          errorText={errors.inputField}
        />
      </div>

      {extraContent}

      <UiKitButton
        className={styles.submitButton}
        fullWidth
        type="submit"
        disabled={!isValid || !values.inputField}
      >
        {submitButtonId ? (
          <FormattedMessage id={submitButtonId} defaultMessage="Next step" />
        ) : (
          submitButtonText
        )}
      </UiKitButton>
    </form>
  );
};

export default InputStep;
