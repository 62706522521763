import classNames from 'classnames';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as yup from 'yup';

import { UiKitButton } from 'src/components/common/ui-kit-button';
import {
  MAX_HEIGHT_CM,
  MAX_HEIGHT_FEET,
  MAX_HEIGHT_INCHES,
  MIN_HEIGHT_CM,
  MIN_HEIGHT_FEET,
  MIN_HEIGHT_INCHES,
} from 'src/constants/common';
import { cmToFeet, feetToCm } from 'src/utils/height-units';

import { HeightUnits } from '@features/Quiz/constants';
import { FormattedMessage, useIntl } from '@features/intl';

import DecimalNumberFormControl from '../../../common/form/decimal-number-form-control/decimal-number-form-control';
import styles from './height-step.module.scss';

export const HEIGHT_FEET_FORM_SCHEMA = yup.object().shape({
  feet: yup
    .number()
    .min(MIN_HEIGHT_FEET)
    .max(MAX_HEIGHT_FEET)
    .required()
    .transform((value) => (value === '' ? undefined : value)),
  inches: yup
    .number()
    .min(MIN_HEIGHT_INCHES)
    .max(MAX_HEIGHT_INCHES)
    .required()
    .transform((value) => (value === '' ? undefined : value)),
});

export const HEIGHT_CM_FORM_SCHEMA = yup.object().shape({
  cm: yup
    .number()
    .min(MIN_HEIGHT_CM)
    .max(MAX_HEIGHT_CM)
    .required()
    .transform((value) => (value === '' ? undefined : value)),
});

const feetToFeetAndInches = (feet: number) => [
  Math.floor(feet),
  (feet % 1) * 12,
];

const feetAndInchesToFeet = (feet: number, inches: number) =>
  feet + inches / 12;

const Tooltip = () => (
  <div className={styles.tooltip}>
    <b className={styles.title}>
      <FormattedMessage
        defaultMessage="We will calculate your BMI"
        id="Onboarding.Height.Tip.Title"
      />
    </b>
    <p className={styles.subTitle}>
      <FormattedMessage
        defaultMessage="Body mass index (BMI) is a measure of body fat based on height and weight"
        id="Onboarding.Height.Tip.Subtitle"
      />
    </p>
  </div>
);

const FeetTabContent = ({
  value,
  onChange,
  onNextPress,
}: {
  value?: number;
  onChange: (value: number) => void;
  onNextPress: ((value: number, units: HeightUnits) => void) | undefined;
}) => {
  const intl = useIntl();
  const {
    values,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik<{
    feet?: number;
    inches?: number;
  }>({
    initialValues: {
      feet: undefined,
      inches: undefined,
    },
    validationSchema: HEIGHT_FEET_FORM_SCHEMA,
    onSubmit: (values) => {
      onChange(feetAndInchesToFeet(values.feet!, values.inches!));
    },
  });

  const handleClick = useCallback(() => {
    if (onNextPress) {
      onNextPress(
        feetAndInchesToFeet(values.feet!, values.inches!),
        HeightUnits.FEET,
      );
    }
  }, [values, onNextPress]);

  const height1Message = useMemo(
    () =>
      intl.formatMessage({
        asString: true,
        id: 'Onboarding.Height.Сlue1',
        defaultMessage: 'Height (ft)',
      }),
    [intl],
  );

  const height2Message = useMemo(
    () =>
      intl.formatMessage({
        asString: true,
        id: 'Onboarding.Height.Сlue2',
        defaultMessage: 'Height (inch)',
      }),
    [intl],
  );

  const showTooltip = values.feet !== undefined && values.feet > 0;

  useEffect(() => {
    let newValues: typeof values | undefined;
    if (value) {
      const [feet, inches] = feetToFeetAndInches(value);
      newValues = {
        feet: feet,
        inches: Math.floor(inches),
      };
    }
    resetForm({ values: newValues });
  }, [value, resetForm]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.feetInputGroup}>
        <div className={styles.feetControlGroup}>
          <DecimalNumberFormControl
            placeholder={height1Message as string}
            min={MIN_HEIGHT_FEET}
            max={MAX_HEIGHT_FEET}
            name="feet"
            value={values.feet ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.feet && errors.feet != null}
          />
        </div>
        <div className={styles.feetControlGroup}>
          <DecimalNumberFormControl
            placeholder={height2Message as string}
            min={MIN_HEIGHT_INCHES}
            max={MAX_HEIGHT_INCHES}
            name="inches"
            value={values.inches ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.inches && errors.inches != null}
          />
        </div>
      </div>
      <p
        className={classNames(styles.subText, {
          [styles.errorText]: errors.feet || errors.inches,
        })}
      >
        <FormattedMessage
          defaultMessage="Please, enter a value from {mark1} {mark2} to {mark3} {mark4}"
          id="Onboarding.TargetWeight.Tooltip.Kg"
          values={{
            mark1: <b>{MIN_HEIGHT_FEET}</b>,
            mark2: (
              <b>
                <FormattedMessage
                  defaultMessage="ft"
                  id="Onboarding.Height.Ft"
                />{' '}
                8in
              </b>
            ),
            mark3: <b>{MAX_HEIGHT_FEET}</b>,
            mark4: (
              <b>
                <FormattedMessage
                  defaultMessage="ft"
                  id="Onboarding.Height.Ft"
                />{' '}
                2in
              </b>
            ),
          }}
        />
      </p>
      {showTooltip && <Tooltip />}
      <UiKitButton
        className={styles.submitButton}
        type="submit"
        disabled={!isValid || !values.feet}
        onClick={handleClick}
      >
        <FormattedMessage
          defaultMessage="Next step"
          id="Onboarding.Height.Button"
        />
      </UiKitButton>
    </form>
  );
};

const CmTabContent = ({
  value,
  onChange,
  onNextPress,
}: {
  value?: number;
  onChange: (value: number) => void;
  onNextPress: ((value: number, units: HeightUnits) => void) | undefined;
}) => {
  const intl = useIntl();

  const {
    values,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
  } = useFormik<{ cm?: number }>({
    initialValues: { cm: undefined },
    validationSchema: HEIGHT_CM_FORM_SCHEMA,
    onSubmit: (values) => {
      onChange(values.cm!);
    },
  });

  const handleClick = useCallback(() => {
    onNextPress?.(values.cm!, HeightUnits.CM);
  }, [values.cm, onNextPress]);

  const showTooltip = values.cm !== undefined && values.cm > 0;

  const height1Message = useMemo(
    () =>
      intl.formatMessage({
        asString: true,
        id: 'Onboarding.Height.Сlue',
        defaultMessage: 'Height (cm)',
      }),
    [intl],
  );

  useEffect(() => {
    resetForm(value == null ? undefined : { values: { cm: Math.ceil(value) } });
  }, [value, resetForm]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.cmControlGroup}>
        <DecimalNumberFormControl
          placeholder={height1Message as string}
          min={MIN_HEIGHT_CM}
          max={MAX_HEIGHT_CM}
          name="cm"
          value={values.cm ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.cm && errors.cm != null}
        />
      </div>
      <p
        className={classNames(styles.subText, {
          [styles.errorText]: errors.cm,
        })}
      >
        <FormattedMessage
          defaultMessage="Please, enter a value from {mark1} {mark2} to {mark3} {mark4}"
          id="Onboarding.TargetWeight.Tooltip.Kg"
          values={{
            mark1: <b>{MIN_HEIGHT_CM}</b>,
            mark2: (
              <b>
                <FormattedMessage
                  defaultMessage="cm"
                  id="Onboarding.Height.Cm"
                />
              </b>
            ),
            mark3: <b>{MAX_HEIGHT_CM}</b>,
            mark4: (
              <b>
                <FormattedMessage
                  defaultMessage="cm"
                  id="Onboarding.Height.Cm"
                />
              </b>
            ),
          }}
        />
      </p>
      {showTooltip && <Tooltip />}
      <UiKitButton
        className={styles.submitButton}
        type="submit"
        disabled={!isValid || !values.cm}
        onClick={handleClick}
        data-testid="next-button"
      >
        <FormattedMessage
          defaultMessage="Next step"
          id="Onboarding.Height.Button"
        />
      </UiKitButton>
    </form>
  );
};

export interface Props {
  value?: number;
  units?: HeightUnits;
  onChange: (value: number, unit: HeightUnits) => void;
  onNextPress?: ((value: number, units: HeightUnits) => void) | undefined;
}

const HeightStep = ({ value, units, onChange, onNextPress }: Props) => (
  <div className={styles.container}>
    <Tabs
      className={styles.tabs}
      id="height-step"
      defaultActiveKey={units ?? HeightUnits.FEET}
    >
      <Tab eventKey={HeightUnits.FEET} title="ft">
        <FeetTabContent
          value={
            value == null || units === HeightUnits.FEET
              ? value
              : cmToFeet(value)
          }
          onChange={(value) => onChange(value, HeightUnits.FEET)}
          onNextPress={onNextPress}
        />
      </Tab>
      <Tab eventKey={HeightUnits.CM} title="cm">
        <CmTabContent
          value={
            value == null || units === HeightUnits.CM ? value : feetToCm(value)
          }
          onChange={(value) => onChange(value, HeightUnits.CM)}
          onNextPress={onNextPress}
        />
      </Tab>
    </Tabs>
  </div>
);

export default HeightStep;
