import { useMemo } from 'react';
import { useIntl as useReactIntl } from 'react-intl';
import type { IntlFormatters } from 'react-intl';

import type { TpMessageKeys } from './types';

export type TpFormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

export function useIntl() {
  const { formatMessage, ...rest } = useReactIntl();

  const typedFormatMessage = useMemo(
    () =>
      (
        descriptor: TpFormatMessageArgs[0] & {
          id?: TpMessageKeys;
          asString?: boolean;
        },
        values?: TpFormatMessageArgs[1],
        options?: TpFormatMessageArgs[2],
      ) => {
        // @ts-expect-error values is required
        const message = formatMessage(descriptor, values, options);
        return descriptor.asString ? (
          message
        ) : (
          <span data-trans={descriptor.id}>{message}</span>
        );
      },
    [formatMessage],
  );

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
}
