import { ReactNode } from 'react';
import Slider from 'react-slick';

import classNames from 'classnames';
import styles from './SlickSlider.module.scss';

const sliderSettings = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

type TpSlickSliderProps = {
  children: ReactNode;
  settings?: object | undefined;
  sliderContainer?: string | undefined;
};
export const SlickSlider = ({
  children,
  settings = {},
  sliderContainer,
}: TpSlickSliderProps) => (
  <Slider
    className={classNames(styles.sliderContainer, sliderContainer)}
    {...sliderSettings}
    {...settings}
  >
    {children}
  </Slider>
);
