import classNames from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';

import CircleButton from '../../circle-button/circle-button';
import styles from './header-button.module.scss';

export interface Props extends ComponentProps<typeof CircleButton> {}

const HeaderButton = forwardRef<typeof Button, Props>(
  ({ className, ...props }: Props, ref) => (
    <CircleButton
      ref={ref}
      className={classNames(styles.container, className)}
      {...props}
    />
  ),
);

HeaderButton.displayName = 'HeaderButton';
export default HeaderButton;
