import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';

import { TpSummaryStepProps } from '../../types';

export const BestNoDiet = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <BreaksScreen
    step={step}
    goToNextStep={goToNextStep}
    gaEventQuery="best_no_diet"
  />
);
