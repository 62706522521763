import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';

import { TpSummaryStepProps } from '../../types';

export const CommitTo = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <BreaksScreen
    step={step}
    goToNextStep={goToNextStep}
    gaEventQuery="commit_yourself"
  />
);
