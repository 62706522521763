import classNames from 'classnames';
import { ComponentType, ReactNode, SVGAttributes, forwardRef } from 'react';

import LoadingIndicator from '../loading-indicator/loading-indicator';
import styles from './button.module.scss';

export type TpButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'link';

interface Props {
  handleClick?: () => void;
  IconComponent?: ComponentType<SVGAttributes<SVGElement>>;
  iconClassName?: string;
  showLoadingIndicator?: boolean;
  variant?: TpButtonVariant;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  size?: 'lg' | 'sm';
  'data-testid'?: string;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      handleClick,
      variant = 'primary',
      IconComponent,
      children,
      className,
      iconClassName,
      showLoadingIndicator = false,
      disabled,
      size,
      ...props
    }: Props,
    ref,
  ) => {
    const variantClassName = classNames(styles.container, {
      [styles.containerPrimary]: variant === 'primary',
      [styles.containerSecondary]: variant === 'secondary',
      [styles.containerTertiary]: variant === 'tertiary',
      [styles.containerQuaternary]: variant === 'quaternary',
      [styles.containerLink]: variant === 'link',
    });

    const sizeClassName = classNames({
      [styles.lg]: size === 'lg',
      [styles.sm]: size === 'sm',
    });

    return (
      <button
        ref={ref}
        onClick={handleClick}
        className={classNames(variantClassName, sizeClassName, className)}
        disabled={disabled || showLoadingIndicator}
        {...props}
      >
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) : (
          <>
            {IconComponent ? (
              <IconComponent
                className={classNames(styles.icon, iconClassName)}
              />
            ) : null}
            {children}
          </>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
export default Button;
