const CLOUDFRONT = '/';

type TpImagePath = 'images' | 'icons';

const getImageUrl = (path: TpImagePath = 'images') => `${CLOUDFRONT}${path}/`;

export const IMAGE_SOURCE = getImageUrl();
export const ICON_SOURCE = getImageUrl('icons');

const preloadImages = (images?: string[]) => {
  if (!images) {
    return;
  }
  for (const source of images) {
    const img = new Image();
    img.src = `${IMAGE_SOURCE}${source}`;
  }
};

export default preloadImages;
