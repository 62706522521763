import {
  CheckboxOption,
  TpCheckboxOptionProps,
} from '@mentalgrowth/ui-kit-web';

const CheckBoxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.33337 7.33333L6.00004 12L14 4"
      stroke="#F6F4F0"
      strokeWidth="2.66667"
    />
  </svg>
);

export const UiKitCheckboxOption = (props: TpCheckboxOptionProps) => (
  <CheckboxOption
    checkboxIcon={(<CheckBoxIcon />) as unknown as string}
    {...props}
  />
);
