import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import VectorLeft from '../../../../../../public/images/vector-left.svg';
import VectorRight from '../../../../../../public/images/vector-right.svg';
import styles from './WomenCompleted.module.scss';

export const WomenCompleted = observer(() => {
  const {
    quizStore: { struggleWith },
  } = useStores();

  const STEPS_DATA = [
    {
      value: 'sensitive_back',
      text: 'Back pain is gone, and now I can enjoy life',
    },
    {
      value: 'sensitive_knees',
      text: 'Knees and joints no longer cause discomfort, allowing me to walk more',
    },
    {
      value: 'neck_pain',
      text: 'Neck pain has disappeared, so it no longer interferes with my work',
    },
    { value: '', text: 'I started feeling healthier and gained more energy' },
  ];

  const stepThreeText = STEPS_DATA.find((item) =>
    struggleWith![0] === undefined
      ? item.value === ''
      : item.value === struggleWith![0],
  );

  return (
    <>
      <h1 className={styles.subtitle}>
        See what other women are saying about Youth Pilates
      </h1>
      <p className={styles.completed}>
        <b>15,216 women</b> have already completed our Program in{' '}
        <b>{dayjs().format('MMMM')}</b>
      </p>

      <div className={styles.stepsContainer}>
        <div className={styles.stepOne}>
          <span className={styles.name}>Daniel:</span>
          <span className={styles.text}>
            “I like doing Pilates because it helping me shed inches and pounds
            ❤”
          </span>
        </div>
        <VectorLeft />
        <div className={styles.stepTwo}>
          <span className={styles.name}>Emma:</span>
          <span className={styles.text}>
            “...looking at the mirror and see that it makes a huge impact on my
            body shape!”
          </span>
        </div>
        <VectorRight />
        <div className={styles.stepThree}>
          <div className={styles.thirdContainer}>
            <span className={styles.name}>Jessica:</span>
            <span className={styles.text}>“{stepThreeText?.text}”</span>
          </div>
          <Image src="jesica-prepaywall.webp" alt="" width={96} height={96} />
          <Image
            src="emoji-tada-prepaywall.webp"
            alt=""
            width={48}
            height={48}
            className={styles.image}
          />
        </div>
      </div>
    </>
  );
});
