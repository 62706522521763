import classNames from 'classnames';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import DecimalNumberFormControl from 'src/components/common/form/decimal-number-form-control/decimal-number-form-control';
import { UiKitButton } from 'src/components/common/ui-kit-button';

import { TooltipTargetWeight } from '@features/Quiz/PilatesOnboarding/steps/TargetWeight/Tooltip';
import styles from '@features/Quiz/PilatesOnboarding/steps/Weight/WeightForm/weight.module.scss';
import { WeightUnits } from '@features/Quiz/constants';
import { FormattedMessage } from '@features/intl';

export const TargetTabContent = observer(
  <T extends string>({
    onChange,
    controlPlaceholder,
    targetWeight,
    onNextPress,
    unit,
    controlMin,
    controlMax,
  }: {
    onChange: (value: number) => void;
    controlPlaceholder: string;
    targetWeight?: number;
    onNextPress?: ((value: number, units: WeightUnits) => void) | undefined;
    unit: string;
    controlMin: number;
    controlMax: number;
  }) => {
    interface Values {
      targetWeight?: number;
    }

    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      errors,
      isValid,
      resetForm,
    } = useFormik<Values>({
      initialValues: {
        targetWeight: targetWeight || undefined,
      },
      validationSchema: yup.object().shape({
        targetWeight: yup
          .number()
          .min(Math.ceil(controlMin))
          .max(Math.floor(controlMax))
          .transform((value) => (value === '' ? undefined : value)),
      }),
      onSubmit: (values) => {
        onChange(values.targetWeight!);
      },
    });

    const [targetBMI, setTargetBMI] = useState<number | undefined>();

    const handleClick = useCallback(() => {
      onNextPress?.(values.targetWeight!, unit as WeightUnits);
    }, [onNextPress, unit, values.targetWeight]);

    const showTooltip =
      values.targetWeight !== undefined && values.targetWeight > 0;

    useEffect(() => {
      resetForm(
        targetWeight == null
          ? undefined
          : {
              values: {
                targetWeight: Math.round(targetWeight),
              },
            },
      );
    }, [resetForm, targetWeight]);

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.controlGroup}>
          <DecimalNumberFormControl
            id="targetWeight"
            placeholder={controlPlaceholder}
            name="targetWeight"
            value={values.targetWeight}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.targetWeight && errors.targetWeight != null}
          />
          <p
            className={classNames(styles.subText, {
              [styles.errorText]: errors.targetWeight,
            })}
          >
            <FormattedMessage
              defaultMessage="Please, enter a value from {mark1} {mark2} to {mark3} {mark4}"
              id="Onboarding.Weight.Text"
              values={{
                mark1: <b>{controlMin}</b>,
                mark2: <b>{unit}</b>,
                mark3: <b>{controlMax}</b>,
                mark4: <b>{unit}</b>,
              }}
            />
          </p>
        </div>
        {showTooltip && (
          <TooltipTargetWeight
            targetWeight={values.targetWeight}
            unit={unit}
            setTargetBMI={setTargetBMI}
          />
        )}
        <UiKitButton
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || !targetBMI || targetBMI < 18.5}
          onClick={handleClick}
          data-testid={`next-button-${unit}`}
        >
          <FormattedMessage
            defaultMessage="Next"
            id="Onboarding.Weight.Button"
          />
        </UiKitButton>
      </form>
    );
  },
);
