import classNames from 'classnames';
import { ComponentProps, HTMLProps } from 'react';

import { UiKitDropdown } from 'src/components/common/ui-kit-dropdown';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';

import QuestionTooltipOutline from '../../../assets/icons/question-tooltip-outline.svg';
import VeggieBurger from '../../../assets/icons/veggie-burger.svg';
import HeaderButton from '../header/header-button/header-button';
import styles from './info-hamburger-menu.module.scss';

const ButtonItem = ({ className, ...props }: HTMLProps<HTMLButtonElement>) => (
  <button
    className={classNames(styles.menuItem, className)}
    {...props}
    type="button"
  />
);

const IconButtonItem = ({
  IconComponent,
  children,
  ...props
}: { IconComponent: any } & ComponentProps<typeof ButtonItem>) => (
  <ButtonItem className={styles.menuIconItem} {...props}>
    <IconComponent className={styles.menuIconItemIcon} />
    {children}
  </ButtonItem>
);

const InfoHamburgerMenu = ({
  showSupportButton = false,
  handleShowFeedback,
  toggleClassName,
}: {
  showSupportButton?: boolean;
  toggleClassName?: string;
  handleShowFeedback: () => void;
}) => (
  <UiKitDropdown
    toggleComponent={
      <HeaderButton className={toggleClassName}>
        <VeggieBurger />
      </HeaderButton>
    }
    placement="bottom-end"
  >
    <TermsLink className={styles.menuItem} place="burger_menu" />
    <PolicyLink className={styles.menuItem} place="burger_menu" />
    <RefundLink className={styles.menuItem} place="burger_menu" />
    <IconButtonItem
      IconComponent={QuestionTooltipOutline}
      onClick={() => handleShowFeedback()}
    >
      Contact us
    </IconButtonItem>
  </UiKitDropdown>
);

export default InfoHamburgerMenu;
