import { useRef } from 'react';

import { AccentProgress } from 'src/components/common/accent-progress/accent-progress';
import { useProgressSteps } from 'src/utils';
import { useIsInViewport } from 'src/utils/hooks/use-is-in-viewport';

import styles from './progress-item.module.scss';

export const ProgressItem = ({
  label,
  maxProgress,
  progressDurations,
}: {
  label: string;
  maxProgress: number;
  progressDurations: number;
}) => {
  const progressBlockRef = useRef(null);
  const isProgressInViewport = useIsInViewport(progressBlockRef);
  const progress = useProgressSteps({
    started: isProgressInViewport,
    steps: [maxProgress],
    progressDurations: [progressDurations],
  });
  return (
    <div className={styles.container} ref={progressBlockRef}>
      <p className={styles.label}>{label}</p>
      <div className={styles.progressWrapper}>
        <AccentProgress
          value={(progress.progress / 100).toFixed(2)}
          className={styles.progress}
        />
        <p className={styles.progressPercent}>
          {progress.progress.toFixed(0)}%
        </p>
      </div>
    </div>
  );
};
