import { memo, useMemo } from 'react';

import styles from './circle-animated-progress-pause.module.scss';

const PROGRESS_BAR_RADIUS = 93.5;
const PROGRESS_BAR_LENGTH = 2 * Math.PI * PROGRESS_BAR_RADIUS;

type Props = {
  progressPercent: number;
};

const GradientProgress = memo(() => (
  <linearGradient
    id="gradient-progress"
    x1="-51.8932"
    y1="0.499971"
    x2="124.495"
    y2="12.5818"
    gradientUnits="userSpaceOnUse"
  >
    <stop offset="0.5" stopColor="#FF522C" />
    <stop offset="1" stopColor="#FF3C8B" />
  </linearGradient>
));

GradientProgress.displayName = 'GradientProgress';

const PaintLinear = memo(() => (
  <linearGradient
    id="paint0_linear_5520_31945"
    x1="36.5"
    y1="-4.57764e-05"
    x2="36.5"
    y2="180"
    gradientUnits="userSpaceOnUse"
  >
    <stop stopColor="#875FF4" />
    <stop offset="1" stopColor="#FE6170" />
  </linearGradient>
));

PaintLinear.displayName = 'PaintLinear';

export const CircleAnimatedProgressPause = ({ progressPercent }: Props) => {
  const progressLength = useMemo(
    () => progressPercent * PROGRESS_BAR_LENGTH,
    [progressPercent],
  );

  return (
    <div
      className={styles.progressContainer}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={progressPercent}
    >
      <svg
        className={styles.progress}
        viewBox="0 0 199 199"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={99.5}
          cy={99.5}
          className={styles.progressBar}
          r={PROGRESS_BAR_RADIUS}
        />
        <circle
          cx={99.5}
          cy={99.5}
          className={styles.progressValue}
          r={PROGRESS_BAR_RADIUS}
          strokeDasharray={`${progressLength} ${PROGRESS_BAR_LENGTH}`}
        />
        <defs>
          <GradientProgress />
          <PaintLinear />
        </defs>
      </svg>
      <h1 className={styles.progressPercentage}>{`${Math.round(
        progressPercent * 100,
      )}%`}</h1>
    </div>
  );
};
