import { WeightUnits } from '@features/Quiz/constants';

const LBS_MULTIPLIER = 2.204_622_621_85;

export const lbsToKg = (value: number) => value / LBS_MULTIPLIER;

export const kgToLbs = (value: number) => value * LBS_MULTIPLIER;

export const lbsToKgRound = (value: number, decimalPlaces: number) =>
  +lbsToKg(value).toFixed(decimalPlaces);

export const kgToLbsRound = (value: number, decimalPlaces: number) =>
  +kgToLbs(value).toFixed(decimalPlaces);

export const uniformGoalWeight = ({
  weightUnits,
  goalWeightUnits,
  goalWeightValue,
}: {
  weightUnits: WeightUnits;
  goalWeightUnits: WeightUnits;
  goalWeightValue: number;
}) => {
  if (weightUnits === goalWeightUnits) {
    return goalWeightValue;
  }

  const selectUnit = {
    [WeightUnits.LBS]: lbsToKg(goalWeightValue),
    [WeightUnits.KG]: kgToLbs(goalWeightValue),
  };
  return selectUnit[goalWeightUnits];
};
