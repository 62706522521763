import * as Sentry from '@sentry/react';
import { ComponentProps, ReactNode, useEffect } from 'react';

import { Image } from 'src/components/common/Image';
import ContactUsLink from 'src/components/common/contact-us-link/contact-us-link';
import AlertWithCloseButton from 'src/components/common/modal/alert-with-close-button/alert-with-close-button';
import Alert from 'src/components/common/modal/alert/alert';

import { useAnalytics } from '@features/Analytics';

type TpErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorAlert = ({
  AlertComponent = AlertWithCloseButton,
  show = true,
  error,
  ...props
}: { AlertComponent?: typeof Alert } & ComponentProps<typeof Alert>) => {
  const { trackGoogleEvent } = useAnalytics();

  useEffect(() => {
    if (error) {
      console.log('Error handled', error);
      Sentry.captureException(error);
      trackGoogleEvent({
        eventName: 'something_went_wrong_opened',
        options: { reason: error },
      });
    }
  }, [error, trackGoogleEvent]);

  return (
    <AlertComponent
      show={show}
      closeButtonText="Refresh page"
      image={
        <Image src="technical-problems.webp" width={160} height={120} alt="" />
      }
      title={<>Oops! Something&nbsp;went&nbsp;wrong</>}
      description={
        <>
          We’re trying to fix it.
          <br />
          Please reload the page or try again later. If the issue persists, feel
          free to <ContactUsLink />.
        </>
      }
      {...props}
    />
  );
};

export const ErrorBoundary = (props: TpErrorBoundaryProps) => {
  const { children } = props;
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorAlert
          AlertComponent={AlertWithCloseButton}
          error={error}
          onHide={() => {
            resetError();
            window.location.reload();
          }}
        />
      )}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
