import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { getBMI } from 'src/utils/utils-health';

import { FormattedMessage, TpMessageKeys } from '@features/intl';

import styles from './tooltip.module.scss';

type TooltipProps = {
  targetWeight: number | undefined;
  unit: string;
  setTargetBMI: (value: number | undefined) => void;
};

type CategoryData = {
  title: string;
  titleId: TpMessageKeys;
  subtitle: string;
  subtitleId: TpMessageKeys;
  style: string;
};

const tooltipArray = [
  {
    title: 'Great goal',
    titleId: 'Onboarding.TargetWeight.Tooltip.Weight.Title.Great',
    subtitle:
      'A realistic target is a must for successful weight loss! We’re here to support you on this journey🔥',
    subtitleId: 'Onboarding.TargetWeight.Tooltip.Weight.Subtitle.Great',
    style: 'green',
  },
  {
    title: 'Your goal may be a bit unhealthy',
    titleId: 'Onboarding.TargetWeight.Tooltip.Weight.Title.Unhealthy',
    subtitle:
      'There’s no need to lose this much weight! Please enter another target 👀',
    subtitleId: 'Onboarding.TargetWeight.Tooltip.Weight.Subtitle.Unhealthy',
    style: 'red',
  },
  {
    title: 'Let’s go!',
    titleId: 'Onboarding.TargetWeight.Tooltip.Weight.Title.Go',
    subtitle: 'Improve sleep, boost energy, and get fit starting today 💪🏼',
    subtitleId: 'Onboarding.TargetWeight.Tooltip.Weight.Subtitle.Go',
    style: 'green',
  },
];
export const TooltipTargetWeight: React.FC<TooltipProps> = observer(
  ({ targetWeight, unit, setTargetBMI }: TooltipProps) => {
    const {
      quizStore: { heightValue, heightUnits, currentBMI },
    } = useStores();

    const targetBMI = getBMI({
      weightUnits: unit,
      weightValue: targetWeight,
      heightUnits: heightUnits!,
      heightValue: heightValue!,
    });

    const getCategoryData = (targetBMI: number): CategoryData | undefined => {
      if (targetBMI < 18.5) {
        // @ts-ignore
        return tooltipArray[1];
      }
      if (currentBMI && currentBMI <= 22.9 && targetBMI > currentBMI) {
        // @ts-ignore
        return tooltipArray[2];
      }
      // @ts-ignore
      return tooltipArray[0];
    };

    const categoryData = getCategoryData(targetBMI || 0);

    useEffect(() => {
      targetBMI ? setTargetBMI(targetBMI) : null;
    }, [targetBMI, setTargetBMI]);

    return (
      <div className={classNames(styles.tooltip, styles[categoryData!.style])}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage={categoryData?.title ?? ''}
            id={categoryData?.titleId as TpMessageKeys}
          />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage
            defaultMessage={categoryData?.subtitle ?? ''}
            id={categoryData?.subtitleId as TpMessageKeys}
          />
        </div>
      </div>
    );
  },
);
