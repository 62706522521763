import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { TpPilatesQuizStepList } from '@features/Quiz';
import { UiKitRadioGroupComponent } from '@features/Quiz/components/SingleOption/components/UiKitRadioGroupComponent';

import { SingleOptionSelectQuizStepOption } from '../../PilatesOnboarding/types';
import styles from './SingleOption.module.scss';

export type TpSingleOptionProps = {
  stepName: TpPilatesQuizStepList;
  name: string;
  options: SingleOptionSelectQuizStepOption[];
  handleEventSend?: (event: any) => void;
  setterFunction: (value: string) => void;
  goToNextStep: () => void;
  eventName: string;
  doubleImageWidth?: boolean;
  labelHalfWidth?: boolean;
  labelClassName?: string;
};

export const SingleOption = observer((props: TpSingleOptionProps) => {
  const {
    stepName,
    name,
    options,
    handleEventSend,
    setterFunction,
    goToNextStep,
    eventName,
    doubleImageWidth,
    labelHalfWidth,
    labelClassName,
  } = props;
  const {
    quizStore: { getSingleOptionSelectStepValue },
  } = useStores();
  const value = getSingleOptionSelectStepValue(stepName);

  const handleRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setterFunction(event.target.value);
      if (handleEventSend) {
        handleEventSend({
          eventName,
          options: { answer: event.target.value },
        });
      }
    },
    [eventName, handleEventSend, setterFunction],
  );

  return (
    <UiKitRadioGroupComponent
      name={name}
      value={value}
      options={options}
      handleRadioChange={handleRadioChange}
      goToNextStep={goToNextStep}
      stepName={stepName}
      startIconClassName={classNames({
        [styles.image]: doubleImageWidth,
      })}
      labelClassName={classNames(
        {
          [styles.label]: doubleImageWidth,
          [styles.labelHalfWidth]: labelHalfWidth,
        },
        labelClassName,
      )}
    />
  );
});
