import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import * as yup from 'yup';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import InputStep from '@features/Quiz/components/InputStep';
import { StepContainer } from '@features/Quiz/components/StepContainer';
import { FormattedMessage, useIntl } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './age.module.scss';

const AgeDisclaimer = () => (
  <div className={styles.ageDisclaimer}>
    <Image src="whatever-woman-emoji.webp" width={40} height={40} alt="" />
    <p className={styles.disclaimerTextContainer}>
      <b>
        <FormattedMessage
          defaultMessage="We ask for your age to create a personalized weight loss plan"
          id="Onboarding.Age.Tip.Title"
        />
      </b>
      <span>
        <FormattedMessage
          defaultMessage="that considers metabolism, hormones, and age-related factors. It helps
        us determine the most effective approach to help you reach your goals."
          id="Onboarding.Age.Tip.Subititle"
        />
      </span>
    </p>
  </div>
);

const validationSchema = yup.object().shape({
  inputField: yup
    .number()
    .required('Age is required')
    .min(
      16,
      (
        <FormattedMessage
          defaultMessage="Please enter a valid age"
          id="Onboarding.Age.Сlue.Error"
          asString
        />
      ) as unknown as string,
    )
    .max(
      99,
      (
        <FormattedMessage
          defaultMessage="Please enter a valid age"
          id="Onboarding.Age.Сlue.Error"
          asString
        />
      ) as unknown as string,
    ),
});

export const Age = observer(
  ({ goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { ageNumber, setAgeNumber },
    } = useStores();
    const intl = useIntl();

    const handleNextPress = useCallback(
      (value: number) => {
        handleEventSend &&
          handleEventSend({
            eventName: 'onboarding_age_chosen',
            options: { answer: value },
          });
        setAgeNumber(value);
        goToNextStep();
      },
      [goToNextStep, handleEventSend, setAgeNumber],
    );

    return (
      <StepContainer isQuestionStep>
        <InputStep
          value={ageNumber}
          validationSchema={validationSchema}
          onChange={handleNextPress}
          inputType="number"
          submitButtonId="Onboarding.Age.Button"
          placeholder={
            intl.formatMessage({
              asString: true,
              id: 'Onboarding.Age.Сlue',
              defaultMessage: 'Years',
            }) as string
          }
          extraContent={<AgeDisclaimer />}
        />
      </StepContainer>
    );
  },
);
