export { QuizHead } from './components/QuizHead';
export { OnboardingHeader } from './components/OnboardingHeader';
export { PilatesWizardSteps } from './PilatesOnboarding/steps/WizzardSteps';

export type {
  TpPilatesQuizSection,
  TpPilatesStep,
  TpPilatesQuizStepList,
} from './PilatesOnboarding/types';

export {
  JTBD_PATH,
  PILATES_BREAKS_PATH,
  PILATES_PATH,
  QUIZ_STEPS_LIST,
  STEPS,
} from './PilatesOnboarding/config';
