import { observer } from 'mobx-react-lite';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  MAX_WEIGHT_KG,
  MAX_WEIGHT_LBS,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LBS,
} from 'src/constants/common';
import { kgToLbs, lbsToKg } from 'src/utils/weight-units';

import { WeightUnits } from '@features/Quiz/constants';
import { useIntl } from '@features/intl';

import styles from './target-weight.module.scss';
import { TargetTabContent } from './targetTabContent';

const getLbsWeight = (value?: number, units?: WeightUnits) =>
  value == null || units === WeightUnits.LBS ? value : kgToLbs(value);

const getKgWeight = (value?: number, units?: WeightUnits) =>
  value == null || units === WeightUnits.KG ? value : lbsToKg(value);

export interface Props {
  value?: number;
  units?: WeightUnits;
  onChange: (value: number, unit: WeightUnits) => void;
  onNextPress?: ((value: number, units: WeightUnits) => void) | undefined;
}

const TargetWeightStep = observer(
  ({ value, units, onChange, onNextPress }: Props) => {
    const intl = useIntl();

    return (
      <div className={styles.container}>
        <Tabs
          id="target-weight-step"
          className={styles.tabs}
          defaultActiveKey={units ?? WeightUnits.LBS}
        >
          <Tab eventKey={WeightUnits.LBS} title="lbs">
            <TargetTabContent
              onChange={(targetWeight) =>
                onChange(targetWeight, WeightUnits.LBS)
              }
              controlPlaceholder={
                intl.formatMessage({
                  asString: true,
                  id: 'Onboarding.Weight.Сlue',
                  defaultMessage: 'Weight (lbs)',
                }) as string
              }
              targetWeight={getLbsWeight(value, units)}
              onNextPress={onNextPress}
              unit={WeightUnits.LBS}
              controlMin={MIN_WEIGHT_LBS}
              controlMax={MAX_WEIGHT_LBS}
            />
          </Tab>
          <Tab eventKey={WeightUnits.KG} title="kg">
            <TargetTabContent
              onChange={(targetWeight) =>
                onChange(targetWeight, WeightUnits.KG)
              }
              controlPlaceholder={
                intl.formatMessage({
                  asString: true,
                  id: 'Onboarding.Weight.Сlue:79',
                  defaultMessage: 'Weight (kg)',
                }) as string
              }
              targetWeight={getKgWeight(value, units)}
              onNextPress={onNextPress}
              unit={WeightUnits.KG}
              controlMin={MIN_WEIGHT_KG}
              controlMax={MAX_WEIGHT_KG}
            />
          </Tab>
        </Tabs>
      </div>
    );
  },
);

export default TargetWeightStep;
