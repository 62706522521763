import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { Image } from 'src/components/common/Image';
import { CookieAgreement } from 'src/components/common/cookie-agreement';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import {
  COOKIES_AB_TEST_NAME,
  CookiesAbTestCaseName,
} from 'src/constants/analytics';
import { EURO_COUNTRIES } from 'src/constants/payment';

import { SingleOption } from '@features/Quiz/components/SingleOption';
import { TpQuizStepQuestionProps } from '../../types';

export const GOAL_OPTIONS = [
  {
    value: 'lose_weight',
    labelId: 'Onboarding.Goal.Option.Weight',
    labelDefaultMessage: 'Lose weight',
    image: <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />,
  },
  {
    value: 'build_muscle_strength',
    labelId: 'Onboarding.Goal.Option.Strenght',
    labelDefaultMessage: 'Build muscle strenght',
    image: (
      <Image
        width={40}
        height={40}
        src="emoji-man-lifting-weights.webp"
        alt=""
      />
    ),
  },
  {
    value: 'develop_flexibility',
    labelId: 'Onboarding.Goal.Option.Flexibility',
    labelDefaultMessage: 'Increase flexibility',
    image: <Image width={40} height={40} src="emoji-dancer.webp" alt="" />,
  },
  {
    value: 'reduce_stress_anxiety',
    labelDefaultMessage: 'Reduce stress & anxiety',
    labelId: 'Onboarding.Goal.Option.Stress',
    image: <Image width={40} height={40} src="emoji-yoga-woman.webp" alt="" />,
  },
  {
    value: 'improve_posture',
    labelDefaultMessage: 'Improve posture',
    labelId: 'Onboarding.Goal.Option.Posture',
    image: (
      <Image width={40} height={40} src="emoji-woman-walking.webp" alt="" />
    ),
  },
];

export const GoalPilates = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { countryCode, setGoalPilates },
      analyticsStore: { getCaseNameByTestName },
    } = useStores();

    const [isCookieAgreementShow, setIsCookieAgreementShow] = useState(false);

    const cookieAgreementFromStorage =
      typeof window !== 'undefined' &&
      sessionStorage.getItem('cookie-agreement');

    const isCookieAvailable =
      getCaseNameByTestName(COOKIES_AB_TEST_NAME) === CookiesAbTestCaseName.NEW;

    const isCookieAgreementRender =
      cookieAgreementFromStorage !== 'agreed' &&
      isCookieAvailable &&
      countryCode &&
      EURO_COUNTRIES.includes(countryCode);

    const { stepName } = step;

    const handleAgree = useCallback(() => {
      sessionStorage.setItem('cookie-agreement', 'agreed');
      setIsCookieAgreementShow(false);
      goToNextStep();
    }, [goToNextStep]);

    return (
      <>
        <SingleOption
          stepName={stepName}
          name="goal-pilates"
          // @ts-expect-error TS2339
          options={GOAL_OPTIONS}
          handleEventSend={handleEventSend}
          setterFunction={setGoalPilates}
          goToNextStep={goToNextStep}
          eventName="onboarding_goal_chosen"
        />

        {isCookieAgreementShow && isCookieAgreementRender && (
          <CookieAgreement onAgree={handleAgree} />
        )}
      </>
    );
  },
);
