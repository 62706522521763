import { ParentSize } from '@visx/responsive';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import GraphImage from 'src/components/main/quiz/graph/graph-image/graph-image';

import { FormattedMessage } from '@features/intl';

import styles from './GraphLastPlanPrepaywall.module.scss';

const LABEL_COLORS = ['#F7544A', '#FFDE85', '#5BC083'];

const startLabel = {
  backgroundProps: {
    rx: 8,
    fill: '#5BC083',
    stroke: 'var(--gray-middle, #E7E7EC)',
    strokeWidth: 1,
  },
  titleFontWeight: '400',
  titleFontSize: '17px',
  fontColor: '#FFF',
  backgroundPadding: { left: 8, right: 8, top: 8, bottom: 8 },
  width: 146,
  horizontalAnchor: 'end',
};

const labelGradient = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="239"
    height="189"
    viewBox="0 0 239 189"
    fill="none"
  >
    <defs>
      <linearGradient
        id="paint0_linear_5964_19318"
        x1="-15.6951"
        y1="-31.2094"
        x2="280.903"
        y2="-15.5455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF564B" />
        <stop offset="0.536458" stopColor="#FFE789" />
        <stop offset="1" stopColor="#A2E3F9" />
      </linearGradient>
    </defs>
  </svg>
);

const GraphLastPlanPrepaywall = observer(() => {
  const { quizStore } = useStores();
  const { weightPerDay, weightUnits } = quizStore;

  const rangeMonth = ['Today', '28 days', '3 months'];

  const lineData = [
    {
      unitIndex: 0,
      value: 80,
      labelValue: 'Start workouting',
      stylesLabel: {
        horizontalAnchor: 'start',
        width: 142,
        backgroundPadding: { left: 8, right: 8, top: 8, bottom: 8 },
      },
      dx: -20,
      dy: -15,
    },
    {
      unitIndex: 1,
      value: 70,
      labelValue: 'See the first changes',
      stylesLabel: {
        width: 177,
        backgroundPadding: { left: 8, right: 8, top: 8, bottom: 8 },
      },
      dy: -15,
    },
    {
      unitIndex: 2,
      value: 60,
      labelValue: 'Get dream body',
      stylesLabel: startLabel,
      dx: 5,
      dy: 0,
      transform: -3,
    },
  ];

  return (
    <figure className={classNames(styles.container)}>
      <ParentSize>
        {({ width }) => (
          <>
            <GraphImage
              // @ts-ignore
              lineData={lineData}
              unitLabels={rangeMonth}
              minWeight={60}
              maxWeight={80}
              weightUnits={weightUnits!}
              width={width}
              height={272}
              labelColors={LABEL_COLORS}
              labelGradient={labelGradient}
              weightPerDay={weightPerDay}
              graphPaddingLeft={35}
              graphPaddingRight={40}
            />
            <p className={styles.subtext}>
              <FormattedMessage
                id="Onboarding.OnlyPlan.Footnote"
                defaultMessage="*Based on the data of users who log their progress in the app. Consult your physician first. The chart is a non-customized illustration"
              />
            </p>
          </>
        )}
      </ParentSize>
    </figure>
  );
});

export default GraphLastPlanPrepaywall;
