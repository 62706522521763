import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { FormattedMessage } from '@features/intl';

import styles from './now-goal-body.module.scss';

const nowBody = [
  'afro-summary-body-type-average.webp',
  'afro-summary-body-type-extra.webp',
  'afro-summary-body-type-plump.webp',
  'afro-summary-body-type-slim.webp',
];

const goalBody = [
  'afro-goal-body-athletic.webp',
  'afro-goal-body-curvy.webp',
  'afro-goal-body-toned.webp',
  'afro-goal-body-few-size-smaller.webp',
];

const bodyMap = {
  current: new Map([
    ['average', nowBody[0]],
    ['extra', nowBody[1]],
    ['plump', nowBody[2]],
    ['slim', nowBody[3]],
    ['mid_sized', nowBody[0]],
    ['on_a_heavier_side', nowBody[1]],
    ['overweight', nowBody[2]],
  ]),
  goal: new Map([
    ['athletic', goalBody[0]],
    ['curvy', goalBody[1]],
    ['fit', goalBody[2]],
    ['thin', goalBody[0]],
    ['toned', goalBody[2]],
    ['just_a_few_sizes_smaller', goalBody[3]],
    ['a_few_sizes_down', goalBody[3]],
  ]),
};

export const NowGoalBody = observer(() => {
  const {
    quizStore,
    authStore: { user },
  } = useStores();
  const { physicalBuild, dreamBodyPilates } = quizStore;
  const currentBody = user?.user_data?.physicalBuild || physicalBuild;
  const goalBody = user?.user_data?.dreamBodyPilates || dreamBodyPilates;

  const bodyPair = useMemo(
    () => ({
      current: currentBody ? bodyMap.current.get(currentBody) : '',
      goal: goalBody ? bodyMap.goal.get(goalBody) : '',
    }),
    [currentBody, goalBody],
  );

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div
          className={classNames(styles.leftTitle, {
            [styles.afroTitle]: true,
          })}
        >
          <FormattedMessage
            defaultMessage="Now"
            id="Onboarding.FirstScreen.Now"
          />
        </div>
        <Image
          width={167}
          height={270}
          src={bodyPair.current || ''}
          alt="now-body"
        />
      </div>
      <div className={styles.subContainer}>
        <div
          className={classNames(styles.rightTitle, {
            [styles.afroTitle]: true,
          })}
        >
          <FormattedMessage
            defaultMessage="Your goal"
            id="Onboarding.FirstScreen.Goal"
          />
        </div>
        <Image
          width={167}
          height={270}
          src={bodyPair.goal || ''}
          alt="goal-body"
        />
      </div>
    </div>
  );
});
