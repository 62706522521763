import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';
import { TpQuizStepQuestionProps } from '../../types';

export const Walks = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setWalks },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOption
        stepName={stepName}
        name="walks"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setWalks}
        goToNextStep={goToNextStep}
        eventName="onboarding_often_walks_chosen"
      />
    );
  },
);
