export const getRandomEmail = (length: number = 8) => {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charLength = chars.length;
  let result = '';
  while (length--) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return `${result}@zxc.zxc`;
};
