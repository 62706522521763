import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './AnyDiet.module.scss';

export const AnyDiet = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setAnyDiet, getSingleOptionSelectStepValue },
    } = useStores();
    const intl = useIntl();
    const { options, stepName } = step;
    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setAnyDiet(event.target.value);
        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_kind_of_diet_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [handleEventSend, setAnyDiet],
    );

    return (
      <UiKitRadioGroup
        name="any-diet"
        defaultValue={value}
        onChange={handleRadioChange}
        className="options-container"
        onClick={goToNextStep}
      >
        {options.map(
          ({
            value,
            label,
            image,
            labelId,
            labelDefaultMessage,
            labelDescriptionId,
          }) => (
            <UiKitRadioOption
              key={`${stepName}_${value}`}
              label={
                labelId
                  ? intl.formatMessage({
                      id: labelId,
                      defaultMessage: labelDefaultMessage,
                    })
                  : label
              }
              subLabel={
                <FormattedMessage
                  id={labelDescriptionId as TpMessageKeys}
                  defaultMessage="Traditional description"
                />
              }
              labelClassName={styles.label}
              subLabelClassName={styles.anyDiet}
              value={value}
              startIcon={image}
            />
          ),
        )}
      </UiKitRadioGroup>
    );
  },
);
