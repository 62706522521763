import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';

import { TpSummaryStepProps } from '../../types';

export const YourBest = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <BreaksScreen
    step={step}
    goToNextStep={goToNextStep}
    gaEventQuery="your_best"
  />
);
