import classNames from 'classnames';
import { ElementType, ReactNode, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

import styles from './mobile-header.module.scss';

export type Props = {
  className?: string;
  titleClassName?: string;
  TitleComponent?: ElementType;
  title?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
};

const MobileHeader = ({
  className,
  titleClassName,
  TitleComponent = 'h1',
  title,
  left,
  right,
}: Props) => {
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const handleLeftRightResize = () => {
    const left = leftContainerRef.current;
    const right = rightContainerRef.current;
    if (left && right) {
      left.style.removeProperty('min-width');
      right.style.removeProperty('min-width');
      const leftWidth = left.offsetWidth;
      const rightWidth = right.offsetWidth;
      if (leftWidth > rightWidth) {
        right.style.setProperty('min-width', `${leftWidth}px`);
      } else if (rightWidth > leftWidth) {
        left.style.setProperty('min-width', `${rightWidth}px`);
      }
    }
  };
  useResizeObserver({
    ref: leftContainerRef,
    onResize: handleLeftRightResize,
  });
  useResizeObserver({
    ref: rightContainerRef,
    onResize: handleLeftRightResize,
  });

  return (
    <div className={classNames(styles.container, className)}>
      <div ref={leftContainerRef} className={styles.leftContainer}>
        {left}
      </div>
      {title && (
        <TitleComponent className={classNames(styles.title, titleClassName)}>
          {title}
        </TitleComponent>
      )}
      <div ref={rightContainerRef} className={styles.rightContainer}>
        {right}
      </div>
    </div>
  );
};

export default MobileHeader;
