import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import { GA_ID_V2 } from '../../constants';
import { googlePageView } from '../../services/gtag';

const handleRouteChange = (url: string) => {
  googlePageView(url);
};

export const GoogleAnalytics = observer(() => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID_V2}`}
      />
      <Script id="gtag">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){ dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('consent', 'default', {
            'ad_storage': 'granted'
          });
         gtag('config', ${JSON.stringify(GA_ID_V2)}, {
          page_path: window.location.pathname
        });`}
      </Script>
    </>
  );
});

export const GoogleTagManager = () => (
  <Script id="gtm_id">
    {`(function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', 'GTM-MNTGJ4LR');
      `}
  </Script>
);
