import classNames from 'classnames';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import DecimalNumberFormControl from 'src/components/common/form/decimal-number-form-control/decimal-number-form-control';
import { UiKitButton } from 'src/components/common/ui-kit-button';

import { Tooltip } from '@features/Quiz/PilatesOnboarding/steps/Weight/TooltipBMI';
import { WeightUnits } from '@features/Quiz/constants';
import { FormattedMessage } from '@features/intl';

import styles from './weight.module.scss';

type Values = {
  currentWeight?: number;
};

export const TabContent = observer(
  ({
    onChange,
    controlPlaceholder,
    controlMin,
    controlMax,
    currentWeight,
    unit,
    onNextPress,
  }: {
    onChange: (value: number) => void;
    controlPlaceholder: string;
    controlMin: number;
    controlMax: number;
    currentWeight?: number;
    unit: string;
    onNextPress?: ((value: number, units: WeightUnits) => void) | undefined;
  }) => {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      errors,
      isValid,
      resetForm,
    } = useFormik<Values>({
      initialValues: {
        currentWeight: currentWeight || undefined,
      },
      validationSchema: yup.object().shape({
        currentWeight: yup
          .number()
          .min(Math.ceil(controlMin))
          .max(Math.floor(controlMax))
          .transform((value) => (value === '' ? undefined : value)),
      }),
      onSubmit: (values) => {
        onChange(values.currentWeight!);
      },
    });

    useEffect(() => {
      resetForm(
        currentWeight == null
          ? undefined
          : {
              values: {
                currentWeight: Math.round(currentWeight),
              },
            },
      );
    }, [resetForm, currentWeight]);

    const handleClick = useCallback(() => {
      onNextPress?.(values.currentWeight!, unit as WeightUnits);
    }, [unit, onNextPress, values.currentWeight]);

    const showTooltip =
      values.currentWeight !== undefined && values.currentWeight > 0;

    const RenderTooltip = useCallback(
      () =>
        showTooltip ? (
          <Tooltip currentWeight={values.currentWeight} unit={unit} />
        ) : null,
      [showTooltip, values.currentWeight, unit],
    );

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.controlGroup}>
          <DecimalNumberFormControl
            id="currentWeight"
            placeholder={controlPlaceholder}
            name="currentWeight"
            value={values.currentWeight}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.currentWeight && errors.currentWeight != null}
          />
          <p
            className={classNames(styles.subText, {
              [styles.errorText]: errors.currentWeight,
            })}
          >
            <FormattedMessage
              defaultMessage="Please, enter a value from {mark1} {mark2} to {mark3} {mark4}"
              id="Onboarding.Weight.Text"
              values={{
                mark1: <b>{controlMin}</b>,
                mark2: <b>{unit}</b>,
                mark3: <b>{controlMax}</b>,
                mark4: <b>{unit}</b>,
              }}
            />
          </p>
        </div>
        <RenderTooltip />
        <UiKitButton
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || !values.currentWeight}
          onClick={handleClick}
          data-testid={`next-button-${unit}`}
        >
          <FormattedMessage
            defaultMessage="Next"
            id="Onboarding.Weight.Button"
          />
        </UiKitButton>
      </form>
    );
  },
);
