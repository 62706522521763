import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { Tooltip } from '@features/Quiz/PilatesOnboarding/steps/Weight/TooltipBMI';
import { FormattedMessage, TpMessageKeys, useIntl } from '@features/intl';

import styles from './BMIComponent.module.scss';

const scalePosition = [
  { value: 'UNDERWEIGHT', valueId: 'Onboarding.Profile.Variant1' },
  { value: 'NORMAL', valueId: 'Onboarding.Profile.Variant2' },
  { value: 'OVERWEIGHT', valueId: 'Onboarding.Profile.Variant3' },
  { value: 'OBESE', valueId: 'Onboarding.Profile.Variant4' },
];

const dietTranslationKeysMap = new Map([
  ['traditional', 'Onboarding.Diet.Option1.label'],
  ['keto', 'Onboarding.Diet.Option2.label'],
  ['vegetarian', 'Onboarding.Diet.Option3.label'],
  ['vegan', 'Onboarding.Diet.Option4.label'],
  ['keto_vegan', 'Onboarding.Diet.Option5.label'],
  ['pescetarian', 'Onboarding.Diet.Option6.label'],
  ['lactose_free', 'Onboarding.Diet.Option7.label'],
  ['gluten_free', 'Onboarding.Diet.Option8.label'],
  ['paleo', 'Onboarding.Diet.Option9.label'],
  ['mediterranean', 'Onboarding.Diet.Option10.label'],
]);

const contentData = [
  {
    type: 'Slim',
    typeId: 'Onboarding.Profile.Body.Type1',
    image: 'afro-summary-body-type-slim.webp',
  },
  {
    type: 'Mid-sized',
    image: 'afro-summary-body-type-average.webp',
    typeId: 'Onboarding.Profile.Body.Type2',
  },
  {
    type: 'On a heavier side',
    image: 'afro-summary-body-type-extra.webp',
    typeId: 'Onboarding.Profile.Body.Type3',
  },
  {
    type: 'Overweight',
    image: 'afro-summary-body-type-plump.webp',
    typeId: 'Onboarding.Profile.Body.Type3',
  },
];

const physicalBuildDataMap = new Map([
  ['slim', contentData[0]],
  ['mid_sized', contentData[1]],
  ['on_a_heavier_side', contentData[2]],
  ['overweight', contentData[3]],
]);

const fitnessLevelMap: { [key: string]: string } = {
  almost_every_day: 'Onboarding.Profile.Fitness.Level1',
  several_times_per_week: 'Onboarding.Profile.Fitness.Level2',
  several_times_per_month: 'Onboarding.Profile.Fitness.Level2',
  default: 'Onboarding.Profile.Fitness.Level3',
};

const MIN_BMI = 14.5;
const MAX_BMI = 32;

const BMIComponent = observer(() => {
  const intl = useIntl();
  const { quizStore } = useStores();

  const {
    physicalBuild,
    currentBMI,
    weightUnits,
    userName,
    anyDiet,
    exercise,
  } = quizStore;

  const getData = useCallback(
    () => physicalBuildDataMap.get(physicalBuild as string),
    [physicalBuild],
  );

  const currentFitnessLevel = useMemo(
    () =>
      intl.formatMessage({
        id:
          (fitnessLevelMap[exercise as string] as TpMessageKeys) ??
          (fitnessLevelMap['default'] as TpMessageKeys),
        defaultMessage: 'Beginner',
      }),
    [exercise, intl],
  );

  const currentData = getData() || contentData[0];

  const bmiCircleRef = useRef<HTMLDivElement | null>(null);
  const bmiCountRef = useRef<HTMLDivElement | null>(null);

  const calculateBmiCirclePosition = useCallback(() => {
    const bmiCircle = bmiCircleRef.current;
    const bmiCount = bmiCountRef.current;
    if (bmiCircle && bmiCount) {
      const bmi = currentBMI ?? 21.5;
      const scaleWidth = MAX_BMI - MIN_BMI;

      let newPosition = +(((bmi - MIN_BMI) / scaleWidth) * 100).toFixed(1);

      if (bmi < MIN_BMI) {
        newPosition = 0;
      } else if (bmi >= MAX_BMI) {
        newPosition = 100 - 4;
      }

      bmiCircle.style.left = `${newPosition}%`;
      bmiCount.style.left = `${newPosition}%`;
    }
  }, [currentBMI]);

  useEffect(() => {
    calculateBmiCirclePosition();
  }, [calculateBmiCirclePosition]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          <FormattedMessage
            defaultMessage="Body Mass Index (BMI)"
            id="Onboarding.Profile.Subtitle1"
          />
        </h1>
        <span className={styles.subtitle}>Normal - 21.5</span>
      </div>
      <div className={styles.scaleContainer}>
        <div className={styles.linear}>
          <div ref={bmiCountRef} className={styles.bmiCount}>
            {currentBMI ?? 21.5}
            <div className={styles.triangle} />
          </div>
          <p ref={bmiCircleRef} className={styles.bmiCircle}></p>
        </div>
        <div className={styles.scale}>
          {scalePosition.map((item, index) => (
            <span className={styles.scaleItem} key={index}>
              <FormattedMessage
                defaultMessage={item.value}
                id={item.valueId as TpMessageKeys}
              />
            </span>
          ))}
        </div>
      </div>
      <Tooltip
        isOnFinalScreen={true}
        currentBMI={currentBMI}
        unit={weightUnits!}
      />
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <ul className={styles.categoryContainer}>
            <li>
              <span className={styles.categoryTitle}>
                <FormattedMessage
                  defaultMessage="Name"
                  id="Onboarding.Profile.Bullet1"
                />
              </span>
              <p className={styles.categoryContent}>{userName}</p>
            </li>
            <li>
              <span className={styles.categoryTitle}>
                <FormattedMessage
                  defaultMessage="Fitness level"
                  id="Onboarding.Profile.Bullet2"
                />
              </span>
              <p className={styles.categoryContent}>{currentFitnessLevel}</p>
            </li>
            <li>
              <span className={styles.categoryTitle}>
                <FormattedMessage
                  defaultMessage="Body Type"
                  id="Onboarding.Profile.Bullet3"
                />
              </span>
              <p className={styles.categoryContent}>
                {intl.formatMessage({
                  id: currentData.typeId as TpMessageKeys,
                  defaultMessage: currentData.type,
                })}
              </p>
            </li>
            <li>
              <span className={styles.categoryTitle}>
                <FormattedMessage
                  defaultMessage="Diet Type"
                  id="Onboarding.Profile.Bullet4"
                />
              </span>
              <p
                className={styles.categoryContent}
                style={{ textTransform: 'capitalize' }}
              >
                {intl.formatMessage({
                  id: dietTranslationKeysMap.get(
                    anyDiet as string,
                  ) as TpMessageKeys,
                  defaultMessage: anyDiet,
                })}
              </p>
            </li>
          </ul>
        </div>
        <div className={styles.rightSide}>
          <Image
            width={167}
            height={270}
            src={currentData!.image}
            alt="body-type"
          />
        </div>
      </div>
    </div>
  );
});

export default BMIComponent;
