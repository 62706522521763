import classNames from 'classnames';

import { usePersonalPlan } from '@features/PersonalPlan';

import styles from './WeightProgress.module.scss';

export const WeightProgress = () => {
  const {
    defaultTargetDate,
    totalTooltip,
    goalWeightValue,
    weightValue,
    weightUnits,
  } = usePersonalPlan();

  return (
    <div className={styles.host}>
      <div className={styles.itemsWithWeights}>
        <span className={styles.weightValue}>
          {weightValue} {weightUnits}
        </span>
        <div className={styles.items}>
          {Array.from({ length: 6 }).map((_, index) => (
            <span
              className={classNames(styles.item, styles[`item${index + 1}`])}
              key={index}
            />
          ))}
        </div>
        <span className={styles.weightValue}>
          {goalWeightValue} {weightUnits}
        </span>
      </div>
      <div className={styles.dates}>
        <span>Today</span>
        <span>{defaultTargetDate}</span>
      </div>

      {totalTooltip && (
        <div
          className={styles.tooltip}
          dangerouslySetInnerHTML={{ __html: totalTooltip }}
        />
      )}
    </div>
  );
};
