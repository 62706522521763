import Chevron from 'src/assets/icons/chevron-white-polates.svg';
import { Image } from 'src/components/common/Image';
import { getLocaleImage } from 'src/utils';

import { TpPilatesQuizStepList, TpPilatesStep } from './types';

export const QUIZ_STEPS_LIST = {
  ABOUT_YOU_BREAKS: 'about_you_breaks',
  ADAPT_PLAN: 'adapt_plan',
  AGE: 'age_number',
  ANY_DIET: 'any_diet',
  BAD_HABITS: 'bad_habits',
  BEST_NO_DIET: 'best_no_diet',
  BEST_SHAPE: 'best_shape',
  BEST_SHAPE_V2: 'best_shape_v2',
  BREAKFAST_TIME: 'breakfast_time',
  CHOOSE_YOUTH: 'choose_youth_pilates',
  COMMIT_TO: 'commit_to',
  CURRENT_WEIGHT: 'current_weight_pilates',
  DAILY_DRINK: 'daily_drink',
  DAILY_DRINK_V2: 'daily_drink_v2',
  DINNER_TIME: 'dinner_time',
  DREAM_BODY_PILATES: 'dream_body_pilates',
  EATING_HABITS: 'eating_habits',
  ENERGY_LEVEL: 'energy_level',
  ENERGY_LEVEL_V2: 'energy_level_v2',
  ENTER_EMAIL: 'enter_email',
  EVENT_COMING: 'event_coming',
  EXERCISE: 'exercise',
  EXERCISES_AVOID: 'exercises_avoid',
  EXERCISE_V2: 'exercise_v2',
  FITNESS_EXPERIENCE: 'fitness_experience',
  FLEXIBLE_PILATES: 'flexible_pilates',
  FLEXIBLE_PILATES_V2: 'flexible_pilates_v2',
  GOAL_JTBD: 'goal_jtbd',
  GOAL_PILATES: 'goal_pilates',
  GOT_GREAT_NEWS: 'got_great_news',
  HEIGHT: 'height_pilates',
  HOW_FAST_REACH_GOAL: 'how_fast_reach_goal',
  LAST_PLAN_LONG: 'last_plan_long_pilates',
  LIFE_EVENTS: 'life_events',
  LUNCH_TIME: 'lunch_time',
  MADE_FOR_YOU: 'made_for_you',
  MILLION_USERS: 'million_users',
  NAME: 'name',
  NUTRITION_BREAK: 'nutrition_break',
  OUT_OF_BREATH: 'out_of_breath',
  OUT_OF_BREATH_V2: 'out_of_breath_v2',
  PHYSICAL_BUILD: 'physical_build',
  PHYSICAL_BUILD_V2: 'physical_build_v2',
  PLAN_READY_CHART_LONG: 'plan_ready_chart_long',
  PREFER_WORKOUT: 'prefer_workout',
  PROVEN_EFFECTIVENESS: 'proven_effectiveness',
  SLEEP_HYGIENE: 'sleep_hygiene',
  STAY_FOCUSED: 'stay_focused',
  STRUGGLE_WITH: 'struggle_with',
  STRUGGLE_WITH_V2: 'struggle_with',
  TARGET_WEIGHT: 'target_weight_pilates',
  TARGET_ZONES: 'target_zones',
  TRIED_PILATES: 'tried_pilates',
  TRUST_EXPERTS: 'trust_experts',
  TYPICAL_DAY: 'typical_day',
  TYPICAL_DAY_V2: 'typical_day_v2',
  USUALLY_SLEEP: 'usually_sleep',
  WALKS: 'walks',
  WALKS_V2: 'walks_v2',
  WEIGHT_DYNAMIC: 'weight_dynamic',
  WELCOME: 'welcome_pilates',
  WELCOME_JTBD: 'welcome_jtbd',
  WELLNESS_PROFILE: 'wellness_profile_pilates',
  WHEN_EVENT: 'when_event_pilates',
  WORK_SCHEDULE: 'work_schedule',
  YOUR_BEST: 'your_best',
  YOUR_COMMUNITY: 'your_community',
} as const;

export const STEPS: { [key in TpPilatesQuizStepList]: TpPilatesStep } = {
  [QUIZ_STEPS_LIST.WELCOME]: {
    type: 'other',
    gaEventName: 'onboarding_goal_opened',
    gaEventOptions: 'pilates',
    stepName: QUIZ_STEPS_LIST.WELCOME,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: false,
    showStepHeader: true,
    showPreviousPress: false,
    showBurgerMenu: true,
    title: '',
    options: [
      {
        value: '18_35',
        label: (
          <>
            18-35 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="18-35-pilates.webp" alt="" />
        ),
      },
      {
        value: '35_45',
        label: (
          <>
            35-45 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="35-45-pilates.webp" alt="" />
        ),
      },
      {
        value: '45_55',
        label: (
          <>
            45-55 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="45-55-pilates.webp" alt="" />
        ),
      },
      {
        value: '55+',
        label: (
          <>
            55+ <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="55plus-pilates.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.WELCOME_JTBD]: {
    type: 'other',
    gaEventName: 'onboarding_jtbd_goal_opened',
    gaEventOptions: 'pilates_jtbd',
    stepName: QUIZ_STEPS_LIST.WELCOME_JTBD,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: false,
    showStepHeader: true,
    showPreviousPress: false,
    showBurgerMenu: true,
    title: '',
    options: [
      {
        value: '18_35',
        label: (
          <>
            18-35 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="18-35-pilates.webp" alt="" />
        ),
      },
      {
        value: '35_45',
        label: (
          <>
            35-45 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="35-45-pilates.webp" alt="" />
        ),
      },
      {
        value: '45_55',
        label: (
          <>
            45-55 <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="45-55-pilates.webp" alt="" />
        ),
      },
      {
        value: '55+',
        label: (
          <>
            55+ <Chevron width={20} height={20} />
          </>
        ),
        image: (
          <Image width={156} height={140} src="55plus-pilates.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.CHOOSE_YOUTH]: {
    type: 'other',
    stepName: QUIZ_STEPS_LIST.CHOOSE_YOUTH,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: false,
    imagesForPreload: ['trio-pilates.webp'],
    title: '',
  },
  [QUIZ_STEPS_LIST.GOAL_PILATES]: {
    type: 'singleOption',
    gaEventName: 'onboarding_goal_opened',
    stepName: QUIZ_STEPS_LIST.GOAL_PILATES,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What is your main goal?',
    titleId: 'Onboarding.Goal.Title',
    imagesForPreload: [
      'emoji-arm-strong.webp',
      'emoji-man-lifting-weights.webp',
      'emoji-dancer.webp',
      'emoji-yoga-woman.webp',
    ],
    options: [
      {
        value: 'lose_weight',
        labelId: 'Onboarding.Goal.Option.Weight',
        labelDefaultMessage: 'Lose weight',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: 'build_muscle_strength',
        labelId: 'Onboarding.Goal.Option.Strenght',
        labelDefaultMessage: 'Build muscle strenght',
        image: (
          <Image
            width={40}
            height={40}
            src="emoji-man-lifting-weights.webp"
            alt=""
          />
        ),
      },
      {
        value: 'develop_flexibility',
        labelId: 'Onboarding.Goal.Option.Flexibility',
        labelDefaultMessage: 'Increase flexibility',
        image: <Image width={40} height={40} src="emoji-dancer.webp" alt="" />,
      },
      {
        value: 'reduce_stress_anxiety',
        labelDefaultMessage: 'Reduce stress & anxiety',
        labelId: 'Onboarding.Goal.Option.Stress',
        image: (
          <Image width={40} height={40} src="emoji-yoga-woman.webp" alt="" />
        ),
      },
      {
        value: 'improve_posture',
        labelDefaultMessage: 'Improve posture',
        labelId: 'Onboarding.Goal.Option.Posture',
        image: (
          <Image width={40} height={40} src="emoji-woman-walking.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.GOAL_JTBD]: {
    type: 'singleOption',
    gaEventName: 'onboarding_goal_jtbd_opened',
    stepName: QUIZ_STEPS_LIST.GOAL_JTBD,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: false,
    showStepHeader: false,
  },
  [QUIZ_STEPS_LIST.STAY_FOCUSED]: {
    type: 'singleOption',
    gaEventName: 'onboarding_stay_focused_opened',
    stepName: QUIZ_STEPS_LIST.STAY_FOCUSED,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'What helps you stay focused on your fitness goals?',
  },
  [QUIZ_STEPS_LIST.WEIGHT_DYNAMIC]: {
    type: 'singleOption',
    gaEventName: 'onboarding_weight_dynamic_opened',
    stepName: QUIZ_STEPS_LIST.WEIGHT_DYNAMIC,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How does your weight typically change?',
    imagesForPreload: ['weight-dynamic-left.webp', 'weight-dynamic-bg.webp'],
  },
  [QUIZ_STEPS_LIST.BEST_SHAPE_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_best_shape_v2_opened',
    stepName: QUIZ_STEPS_LIST.BEST_SHAPE_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How long ago were you in the best shape of your life?',
    options: [
      {
        value: 'less_than_a_year_ago',
        labelId: 'Onboarding.BestShape.Option1',
        labelDefaultMessage: 'Less than a year ago',
      },
      {
        value: '1_to_2_years_ago',
        labelId: 'Onboarding.BestShape.Option2',
        labelDefaultMessage: '1 to 2 years ago',
      },
      {
        value: 'more_than_3_years_ago',
        labelId: 'Onboarding.BestShape.Option3',
        labelDefaultMessage: 'More than 3 years ago',
      },
      {
        value: 'never',
        labelId: 'Onboarding.BestShape.Option4',
        labelDefaultMessage: 'Never',
      },
    ],
  },
  [QUIZ_STEPS_LIST.FLEXIBLE_PILATES_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_flexible_v2_opened',
    stepName: QUIZ_STEPS_LIST.FLEXIBLE_PILATES_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How would you rate your flexibility?',
    imagesForPreload: [
      'flexible-pilates-left.webp',
      'flexible-pilates-bg.webp',
    ],
    options: [
      {
        value: 'pretty_flexible',
        labelId: 'Onboarding.Flexible.Option1',
        labelDefaultMessage: 'Pretty flexible',
      },
      {
        value: 'just_getting_started',
        labelId: 'Onboarding.Flexible.Option2',
        labelDefaultMessage: 'Just getting started',
      },
      {
        value: 'not_that_good',
        labelId: 'Onboarding.Flexible.Option3',
        labelDefaultMessage: 'Not too much',
      },
      {
        value: 'not_sure',
        labelId: 'Onboarding.Flexible.Option4',
        labelDefaultMessage: 'Not sure',
      },
    ],
  },
  [QUIZ_STEPS_LIST.EXERCISE_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_often_exercise_v2_opened',
    stepName: QUIZ_STEPS_LIST.EXERCISE_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How often do you work out?',
    imagesForPreload: ['exercise-left.webp', 'exercise-bg.webp'],
    options: [
      {
        value: 'almost_every_day',
        labelId: 'Onboarding.Exercise.Option1',
        labelDefaultMessage: 'Almost every day',
      },
      {
        value: 'few_times_per_week',
        label: 'A few times a week',
      },
      {
        value: 'few_times_per_month',
        label: 'A few times a month',
      },
      {
        value: 'Never',
        labelId: 'Onboarding.Exercise.Option4',
        labelDefaultMessage: 'Never',
      },
    ],
  },
  [QUIZ_STEPS_LIST.EXERCISES_AVOID]: {
    type: 'singleOption',
    gaEventName: 'onboarding_often_exercises_avoid_opened',
    stepName: QUIZ_STEPS_LIST.EXERCISES_AVOID,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'Are there any types of exercises you need to avoid?',
    imagesForPreload: ['exercise-pilates.webp'],
    options: [
      {
        value: 'jumping_or_cardio',
        label: 'Jumping or cardio',
        image: (
          <Image width={40} height={40} src="emoji-woman-running.webp" alt="" />
        ),
      },
      {
        value: 'squats_or_lunges',
        label: 'Squats or lunges',
        image: (
          <Image width={40} height={40} src="emoji-woman-walking.webp" alt="" />
        ),
      },
      {
        value: 'strength_training_with_weights',
        label: 'Strength training with weights',
        image: <Image width={40} height={40} src="emoji-workout.webp" alt="" />,
      },
      {
        value: 'none_of_the_above',
        label: 'None of the above',
        image: <Image width={40} height={40} src="emoji-ok.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.PHYSICAL_BUILD]: {
    type: 'singleOption',
    gaEventName: 'onboarding_current_body_opened',
    stepName: QUIZ_STEPS_LIST.PHYSICAL_BUILD,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What is your body type?',
    titleId: 'Onboarding.BodyType.Title',
    imagesForPreload: [
      'pilates-slim-body.webp',
      'pilates-mid-sized-body.webp',
      'pilates-heavier-side-body.webp',
      'pilates-overweight-body.webp',
    ],
    options: [
      {
        value: 'slim',
        labelId: 'Onboarding.BodyType.Slim',
        labelDefaultMessage: 'Slim',
        image: (
          <Image width={80} height={80} src="pilates-slim-body.webp" alt="" />
        ),
      },
      {
        value: 'mid_sized',
        labelId: 'Onboarding.BodyType.Average',
        labelDefaultMessage: 'Average',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-mid-sized-body.webp"
            alt=""
          />
        ),
      },
      {
        labelId: 'Onboarding.BodyType.Havier',
        value: 'on_a_heavier_side',
        labelDefaultMessage: 'On a heavier side',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-heavier-side-body.webp"
            alt=""
          />
        ),
      },
      {
        labelId: 'Onboarding.BodyType.Overweight',
        value: 'overweight',
        labelDefaultMessage: 'Overweight',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-overweight-body.webp"
            alt=""
          />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.PHYSICAL_BUILD_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_current_body_opened',
    stepName: QUIZ_STEPS_LIST.PHYSICAL_BUILD_V2,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How would your describe your physical build?',
    imagesForPreload: [
      'pilates-slim-body.webp',
      'pilates-mid-sized-body.webp',
      'pilates-heavier-side-body.webp',
      'pilates-overweight-body.webp',
    ],
    options: [
      {
        value: 'slim',
        labelId: 'Onboarding.BodyType.Slim',
        labelDefaultMessage: 'Slim',
        image: (
          <Image width={80} height={80} src="pilates-slim-body.webp" alt="" />
        ),
      },
      {
        value: 'mid_sized',
        labelId: 'Onboarding.BodyType.Average',
        labelDefaultMessage: 'Average',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-mid-sized-body.webp"
            alt=""
          />
        ),
      },
      {
        labelId: 'Onboarding.BodyType.Havier',
        value: 'on_a_heavier_side',
        labelDefaultMessage: 'On a heavier side',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-heavier-side-body.webp"
            alt=""
          />
        ),
      },
      {
        labelId: 'Onboarding.BodyType.Overweight',
        value: 'overweight',
        labelDefaultMessage: 'Overweight',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-overweight-body.webp"
            alt=""
          />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.DREAM_BODY_PILATES]: {
    type: 'singleOption',
    gaEventName: 'onboarding_dream_body_opened',
    stepName: QUIZ_STEPS_LIST.DREAM_BODY_PILATES,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    titleId: 'Onboarding.DreamBody.Title',
    defaultTitleMessage: 'What\'s your "dream body"?',
    imagesForPreload: [
      'pilates-thin-body.webp',
      'pilates-toned-body.webp',
      'pilates-curvy-body.webp',
      'pilates-sizes-smaller.webp',
    ],
    options: [
      {
        value: 'thin',
        labelId: 'Onboarding.DreamBody.Thin',
        labelDefaultMessage: 'Thin',
        image: (
          <Image width={80} height={80} src="pilates-thin-body.webp" alt="" />
        ),
      },
      {
        value: 'toned',
        labelId: 'Onboarding.DreamBody.Toned',
        labelDefaultMessage: 'Toned',
        image: (
          <Image width={80} height={80} src="pilates-toned-body.webp" alt="" />
        ),
      },
      {
        value: 'curvy',
        labelId: 'Onboarding.DreamBody.Curvy',
        labelDefaultMessage: 'Curvy',
        image: (
          <Image width={80} height={80} src="pilates-curvy-body.webp" alt="" />
        ),
      },
      {
        value: 'a_few_sizes_down',
        labelId: 'Onboarding.DreamBody.Few',
        labelDefaultMessage: 'A few sizes down',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-sizes-smaller.webp"
            alt=""
          />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.TARGET_ZONES]: {
    type: 'multipleOptions',
    gaEventName: 'onboarding_target_zone_opened',
    stepName: QUIZ_STEPS_LIST.TARGET_ZONES,
    section: 'Your Goal',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What are your target zones?',
    titleId: 'Onboarding.Zone.Title',
    imagesForPreload: [
      'pilates-flat-belly.webp',
      'pilates-toned-butt.webp',
      'pilates-perky-breasts.webp',
      'pilates-toned-legs.webp',
    ],
    options: [
      {
        value: 'flat_belly',
        labelId: 'Onboarding.Zone.Belly',
        labelDefaultMessage: 'Flat belly',
        image: (
          <Image width={80} height={80} src="pilates-flat-belly.webp" alt="" />
        ),
      },
      {
        value: 'toned_butt',
        labelId: 'Onboarding.Zone.Butt',
        labelDefaultMessage: 'Toned butt',
        image: (
          <Image width={80} height={80} src="pilates-toned-butt.webp" alt="" />
        ),
      },
      {
        value: 'perky_breasts',
        labelId: 'Onboarding.Zone.Breasts',
        labelDefaultMessage: 'Perky breasts',
        image: (
          <Image
            width={80}
            height={80}
            src="pilates-perky-breasts.webp"
            alt=""
          />
        ),
      },
      {
        value: 'toned_legs',
        labelId: 'Onboarding.Zone.Legs',
        labelDefaultMessage: 'Toned legs ',
        image: (
          <Image width={80} height={80} src="pilates-toned-legs.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.FITNESS_EXPERIENCE]: {
    type: 'singleOption',
    gaEventName: 'onboarding_experience_fitness_opened',
    stepName: QUIZ_STEPS_LIST.FITNESS_EXPERIENCE,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What best describes your experience with fitness?',
    titleId: 'Onboarding.Experience.Title',
    imagesForPreload: [
      'emoji-sad.webp',
      'emoji-arm-strong.webp',
      'emoji-grimacing.webp',
    ],
    options: [
      {
        value: 'trouble_gaining_muscle',
        labelId: 'Onboarding.Experience.Option1',
        labelDefaultMessage: 'I have trouble gaining muscle or body fat',
        image: <Image width={40} height={40} src="emoji-sad.webp" alt="" />,
      },
      {
        value: 'lose_weight_without_effort',
        labelId: 'Onboarding.Experience.Option2',
        labelDefaultMessage: 'I gain and lose weight without effort',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: 'gain_weight_easily',
        labelId: 'Onboarding.Experience.Option3',
        labelDefaultMessage: 'I gain weight easily but find it hard to lose it',
        image: (
          <Image width={40} height={40} src="emoji-grimacing.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.BEST_SHAPE]: {
    type: 'singleOption',
    gaEventName: 'onboarding_perfect_shape_opened',
    stepName: QUIZ_STEPS_LIST.BEST_SHAPE,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: "When did you feel as if you're in your best shape?",
    titleId: 'Onboarding.BestShape.Title',
    imagesForPreload: [
      'emoji-arm-strong.webp',
      'emoji-unhappy.webp',
      'emoji-smiling-tear.webp',
      'emoji-x.webp',
    ],
    options: [
      {
        value: 'less_than_a_year_ago',
        labelId: 'Onboarding.BestShape.Option1',
        labelDefaultMessage: 'Less than a year ago',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: '1_to_2_years_ago',
        labelId: 'Onboarding.BestShape.Option2',
        labelDefaultMessage: '1 to 2 years ago',
        image: <Image width={40} height={40} src="emoji-unhappy.webp" alt="" />,
      },
      {
        value: 'more_than_3_years_ago',
        labelId: 'Onboarding.BestShape.Option3',
        labelDefaultMessage: 'More than 3 years ago',
        image: (
          <Image width={40} height={40} src="emoji-smiling-tear.webp" alt="" />
        ),
      },
      {
        value: 'never',
        labelId: 'Onboarding.BestShape.Option4',
        labelDefaultMessage: 'Never',
        image: <Image width={40} height={40} src="emoji-x.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.TRIED_PILATES]: {
    type: 'singleOption',
    gaEventName: 'onboarding_tried_pilates_opened',
    stepName: QUIZ_STEPS_LIST.TRIED_PILATES,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'Have you tried pilates workouts before?',
    titleId: 'Onboarding.Tried.Title',
    imagesForPreload: [
      'emoji-arm-strong.webp',
      'emoji-blush.webp',
      'emoji-rolling-eyes.webp',
    ],
    options: [
      {
        value: 'regularly',
        labelId: 'Onboarding.Tried.Option1',
        labelDefaultMessage: 'Yes, I practice it regularly',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: 'tried_them',
        labelId: 'Onboarding.Tried.Option2',
        labelDefaultMessage: "Yes, I've tried it before",
        image: <Image width={40} height={40} src="emoji-blush.webp" alt="" />,
      },
      {
        value: 'never_tried',
        labelId: 'Onboarding.Tried.Option3',
        labelDefaultMessage: 'No, I have never tried it',
        image: (
          <Image width={40} height={40} src="emoji-rolling-eyes.webp" alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.FLEXIBLE_PILATES]: {
    type: 'singleOption',
    gaEventName: 'onboarding_flexible_opened',
    stepName: QUIZ_STEPS_LIST.FLEXIBLE_PILATES,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How flexible are you?',
    titleId: 'Onboarding.Flexible.Title',
    imagesForPreload: ['flexible-pilates.webp'],
    options: [
      {
        value: 'pretty_flexible',
        labelId: 'Onboarding.Flexible.Option1',
        labelDefaultMessage: 'Pretty flexible',
      },
      {
        value: 'just_getting_started',
        labelId: 'Onboarding.Flexible.Option2',
        labelDefaultMessage: 'Just getting started',
      },
      {
        value: 'not_that_good',
        labelId: 'Onboarding.Flexible.Option3',
        labelDefaultMessage: 'Not too much',
      },
      {
        value: 'not_sure',
        labelId: 'Onboarding.Flexible.Option4',
        labelDefaultMessage: 'Not sure',
      },
    ],
  },
  [QUIZ_STEPS_LIST.EXERCISE]: {
    type: 'singleOption',
    gaEventName: 'onboarding_often_exercise_opened',
    stepName: QUIZ_STEPS_LIST.EXERCISE,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How often do you exercise?',
    titleId: 'Onboarding.Exercise.Title',
    imagesForPreload: ['exercise-pilates.webp'],
    options: [
      {
        value: 'almost_every_day',
        labelId: 'Onboarding.Exercise.Option1',
        labelDefaultMessage: 'Almost every day',
      },
      {
        value: 'several_times_per_week',
        labelId: 'Onboarding.Exercise.Option2',
        labelDefaultMessage: 'Several times a week',
      },
      {
        value: 'several_times_per_month',
        labelId: 'Onboarding.Exercise.Option3',
        labelDefaultMessage: 'Several times a month',
      },
      {
        value: 'Never',
        labelId: 'Onboarding.Exercise.Option4',
        labelDefaultMessage: 'Never',
      },
    ],
  },
  [QUIZ_STEPS_LIST.OUT_OF_BREATH]: {
    type: 'singleOption',
    gaEventName: 'onboarding_breath_after_walking_opened',
    stepName: QUIZ_STEPS_LIST.OUT_OF_BREATH,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'Do you get out of breath walking up the stairs?',
    titleId: 'Onboarding.Breath.Title',
    imagesForPreload: [
      'emoji-tired.webp',
      'emoji-exhaling.webp',
      'emoji-slightly-smiling.webp',
      'emoji-rocket.webp',
    ],
    options: [
      {
        value: 'can_not_talk',
        labelId: 'Onboarding.Breath.Option1',
        labelDefaultMessage: "I'm so out of breath | can't talk",
        image: <Image width={40} height={40} src="emoji-tired.webp" alt="" />,
      },
      {
        value: 'can_talk',
        labelId: 'Onboarding.Breath.Option2',
        labelDefaultMessage: "I'm somewhat out of breath but can talk",
        image: (
          <Image width={40} height={40} src="emoji-exhaling.webp" alt="" />
        ),
      },
      {
        value: 'ok_after_one_flight',
        labelId: 'Onboarding.Breath.Option3',
        labelDefaultMessage: "I'm OK after one flight of stairs",
        image: (
          <Image
            width={40}
            height={40}
            src="emoji-slightly-smiling.webp"
            alt=""
          />
        ),
      },
      {
        value: 'easily_walk_up',
        labelId: 'Onboarding.Breath.Option4',
        labelDefaultMessage: 'I can easily walk up a few flights of stairs',
        image: <Image width={40} height={40} src="emoji-rocket.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.OUT_OF_BREATH_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_breath_after_walking_opened',
    stepName: QUIZ_STEPS_LIST.OUT_OF_BREATH_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How do you feel after walking up a few flights of stairs',
    imagesForPreload: [
      'emoji-tired.webp',
      'emoji-exhaling.webp',
      'emoji-slightly-smiling.webp',
      'emoji-rocket.webp',
    ],
    options: [
      {
        value: 'can_not_talk',
        labelId: 'Onboarding.Breath.Option1',
        labelDefaultMessage: "I'm so out of breath | can't talk",
        image: <Image width={40} height={40} src="emoji-tired.webp" alt="" />,
      },
      {
        value: 'can_talk',
        labelId: 'Onboarding.Breath.Option2',
        labelDefaultMessage: "I'm somewhat out of breath but can talk",
        image: (
          <Image width={40} height={40} src="emoji-exhaling.webp" alt="" />
        ),
      },
      {
        value: 'ok_after_one_flight',
        labelId: 'Onboarding.Breath.Option3',
        labelDefaultMessage: "I'm OK after one flight of stairs",
        image: (
          <Image
            width={40}
            height={40}
            src="emoji-slightly-smiling.webp"
            alt=""
          />
        ),
      },
      {
        value: 'easily_walk_up',
        labelId: 'Onboarding.Breath.Option4',
        labelDefaultMessage: 'I can easily walk up a few flights of stairs',
        image: <Image width={40} height={40} src="emoji-rocket.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.WALKS]: {
    type: 'singleOption',
    gaEventName: 'onboarding_often_walks_opened',
    stepName: QUIZ_STEPS_LIST.WALKS,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How often do you go for walks?',
    titleId: 'Onboarding.Walks.Title',
    imagesForPreload: [
      'emoji-sunglasses.webp',
      'emoji-arm-strong.webp',
      'emoji-slightly-smiling.webp',
      'emoji-sad.webp',
    ],
    options: [
      {
        value: 'almost_every_day',
        labelId: 'Onboarding.Walks.Option1',
        labelDefaultMessage: 'Almost every day',
        image: (
          <Image width={40} height={40} src="emoji-sunglasses.webp" alt="" />
        ),
      },
      {
        value: '3_4_times_per_week',
        labelId: 'Onboarding.Walks.Option2',
        labelDefaultMessage: '3-4 times per week',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: '1_2_times_per_week',
        labelId: 'Onboarding.Walks.Option3',
        labelDefaultMessage: '1-2 times per week',
        image: (
          <Image
            width={40}
            height={40}
            src="emoji-slightly-smiling.webp"
            alt=""
          />
        ),
      },
      {
        value: 'more_like_once_a_month',
        labelId: 'Onboarding.Walks.Option4',
        labelDefaultMessage: 'More like once a month',
        image: <Image width={40} height={40} src="emoji-sad.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.WALKS_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_often_walks_v2_opened',
    stepName: QUIZ_STEPS_LIST.WALKS_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How often do you {br} go for a walk?',
    titleId: 'Onboarding.Walks.Title.v2',
    imagesForPreload: [
      'emoji-sunglasses.webp',
      'emoji-arm-strong.webp',
      'emoji-slightly-smiling.webp',
      'emoji-sad.webp',
    ],
    options: [
      {
        value: 'almost_every_day',
        labelId: 'Onboarding.Walks.Option1',
        labelDefaultMessage: 'Almost every day',
        image: (
          <Image width={40} height={40} src="emoji-sunglasses.webp" alt="" />
        ),
      },
      {
        value: '3_4_times_per_week',
        labelId: 'Onboarding.Walks.Option2',
        labelDefaultMessage: '3-4 times per week',
        image: (
          <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />
        ),
      },
      {
        value: '1_2_times_per_week',
        labelId: 'Onboarding.Walks.Option3',
        labelDefaultMessage: '1-2 times per week',
        image: (
          <Image
            width={40}
            height={40}
            src="emoji-slightly-smiling.webp"
            alt=""
          />
        ),
      },
      {
        value: 'more_like_once_a_month',
        labelId: 'Onboarding.Walks.Option4',
        labelDefaultMessage: 'More like once a month',
        image: <Image width={40} height={40} src="emoji-sad.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.STRUGGLE_WITH]: {
    type: 'multipleOptions',
    gaEventName: 'onboarding_struggle_any_following_opened',
    stepName: QUIZ_STEPS_LIST.STRUGGLE_WITH,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What do you struggle with?',
    titleId: 'Onboarding.Struggle.Title',
    imagesForPreload: [
      'sensitive-back.svg',
      'sensitive-knees.svg',
      'neck-pain.svg',
      'struggle-none.svg',
    ],
    options: [
      {
        value: 'sensitive_back',
        labelId: 'Onboarding.Struggle.Option1',
        labelDefaultMessage: 'Back pain',
        image: <Image width={80} height={80} src="sensitive-back.svg" alt="" />,
      },
      {
        value: 'sensitive_knees',
        labelId: 'Onboarding.Struggle.Option2',
        labelDefaultMessage: 'Knee pain',
        image: (
          <Image width={80} height={80} src="sensitive-knees.svg" alt="" />
        ),
      },
      {
        value: 'neck_pain',
        labelId: 'Onboarding.Struggle.Option3',
        labelDefaultMessage: 'Neck pain',
        image: <Image width={80} height={80} src="neck-pain.svg" alt="" />,
      },
    ],
    skipOptionText: {
      value: 'none_of_the_above',
      labelId: 'Onboarding.Struggle.Option4',
      labelDefaultMessage: 'None of the above',
      image: <Image width={80} height={80} src="struggle-none.svg" alt="" />,
    },
  },
  [QUIZ_STEPS_LIST.STRUGGLE_WITH_V2]: {
    type: 'multipleOptions',
    gaEventName: 'onboarding_struggle_any_following_opened',
    stepName: QUIZ_STEPS_LIST.STRUGGLE_WITH_V2,
    section: 'Current Level',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'What do you struggle with the most?',
    imagesForPreload: [
      'sensitive-back-jtbd.webp',
      'sensitive-knees-jtbd.webp',
      'neck-pain-jtbd.webp',
      'struggle-none-jtbd.webp',
    ],
    options: [
      {
        value: 'sensitive_back',
        labelId: 'Onboarding.Struggle.Option1',
        labelDefaultMessage: 'Back pain',
        image: (
          <Image width={80} height={80} src="sensitive-back-jtbd.webp" alt="" />
        ),
      },
      {
        value: 'sensitive_knees',
        labelId: 'Onboarding.Struggle.Option2',
        labelDefaultMessage: 'Knee pain',
        image: (
          <Image
            width={80}
            height={80}
            src="sensitive-knees-jtbd.webp"
            alt=""
          />
        ),
      },
      {
        value: 'neck_pain',
        labelId: 'Onboarding.Struggle.Option3',
        labelDefaultMessage: 'Neck pain',
        image: (
          <Image width={80} height={80} src="neck-pain-jtbd.webp" alt="" />
        ),
      },
    ],
    skipOptionText: {
      value: 'none_of_the_above',
      labelId: 'Onboarding.Struggle.Option4',
      labelDefaultMessage: 'None of the above',
      image: (
        <Image width={80} height={80} src="struggle-none-jtbd.webp" alt="" />
      ),
    },
  },
  [QUIZ_STEPS_LIST.WORK_SCHEDULE]: {
    type: 'singleOption',
    gaEventName: 'onboarding_schedule_like_opened',
    stepName: QUIZ_STEPS_LIST.WORK_SCHEDULE,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What is your work schedule like?',
    titleId: 'Onboarding.Schedule.Title',
    imagesForPreload: [
      'emoji-sun-smile.webp',
      'emoji-moon-sun.webp',
      'emoji-sunglasses.webp',
      'emoji-old.webp',
      'emoji-stay-home.webp',
      'emoji-dont-work.webp',
    ],
    options: [
      {
        value: '9_to_5',
        labelId: 'Onboarding.Schedule.Option1',
        labelDefaultMessage: '9 to 5',
        image: (
          <Image src="emoji-sun-smile.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'night_shifts',
        labelId: 'Onboarding.Schedule.Option2',
        labelDefaultMessage: 'Night shifts',
        image: (
          <Image src="emoji-moon-sun.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'my_hours_are_flexible',
        labelId: 'Onboarding.Schedule.Option3',
        labelDefaultMessage: 'Flexible hours',
        image: <Image src="emoji-clock.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'i’m_retired',
        labelId: 'Onboarding.Schedule.Option4',
        labelDefaultMessage: 'I’m retired',
        image: <Image src="emoji-old.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'i_am_a_stay_at_home_mom',
        labelId: 'Onboarding.Schedule.Option5',
        labelDefaultMessage: 'I’m a stay-at-home mom',
        image: (
          <Image src="emoji-stay-home.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'i_dont_work',
        labelId: 'Onboarding.Schedule.Option6',
        labelDefaultMessage: "I don't work",
        image: (
          <Image src="emoji-dont-work.webp" width={40} height={40} alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.TYPICAL_DAY]: {
    type: 'singleOption',
    gaEventName: 'onboarding_describe_typical_day_opened',
    stepName: QUIZ_STEPS_LIST.TYPICAL_DAY,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: "What's your typical day like?",
    titleId: 'Onboarding.Day.Title',
    imagesForPreload: [
      'emoji-woman-computer.webp',
      'emoji-woman-cartwheeling.webp',
      'emoji-woman-running.webp',
      'emoji-mix.webp',
    ],
    options: [
      {
        value: 'sitting',
        labelId: 'Onboarding.Day.Option1',
        labelDefaultMessage: 'I spend most of the day sitting',
        image: (
          <Image
            src="emoji-woman-computer.webp"
            width={40}
            height={40}
            alt=""
          />
        ),
      },
      {
        value: 'active_breaks',
        labelId: 'Onboarding.Day.Option2',
        labelDefaultMessage: 'I take active breaks',
        image: (
          <Image
            src="emoji-woman-cartwheeling.webp"
            width={40}
            height={40}
            alt=""
          />
        ),
      },
      {
        value: 'on_my_feet',
        labelId: 'Onboarding.Day.Option3',
        labelDefaultMessage: "I'm on my feet all day long",
        image: (
          <Image src="emoji-woman-running.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'mix_between_all_of_the_above',
        labelId: 'Onboarding.Day.Option4',
        labelDefaultMessage: 'Mix between all of the above',
        image: <Image src="emoji-mix.webp" width={40} height={40} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.TYPICAL_DAY_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_describe_typical_day_opened',
    stepName: QUIZ_STEPS_LIST.TYPICAL_DAY_V2,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How do you usually spend your work day?',
    imagesForPreload: [
      'emoji-woman-computer.webp',
      'emoji-woman-cartwheeling.webp',
      'emoji-woman-running.webp',
      'emoji-mix.webp',
    ],
    options: [
      {
        value: 'sitting',
        label: 'Mostly behind my desk',
        image: (
          <Image
            src="emoji-woman-computer.webp"
            width={40}
            height={40}
            alt=""
          />
        ),
      },
      {
        value: 'active_breaks',
        labelId: 'Onboarding.Day.Option2',
        labelDefaultMessage: 'I take active breaks',
        image: (
          <Image
            src="emoji-woman-cartwheeling.webp"
            width={40}
            height={40}
            alt=""
          />
        ),
      },
      {
        value: 'on_my_feet',
        labelId: 'Onboarding.Day.Option3',
        labelDefaultMessage: "I'm on my feet all day long",
        image: (
          <Image src="emoji-woman-running.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'mix_between_all_of_the_above',
        labelId: 'Onboarding.Day.Option4',
        labelDefaultMessage: 'Mix between all of the above',
        image: <Image src="emoji-mix.webp" width={40} height={40} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.PREFER_WORKOUT]: {
    type: 'singleOption',
    gaEventName: 'onboarding_prefer_workout_opened',
    stepName: QUIZ_STEPS_LIST.PREFER_WORKOUT,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'When do you prefer {br} to work out?',
    titleId: 'Onboarding.PreferWorkout.Title',
    imagesForPreload: [
      'emoji-morning.webp',
      'emoji-afternoon.webp',
      'emoji-evening.webp',
      'emoji-ok.webp',
    ],
    options: [
      {
        value: 'morning',
        label: 'Morning',
        image: <Image src="emoji-morning.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'afternoon',
        label: 'Afternoon',
        image: (
          <Image src="emoji-afternoon.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'evening',
        label: 'Evening',
        image: <Image src="emoji-evening.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'at_different_times',
        label: 'At different times',
        image: <Image src="emoji-ok.webp" width={40} height={40} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.ENERGY_LEVEL]: {
    type: 'singleOption',
    gaEventName: 'onboarding_energy_level_opened',
    stepName: QUIZ_STEPS_LIST.ENERGY_LEVEL,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How’s your energy level during the day?',
    titleId: 'Onboarding.Energy.Title',
    imagesForPreload: ['energy-pilates.webp'],
    options: [
      {
        value: 'balanced_and_steady',
        labelId: 'Onboarding.Energy.Option1',
        labelDefaultMessage: 'Balanced and steady',
      },
      {
        value: 'not_that_stable',
        labelId: 'Onboarding.Energy.Option2',
        labelDefaultMessage: 'Not that stable',
      },
      {
        value: 'i_have_mood_swings',
        labelId: 'Onboarding.Energy.Option3',
        labelDefaultMessage: 'I have mood swings',
      },
      {
        value: 'tired_most_of_the_time',
        labelId: 'Onboarding.Energy.Option4',
        labelDefaultMessage: "I'm tired most of the time",
      },
    ],
  },
  [QUIZ_STEPS_LIST.ENERGY_LEVEL_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_energy_level_v2_opened',
    stepName: QUIZ_STEPS_LIST.ENERGY_LEVEL_V2,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How’s your energy level during the day?',
    titleId: 'Onboarding.Energy.Title',
    imagesForPreload: ['energy-level-v2-left.webp', 'energy-level-v2-bg.webp'],
    options: [
      {
        value: 'balanced_and_steady',
        labelId: 'Onboarding.Energy.Option1',
        labelDefaultMessage: 'Balanced and steady',
      },
      {
        value: 'not_that_stable',
        labelId: 'Onboarding.Energy.Option2',
        labelDefaultMessage: 'Not that stable',
      },
      {
        value: 'i_have_mood_swings',
        labelId: 'Onboarding.Energy.Option3',
        labelDefaultMessage: 'I have mood swings',
      },
      {
        value: 'tired_most_of_the_time',
        labelId: 'Onboarding.Energy.Option4',
        labelDefaultMessage: "I'm tired most of the time",
      },
    ],
  },
  [QUIZ_STEPS_LIST.USUALLY_SLEEP]: {
    type: 'singleOption',
    gaEventName: 'onboarding_sleep_opened',
    stepName: QUIZ_STEPS_LIST.USUALLY_SLEEP,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How much sleep do you usually get?',
    titleId: 'Onboarding.Sleep.Title',
    imagesForPreload: [
      'emoji-sleepy.webp',
      'emoji-expressionless.webp',
      'emoji-bed.webp',
      'emoji-zzz.webp',
    ],
    options: [
      {
        value: 'less_than_5_hours',
        labelId: 'Onboarding.Sleep.Option1',
        labelDefaultMessage: 'Minimal rest (less than 5 hours)',
        image: <Image src="emoji-sleepy.webp" width={40} height={40} alt="" />,
      },
      {
        value: '5_6_hours',
        labelId: 'Onboarding.Sleep.Option2',
        labelDefaultMessage: '1 get some shut-eye (5-6 hours)',
        image: (
          <Image
            src="emoji-expressionless.webp"
            width={40}
            height={40}
            alt=""
          />
        ),
      },
      {
        value: '7_8_hours',
        labelId: 'Onboarding.Sleep.Option3',
        labelDefaultMessage: 'I sleep long and well (7-8 hours)',
        image: <Image src="emoji-bed.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'more_than_8_hours',
        labelId: 'Onboarding.Sleep.Option4',
        labelDefaultMessage: '1 like to sleep in (more than 8 hours)',
        image: <Image src="emoji-zzz.webp" width={40} height={40} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.DAILY_DRINK]: {
    type: 'singleOption',
    gaEventName: 'onboarding_drink_water_opened',
    stepName: QUIZ_STEPS_LIST.DAILY_DRINK,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How much water do you drink daily?',
    titleId: 'Onboarding.Water.Title',
    imagesForPreload: ['water-pilates.webp'],
    options: [
      {
        value: 'only_coffee_or_tea',
        labelId: 'Onboarding.Water.Option1',
        labelDefaultMessage: 'I drink only coffee or tea',
      },
      {
        value: 'about_2_glasses',
        labelId: 'Onboarding.Water.Option2',
        labelDefaultMessage: 'About 2 glasses',
      },
      {
        value: '2_to_6_glasses',
        labelId: 'Onboarding.Water.Option3',
        labelDefaultMessage: '2 to 6 glasses',
      },
      {
        value: 'more_than_6_glasses',
        labelId: 'Onboarding.Water.Option4',
        labelDefaultMessage: 'More than 6 glasses',
      },
    ],
  },
  [QUIZ_STEPS_LIST.DAILY_DRINK_V2]: {
    type: 'singleOption',
    gaEventName: 'onboarding_drink_water_opened',
    stepName: QUIZ_STEPS_LIST.DAILY_DRINK_V2,
    section: 'Lifestyle',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'How much water do you drink daily?',
    titleId: 'Onboarding.Water.Title',
    imagesForPreload: ['water-pilates-left.webp', 'daily-drink-v2-bg.webp'],
    options: [
      {
        value: 'only_coffee_or_tea',
        labelId: 'Onboarding.Water.Option1',
        labelDefaultMessage: 'I drink only coffee or tea',
      },
      {
        value: 'about_2_glasses',
        labelId: 'Onboarding.Water.Option2',
        labelDefaultMessage: 'About 2 glasses',
      },
      {
        value: '2_to_6_glasses',
        labelId: 'Onboarding.Water.Option3',
        labelDefaultMessage: '2 to 6 glasses',
      },
      {
        value: 'more_than_6_glasses',
        labelId: 'Onboarding.Water.Option4',
        labelDefaultMessage: 'More than 6 glasses',
      },
    ],
  },
  [QUIZ_STEPS_LIST.BREAKFAST_TIME]: {
    type: 'singleOption',
    gaEventName: 'onboarding_usually_breakfast_opened',
    stepName: QUIZ_STEPS_LIST.BREAKFAST_TIME,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'When do you usually have breakfast?',
    titleId: 'Onboarding.Breakfast.Title',
    imagesForPreload: [
      'pankakes.webp',
      'eggs-meal.webp',
      'muslei.webp',
      'orange-juce.webp',
    ],
    options: [
      {
        value: 'between_6_and_8_am',
        labelId: 'Onboarding.Breakfast.Option1',
        labelDefaultMessage: 'Between 6 and 8 am',
        image: <Image src="pankakes.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_8_and_10_am',
        labelId: 'Onboarding.Breakfast.Option2',
        labelDefaultMessage: 'Between 8 and 10 am',
        image: <Image src="eggs-meal.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_10_am_and_noon',
        labelId: 'Onboarding.Breakfast.Option3',
        labelDefaultMessage: 'Between 10 am and noon',
        image: <Image src="muslei.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'i_usually_skip_breakfast',
        labelId: 'Onboarding.Breakfast.Option4',
        labelDefaultMessage: 'I usually skip breakfast',
        image: <Image src="orange-juce.webp" width={80} height={80} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.EATING_HABITS]: {
    type: 'singleOption',
    gaEventName: 'onboarding_eating_habits_opened',
    stepName: QUIZ_STEPS_LIST.EATING_HABITS,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How would you describe your eating habits?',
    imagesForPreload: [
      'emoji-clock.webp',
      'emoji-irregular.webp',
      'emoji-pop.webp',
      'emoji-sad-tear-drop.webp',
    ],
    options: [
      {
        value: 'regular_meals',
        label: 'Regular meals',
        image: <Image src="emoji-clock.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'irregular_meals',
        label: 'Irregular meals',
        image: (
          <Image src="emoji-irregular.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'frequent_snacking',
        label: 'Frequent snacking',
        image: <Image src="emoji-pop.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'emotional_eating',
        label: 'Emotional eating',
        image: (
          <Image src="emoji-sad-tear-drop.webp" width={40} height={40} alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.HOW_FAST_REACH_GOAL]: {
    type: 'singleOption',
    gaEventName: 'onboarding_how_fast_reach_opened',
    stepName: QUIZ_STEPS_LIST.HOW_FAST_REACH_GOAL,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    title: 'How fast do you want to reach your goal?',
    imagesForPreload: [
      'emoji-yoga-woman.webp',
      'emoji-sand-watch.webp',
      'emoji-rocket.webp',
    ],
    options: [
      {
        value: 'slow_and_steady_listening_to_my_body',
        label: 'Slow and steady, listening to my body',
        image: (
          <Image src="emoji-yoga-woman.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'moderate_tempo',
        label: 'Moderate tempo',
        image: (
          <Image src="emoji-sand-watch.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'as_quickly_as_possible',
        label: 'As quickly as possible',
        image: <Image src="emoji-rocket.webp" width={40} height={40} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.LUNCH_TIME]: {
    type: 'singleOption',
    gaEventName: 'onboarding_about_lunch_opened',
    stepName: QUIZ_STEPS_LIST.LUNCH_TIME,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What about lunch?',
    titleId: 'Onboarding.Lunch.Title',
    imagesForPreload: [
      'spaghetti.webp',
      'spaghetti.webp',
      'salad-meal.webp',
      'orange-juce.webp',
    ],
    options: [
      {
        value: 'between_10_am_and_noon',
        labelId: 'Onboarding.Lunch.Option1',
        labelDefaultMessage: 'Between 10 am and noon',
        image: <Image src="spaghetti.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_noon_and_2_pm',
        labelId: 'Onboarding.Lunch.Option2',
        labelDefaultMessage: 'Between noon and 2 pm',
        image: <Image src="fish-meal.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_2_and_4_pm',
        labelId: 'Onboarding.Lunch.Option3',
        labelDefaultMessage: 'Between 2 and 4 pm',
        image: <Image src="salad-meal.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'i_usually_skip_lunch',
        labelId: 'Onboarding.Lunch.Option4',
        labelDefaultMessage: 'I usually skip lunch',
        image: <Image src="orange-juce.webp" width={80} height={80} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.DINNER_TIME]: {
    type: 'singleOption',
    gaEventName: 'onboarding_time_have_dinner_opened',
    stepName: QUIZ_STEPS_LIST.DINNER_TIME,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What time do you have dinner?',
    titleId: 'Onboarding.Dinner.Title',
    imagesForPreload: [
      'chicken-leg.webp',
      'pizza.webp',
      'roll.webp',
      'orange-juce.webp',
    ],
    options: [
      {
        value: 'between_4_and_6_pm',
        labelId: 'Onboarding.Dinner.Option1',
        labelDefaultMessage: 'Between 4 and 6 pm',
        image: <Image src="chicken-leg.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_6_and_8_pm',
        labelId: 'Onboarding.Dinner.Option2',
        labelDefaultMessage: 'Between 6 and 8 pm',
        image: <Image src="pizza.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'between_8_and_10_pm',
        labelId: 'Onboarding.Dinner.Option3',
        labelDefaultMessage: 'Between 8 and 10 pm',
        image: <Image src="roll.webp" width={80} height={80} alt="" />,
      },
      {
        value: 'i_usually_skip_dinner',
        labelId: 'Onboarding.Dinner.Option4',
        labelDefaultMessage: 'I usually skip dinner',
        image: <Image src="orange-juce.webp" width={80} height={80} alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.ANY_DIET]: {
    type: 'singleOption',
    gaEventName: 'onboarding_kind_of_diet_opened',
    stepName: QUIZ_STEPS_LIST.ANY_DIET,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'Are you following any kind of diet?',
    titleId: 'Onboarding.Diet.Title',
    imagesForPreload: [
      'fried-eggs.webp',
      'pilates-beef.webp',
      'pilates-plant.webp',
      'pilates-broccoli.webp',
      'pilates-avocado.webp',
      'pilates-sushi.webp',
      'pilates-no-milk.webp',
      'pilates-no-donut.webp',
      'pilates-pork.webp',
      'pilates-fish.webp',
    ],
    options: [
      {
        value: 'traditional',
        labelId: 'Onboarding.Diet.Option1',
        labelDescriptionId: 'Onboarding.Diet.Option1.Text',
        image: <Image width={40} height={40} src="fried-eggs.webp" alt="" />,
      },
      {
        value: 'keto',
        labelId: 'Onboarding.Diet.Option2',
        labelDescriptionId: 'Onboarding.Diet.Option2.Text',
        image: <Image width={40} height={40} src="pilates-beef.webp" alt="" />,
      },
      {
        value: 'vegetarian',
        labelId: 'Onboarding.Diet.Option3',
        labelDescriptionId: 'Onboarding.Diet.Option3.Text',
        image: <Image width={40} height={40} src="pilates-plant.webp" alt="" />,
      },
      {
        value: 'vegan',
        labelId: 'Onboarding.Diet.Option4',
        labelDescriptionId: 'Onboarding.Diet.Option4.Text',
        image: (
          <Image width={40} height={40} src="pilates-broccoli.webp" alt="" />
        ),
      },
      {
        value: 'keto_vegan',
        labelId: 'Onboarding.Diet.Option5',
        labelDescriptionId: 'Onboarding.Diet.Option5.Text',
        image: (
          <Image width={40} height={40} src="pilates-avocado.webp" alt="" />
        ),
      },
      {
        value: 'pescetarian',
        labelId: 'Onboarding.Diet.Option6',
        labelDescriptionId: 'Onboarding.Diet.Option6.Text',
        image: <Image width={40} height={40} src="pilates-sushi.webp" alt="" />,
      },
      {
        value: 'lactose_free',
        labelId: 'Onboarding.Diet.Option7',
        labelDescriptionId: 'Onboarding.Diet.Option7.Text',
        image: (
          <Image width={48} height={48} src="pilates-no-milk.webp" alt="" />
        ),
      },
      {
        value: 'gluten_free',
        labelId: 'Onboarding.Diet.Option8',
        labelDescriptionId: 'Onboarding.Diet.Option8.Text',
        image: (
          <Image width={48} height={48} src="pilates-no-donut.webp" alt="" />
        ),
      },
      {
        value: 'paleo',
        labelId: 'Onboarding.Diet.Option9',
        labelDescriptionId: 'Onboarding.Diet.Option9.Text',
        image: <Image width={40} height={40} src="pilates-pork.webp" alt="" />,
      },
      {
        value: 'mediterranean',
        labelId: 'Onboarding.Diet.Option10',
        labelDescriptionId: 'Onboarding.Diet.Option10.Text',
        image: <Image width={40} height={40} src="pilates-fish.webp" alt="" />,
      },
    ],
  },
  [QUIZ_STEPS_LIST.BAD_HABITS]: {
    type: 'multipleOptions',
    gaEventName: 'onboarding_bad_habits_opened',
    stepName: QUIZ_STEPS_LIST.BAD_HABITS,
    section: 'Nutrition',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'Do you have any of the following bad habits?',
    titleId: 'Onboarding.Habits.Title',
    defaultSubtitleMessage: 'I eat late at night',
    subtitleId: 'Onboarding.Habits.Subtitle',
    imagesForPreload: [
      'pilates-fridge.webp',
      'pilates-sugar.webp',
      'pilates-cola.webp',
      'pilates-salt.webp',
    ],
    options: [
      {
        value: 'eat_late_at_night',
        labelId: 'Onboarding.Habits.Option1',
        labelDefaultMessage: "I can't quit sugar",
        image: (
          <Image width={80} height={80} src="pilates-fridge.webp" alt="" />
        ),
      },
      {
        value: 'can_t_quit_sugar',
        labelId: 'Onboarding.Habits.Option2',
        labelDefaultMessage: "I can't live without soda 6 and 8 pm",
        image: <Image width={80} height={80} src="pilates-sugar.webp" alt="" />,
      },
      {
        value: 'can_t_live_without_soda',
        labelId: 'Onboarding.Habits.Option3',
        labelDefaultMessage: 'Between 8 and 10 pm',
        image: <Image width={80} height={80} src="pilates-cola.webp" alt="" />,
      },
      {
        value: 'eat_too_much_salt',
        labelId: 'Onboarding.Habits.Option4',
        labelDefaultMessage: 'I eat too much salt',
        image: <Image width={80} height={80} src="pilates-salt.webp" alt="" />,
      },
    ],
    skipButtonId: 'Onboarding.Habits.Button.None',
  },
  [QUIZ_STEPS_LIST.LIFE_EVENTS]: {
    type: 'multipleOptions',
    gaEventName: 'onboarding_events_weight_gain_opened',
    stepName: QUIZ_STEPS_LIST.LIFE_EVENTS,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage:
      'Did any events cause weight gain in the last few years?',
    titleId: 'Onboarding.Events.Title',
    defaultSubtitleMessage: 'Choose all that apply',
    subtitleId: 'Onboarding.Events.Subtitle',
    imagesForPreload: [
      'emoji-wedding.webp',
      'emoji-sad-tear-drop.webp',
      'emoji-money.webp',
      'emoji-hospital.webp',
      'emoji-sand-watch.webp',
      'emoji-stethoscope.webp',
    ],
    options: [
      {
        value: 'marriage_or_relationship',
        labelId: 'Onboarding.Events.Option1',
        labelDefaultMessage: 'Marriage or relationship',
        image: <Image width={40} height={40} src="emoji-wedding.webp" alt="" />,
      },
      {
        value: 'busy_work_or_family_life',
        labelId: 'Onboarding.Events.Option2',
        labelDefaultMessage: 'Busy work or family life',
        image: (
          <Image width={40} height={40} src="emoji-rolling-eyes.webp" alt="" />
        ),
      },
      {
        value: 'financial_struggles',
        labelId: 'Onboarding.Events.Option3',
        labelDefaultMessage: 'Financial struggles',
        image: <Image width={40} height={40} src="emoji-money.webp" alt="" />,
      },
      {
        value: 'injury_or_disability',
        labelId: 'Onboarding.Events.Option4',
        labelDefaultMessage: 'Injury or disability',
        image: (
          <Image width={40} height={40} src="emoji-hospital.webp" alt="" />
        ),
      },
      {
        value: 'stress_or_mental_health_issue',
        labelId: 'Onboarding.Events.Option5',
        labelDefaultMessage: 'Stress or mental health issue',
        image: (
          <Image width={40} height={40} src="emoji-sad-tear-drop.webp" alt="" />
        ),
      },
      {
        value: 'slower_metabolism',
        labelId: 'Onboarding.Events.Option6',
        labelDefaultMessage: 'Slower metabolism due to aging',
        image: (
          <Image width={40} height={40} src="emoji-sand-watch.webp" alt="" />
        ),
      },
      {
        value: 'post_treatment_complications',
        labelId: 'Onboarding.Events.Option7',
        labelDefaultMessage: 'Post-treatment complications',
        image: (
          <Image width={40} height={40} src="emoji-stethoscope.webp" alt="" />
        ),
      },
    ],
    skipButtonId: 'Onboarding.Events.Button.None',
  },
  [QUIZ_STEPS_LIST.HEIGHT]: {
    type: 'other',
    gaEventName: 'onboarding_height_opened',
    stepName: QUIZ_STEPS_LIST.HEIGHT,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What’s your height?',
    titleId: 'Onboarding.Height.Title',
    subtitleId: 'Onboarding.Height.Subtitle',
    defaultSubtitleMessage:
      'We will edit your personal program to fit the desired timeline',
  },
  [QUIZ_STEPS_LIST.CURRENT_WEIGHT]: {
    type: 'other',
    gaEventName: 'onboarding_current_weight_opened',
    stepName: QUIZ_STEPS_LIST.CURRENT_WEIGHT,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    titleId: 'Onboarding.Weight.Title',
    subtitleId: 'Onboarding.Weight.Subtitle',
    defaultTitleMessage: 'What’s your current weight?',
    defaultSubtitleMessage:
      'We will edit your personal program to fit the desired timeline',
  },
  [QUIZ_STEPS_LIST.TARGET_WEIGHT]: {
    type: 'other',
    gaEventName: 'onboarding_target_weight_opened',
    stepName: QUIZ_STEPS_LIST.TARGET_WEIGHT,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'Got it! And what’s your target weight?',
    titleId: 'Onboarding.TargetWeight.Title',
    subtitleId: 'Onboarding.TargetWeight.Subtitle',
    defaultSubtitleMessage:
      'We will edit your personal program to fit the desired timeline',
  },
  [QUIZ_STEPS_LIST.AGE]: {
    type: 'other',
    gaEventName: 'onboarding_age_opened',
    stepName: QUIZ_STEPS_LIST.AGE,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What is your age?',
    titleId: 'Onboarding.Age.Title',
    imagesForPreload: ['whatever-woman-emoji.webp'],
  },
  [QUIZ_STEPS_LIST.NAME]: {
    type: 'other',
    gaEventName: 'onboarding_name_opened',
    stepName: QUIZ_STEPS_LIST.NAME,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    defaultTitleMessage: 'What is your name?',
    titleId: 'Onboarding.Name.Title',
  },
  [QUIZ_STEPS_LIST.WELLNESS_PROFILE]: {
    type: 'other',
    gaEventName: 'onboarding_wellness_profile_opened',
    stepName: QUIZ_STEPS_LIST.WELLNESS_PROFILE,
    section: 'About You',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    defaultTitleMessage: 'Here’s your wellness profile',
    titleId: 'Onboarding.Profile.Title',
  },
  [QUIZ_STEPS_LIST.WHEN_EVENT]: {
    type: 'other',
    gaEventName: 'onboarding_when_event_opened',
    stepName: QUIZ_STEPS_LIST.WHEN_EVENT,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    titleId: 'Onboarding.WhenEvent.Title',
    subtitleId: 'Onboarding.WhenEvent.Subtitle',
    defaultTitleMessage: 'When’s your event?',
    defaultSubtitleMessage:
      'We will edit your personal program to fit the desired timeline',
  },
  [QUIZ_STEPS_LIST.EVENT_COMING]: {
    type: 'singleOption',
    gaEventName: 'onboarding_important_events_opened',
    stepName: QUIZ_STEPS_LIST.EVENT_COMING,
    section: 'About You',
    showOnboardingHeader: true,
    showProgressBar: true,
    showStepHeader: true,
    titleId: 'Onboarding.Important.Title',
    defaultTitleMessage: 'Do you have an important event coming up?',
    subtitleId: 'Onboarding.Important.Subtitle',
    defaultSubtitleMessage:
      'Having something to look forward to might be also a great motivator for reaching your goal',
    imagesForPreload: [
      'emoji-wedding.webp',
      'emoji-vacation.webp',
      'emoji-tada.webp',
      'emoji-man-run.webp',
      'emoji-face-sun.webp',
      'emoji-cake.webp',
      'emoji-sunglasses.webp',
      'emoji-woman-no.webp',
    ],
    options: [
      {
        value: 'Wedding',
        labelId: 'Onboarding.Important.Option1',
        labelDefaultMessage: 'Wedding',
        image: <Image src="emoji-wedding.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'Vacation',
        labelId: 'Onboarding.Important.Option2',
        labelDefaultMessage: 'Vacation',
        image: (
          <Image src="emoji-face-sun.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'Holiday',
        labelId: 'Onboarding.Important.Option3',
        labelDefaultMessage: 'Holiday',
        image: (
          <Image src="emoji-vacation.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'Sports competition',
        labelId: 'Onboarding.Important.Option4',
        labelDefaultMessage: 'Sports competition',
        image: <Image src="emoji-man-run.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'Reunion',
        labelId: 'Onboarding.Important.Option5',
        labelDefaultMessage: 'Reunion',
        image: <Image src="emoji-tada.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'Birthday',
        labelId: 'Onboarding.Important.Option6',
        labelDefaultMessage: 'Birthday',
        image: <Image src="emoji-cake.webp" width={40} height={40} alt="" />,
      },
      {
        value: 'Other',
        labelId: 'Onboarding.Important.Option6:30',
        labelDefaultMessage: 'Other',
        image: (
          <Image src="emoji-sunglasses.webp" width={40} height={40} alt="" />
        ),
      },
      {
        value: 'no_special_events',
        labelId: 'Onboarding.Important.Option7',
        labelDefaultMessage: 'No special events any time soon',
        image: (
          <Image src="emoji-woman-no.webp" width={40} height={40} alt="" />
        ),
      },
    ],
  },
  [QUIZ_STEPS_LIST.LAST_PLAN_LONG]: {
    type: 'other',
    gaEventName: 'onboarding_chart_opened_DEV_867',
    stepName: QUIZ_STEPS_LIST.LAST_PLAN_LONG,
    section: 'Final Screens',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title: '',
  },

  [QUIZ_STEPS_LIST.MILLION_USERS]: {
    type: 'other',
    gaEventName: 'onboarding_final_summary_opened',
    stepName: QUIZ_STEPS_LIST.MILLION_USERS,
    section: 'Final Screens',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Loader.Title',
    defaultTitleMessage: '15 million users love our programs',
  },
  [QUIZ_STEPS_LIST.ENTER_EMAIL]: {
    type: 'other',
    gaEventName: 'onboarding_email_opened',
    stepName: QUIZ_STEPS_LIST.ENTER_EMAIL,
    section: 'Final Screens',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    defaultTitleMessage: 'Enter your email to get your',
    titleId: 'Onboarding.Email.Title',
    subtitleId: 'Onboarding.Email.Title.Mark',
    defaultSubtitleMessage: 'Personal Pilates {br} Workout Plan',
  },
  [QUIZ_STEPS_LIST.PLAN_READY_CHART_LONG]: {
    type: 'other',
    gaEventName: 'onboarding_plan_ready_chart_opened_DEV_867',
    stepName: QUIZ_STEPS_LIST.PLAN_READY_CHART_LONG,
    section: 'Final Screens',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title: '',
  },

  [QUIZ_STEPS_LIST.MADE_FOR_YOU]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'just_for_you',
    stepName: QUIZ_STEPS_LIST.MADE_FOR_YOU,
    section: 'Your Goal',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Made',
    defaultTitleMessage: 'Made just {br}',
    titleIdMark: 'Onboarding.Breaks.Made.Br',
    defaultTitleMessageMark: 'for you',
    subtitleId: 'Onboarding.Breaks.Made.Text',
    defaultSubtitleMessage:
      'Our plans are made to fit unique needs and goals because we believe weight loss can be comfortable',
    image: (
      <Image
        src={`cutaway-made-for-you${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    nextButtonText: 'Start matching',
    nextButtonTextId: 'Onboarding.Breaks.Made.Button',
    imagesForPreload: [`cutaway-made-for-you${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.COMMIT_TO]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'commit_yourself',
    stepName: QUIZ_STEPS_LIST.COMMIT_TO,
    section: 'Current Level',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Commit',
    defaultTitleMessage: 'Commit',
    titleIdMark: 'Onboarding.Breaks.Commit.Br',
    defaultTitleMessageMark: 'to yourself',
    subtitleId: 'Onboarding.Breaks.Commit.Text',
    defaultSubtitleMessage:
      'Be fit, confident, and unstoppable. You deserve the best, so choose your health today',
    image: (
      <Image
        src={`cutaway-commit-to${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    nextButtonText: 'Great results!',
    nextButtonTextId: 'Onboarding.Breaks.Commit.Button',
    imagesForPreload: [`cutaway-commit-to${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.GOT_GREAT_NEWS]: {
    type: 'other',
    gaEventName: 'onboarding_got_great_news_opened',
    stepName: QUIZ_STEPS_LIST.GOT_GREAT_NEWS,
    section: 'Current Level',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title: "We've got great news!",
    nextButtonText: 'Great! Let’s do it!',
    imagesForPreload: ['got-great-news.webp'],
  },
  [QUIZ_STEPS_LIST.PROVEN_EFFECTIVENESS]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'proven_effectiveness',
    stepName: QUIZ_STEPS_LIST.PROVEN_EFFECTIVENESS,
    section: 'Current Level',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Proven',
    defaultTitleMessage: 'Proven {br}',
    titleIdMark: 'Onboarding.Breaks.Proven.Br',
    defaultTitleMessageMark: 'effectiveness',
    subtitleId: 'Onboarding.Breaks.Proven.Text',
    defaultSubtitleMessage:
      'Follow your personal plan for effective, consistent and sustainable weight loss',
    image: (
      <Image
        src={`cutaway-proven-effectiveness${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    nextButtonText: 'Got it!',
    nextButtonTextId: 'Onboarding.Breaks.Proven.Button',
    imagesForPreload: [`cutaway-proven-effectiveness${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.TRUST_EXPERTS]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'trust_experts',
    stepName: QUIZ_STEPS_LIST.TRUST_EXPERTS,
    section: 'Lifestyle',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Trust',
    defaultTitleMessage: 'Trust {br}',
    titleIdMark: 'Onboarding.Breaks.Trust.Br',
    defaultTitleMessageMark: 'our experts',
    subtitleId: 'Onboarding.Breaks.Trust.Text',
    defaultSubtitleMessage:
      'Crafted by professionals, backed by latest research, and proven, tested, and loved by millions',
    image: (
      <Image
        src={`cutaway-trust-experts${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    nextButtonText: 'Sounds good!',
    nextButtonTextId: 'Onboarding.Breaks.Trust.Button',
    imagesForPreload: [`cutaway-trust-experts${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.BEST_NO_DIET]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'best_no_diet',
    stepName: QUIZ_STEPS_LIST.BEST_NO_DIET,
    section: 'About You',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Best',
    defaultTitleMessage: 'Best {br}',
    titleIdMark: 'Onboarding.Breaks.Best.Br',
    defaultTitleMessageMark: 'no-diet solution',
    subtitleId: 'Onboarding.Breaks.Best.Text',
    defaultSubtitleMessage:
      'We focus on effective workouts to maximize weight loss while excluding grueling diets',
    image: <Image src="cutaway-best-no-diet.webp" alt="" fill={true} />,
    nextButtonText: 'Get the solution',
    nextButtonTextId: 'Onboarding.Breaks.Best.Button',
    imagesForPreload: ['cutaway-best-no-diet.webp'],
  },
  [QUIZ_STEPS_LIST.ADAPT_PLAN]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'adapt_plan',
    stepName: QUIZ_STEPS_LIST.ADAPT_PLAN,
    section: 'Lifestyle',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title:
      "Great! We've added the most effective and safe exercises to your plan.",
    subtitle: "Now, let's adapt it to fit your lifestyle.",
    image: <Image src="adapt-plan.webp" alt="" fill={true} />,
    nextButton: 'Let’s adapt it!',
    imagesForPreload: ['adapt-plan.webp'],
  },
  [QUIZ_STEPS_LIST.NUTRITION_BREAK]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'nutrition_break',
    stepName: QUIZ_STEPS_LIST.NUTRITION_BREAK,
    section: 'Lifestyle',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title:
      "Almost done! We've adjusted the plan so you can easily fit it into your lifestyle!",
    subtitle:
      "All that's left is to add a nutrition program to your fitness plan.",
    image: <Image src="nutrition-break.webp" alt="" fill={true} />,
    nextButton: 'Let’s do it!',
    imagesForPreload: ['nutrition-break.webp'],
  },
  [QUIZ_STEPS_LIST.ABOUT_YOU_BREAKS]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'about_you_breaks',
    stepName: QUIZ_STEPS_LIST.ABOUT_YOU_BREAKS,
    section: 'About You',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    title: 'Final step!',
    subtitle:
      'Let’s base your plan on a scientific approach and some calculations to ensure you reach your goal safely and effectively.',
    image: <Image src="about-you-breaks.webp" priority alt="" fill={true} />,
    nextButton: 'Let’s finish this!',
    imagesForPreload: ['about-you-breaks.webp'],
  },
  [QUIZ_STEPS_LIST.YOUR_BEST]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'your_best',
    stepName: QUIZ_STEPS_LIST.YOUR_BEST,
    section: 'About You',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    titleId: 'Onboarding.Breaks.Become',
    defaultTitleMessage: 'Become {br}',
    titleIdMark: 'Onboarding.Breaks.Become.Br',
    defaultTitleMessageMark: 'your best self',
    subtitleId: 'Onboarding.Breaks.Become.Text',
    defaultSubtitleMessage:
      'Believe in yourself, just like we do. We aim for our personalized plans to fit your needs and goals',
    image: (
      <Image
        src={`cutaway-your-best${getLocaleImage()}.webp`}
        alt=""
        fill={true}
      />
    ),
    nextButtonText: 'Let’s do it!',
    nextButtonTextId: 'Onboarding.Breaks.Become.Button',
    imagesForPreload: [`cutaway-your-best${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.SLEEP_HYGIENE]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'sleep_hygiene',
    stepName: QUIZ_STEPS_LIST.SLEEP_HYGIENE,
    section: 'Lifestyle',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    imagesForPreload: [`cutaway-sleep-hygiene${getLocaleImage()}.webp`],
  },
  [QUIZ_STEPS_LIST.YOUR_COMMUNITY]: {
    type: 'other',
    gaEventName: 'onboarding_opened',
    gaEventOptions: 'your_community',
    stepName: QUIZ_STEPS_LIST.YOUR_COMMUNITY,
    section: 'Final Screens',
    showOnboardingHeader: false,
    showProgressBar: false,
    showStepHeader: false,
    imagesForPreload: [`cutaway-your-community${getLocaleImage()}.webp`],
  },
};

export const YOUR_GOAL_PILATES_SECTION = [
  QUIZ_STEPS_LIST.WELCOME,
  QUIZ_STEPS_LIST.CHOOSE_YOUTH,
  QUIZ_STEPS_LIST.GOAL_PILATES,
  QUIZ_STEPS_LIST.PHYSICAL_BUILD,
  QUIZ_STEPS_LIST.DREAM_BODY_PILATES,
  QUIZ_STEPS_LIST.TARGET_ZONES,
];

export const YOUR_GOAL_PILATES_SECTION_BREAKS = [
  QUIZ_STEPS_LIST.WELCOME,
  QUIZ_STEPS_LIST.MADE_FOR_YOU,
  QUIZ_STEPS_LIST.GOAL_PILATES,
  QUIZ_STEPS_LIST.PHYSICAL_BUILD,
  QUIZ_STEPS_LIST.DREAM_BODY_PILATES,
  QUIZ_STEPS_LIST.TARGET_ZONES,
];

export const YOUR_GOAL_PILATES_SECTION_JTBD = [
  QUIZ_STEPS_LIST.WELCOME_JTBD,
  QUIZ_STEPS_LIST.GOAL_JTBD,
  QUIZ_STEPS_LIST.PHYSICAL_BUILD_V2,
  QUIZ_STEPS_LIST.DREAM_BODY_PILATES,
  QUIZ_STEPS_LIST.TARGET_ZONES,
];

export const CURRENT_LEVEL = [
  QUIZ_STEPS_LIST.FITNESS_EXPERIENCE,
  QUIZ_STEPS_LIST.BEST_SHAPE,
  QUIZ_STEPS_LIST.TRIED_PILATES,
  QUIZ_STEPS_LIST.FLEXIBLE_PILATES,
  QUIZ_STEPS_LIST.EXERCISE,
  QUIZ_STEPS_LIST.OUT_OF_BREATH,
  QUIZ_STEPS_LIST.WALKS,
  QUIZ_STEPS_LIST.STRUGGLE_WITH,
];

export const CURRENT_LEVEL_BREAKS = [
  QUIZ_STEPS_LIST.COMMIT_TO,
  QUIZ_STEPS_LIST.FITNESS_EXPERIENCE,
  QUIZ_STEPS_LIST.BEST_SHAPE,
  QUIZ_STEPS_LIST.TRIED_PILATES,
  QUIZ_STEPS_LIST.PROVEN_EFFECTIVENESS,
  QUIZ_STEPS_LIST.FLEXIBLE_PILATES,
  QUIZ_STEPS_LIST.EXERCISE,
  QUIZ_STEPS_LIST.OUT_OF_BREATH,
  QUIZ_STEPS_LIST.WALKS,
  QUIZ_STEPS_LIST.STRUGGLE_WITH,
];

export const CURRENT_LEVEL_JTBD = [
  QUIZ_STEPS_LIST.STAY_FOCUSED,
  QUIZ_STEPS_LIST.GOT_GREAT_NEWS,
  QUIZ_STEPS_LIST.WEIGHT_DYNAMIC,
  QUIZ_STEPS_LIST.BEST_SHAPE_V2,
  QUIZ_STEPS_LIST.FLEXIBLE_PILATES_V2,
  QUIZ_STEPS_LIST.EXERCISE_V2,
  QUIZ_STEPS_LIST.OUT_OF_BREATH_V2,
  QUIZ_STEPS_LIST.WALKS_V2,
  QUIZ_STEPS_LIST.STRUGGLE_WITH_V2,
  QUIZ_STEPS_LIST.EXERCISES_AVOID,
];

export const LIFESTYLE_SECTION = [
  QUIZ_STEPS_LIST.WORK_SCHEDULE,
  QUIZ_STEPS_LIST.TYPICAL_DAY,
  QUIZ_STEPS_LIST.ENERGY_LEVEL,
  QUIZ_STEPS_LIST.USUALLY_SLEEP,
  QUIZ_STEPS_LIST.DAILY_DRINK,
];

export const LIFESTYLE_SECTION_BREAKS = [
  QUIZ_STEPS_LIST.TRUST_EXPERTS,
  QUIZ_STEPS_LIST.WORK_SCHEDULE,
  QUIZ_STEPS_LIST.TYPICAL_DAY,
  QUIZ_STEPS_LIST.ENERGY_LEVEL,
  QUIZ_STEPS_LIST.USUALLY_SLEEP,
  QUIZ_STEPS_LIST.SLEEP_HYGIENE,
  QUIZ_STEPS_LIST.DAILY_DRINK,
];

export const LIFESTYLE_SECTION_JTBD = [
  QUIZ_STEPS_LIST.ADAPT_PLAN,
  QUIZ_STEPS_LIST.WORK_SCHEDULE,
  QUIZ_STEPS_LIST.TYPICAL_DAY_V2,
  QUIZ_STEPS_LIST.PREFER_WORKOUT,
  QUIZ_STEPS_LIST.ENERGY_LEVEL_V2,
  QUIZ_STEPS_LIST.USUALLY_SLEEP,
  QUIZ_STEPS_LIST.DAILY_DRINK_V2,
];

export const NUTRITION_SECTION = [
  QUIZ_STEPS_LIST.BREAKFAST_TIME,
  QUIZ_STEPS_LIST.LUNCH_TIME,
  QUIZ_STEPS_LIST.DINNER_TIME,
  QUIZ_STEPS_LIST.ANY_DIET,
  QUIZ_STEPS_LIST.BAD_HABITS,
];

export const NUTRITION_SECTION_JTBD = [
  QUIZ_STEPS_LIST.NUTRITION_BREAK,
  QUIZ_STEPS_LIST.EATING_HABITS,
  QUIZ_STEPS_LIST.BREAKFAST_TIME,
  QUIZ_STEPS_LIST.LUNCH_TIME,
  QUIZ_STEPS_LIST.DINNER_TIME,
  QUIZ_STEPS_LIST.ANY_DIET,
  QUIZ_STEPS_LIST.BAD_HABITS,
];

export const ABOUT_YOU_SECTION = [
  QUIZ_STEPS_LIST.LIFE_EVENTS,
  QUIZ_STEPS_LIST.HEIGHT,
  QUIZ_STEPS_LIST.CURRENT_WEIGHT,
  QUIZ_STEPS_LIST.TARGET_WEIGHT,
  QUIZ_STEPS_LIST.AGE,
  QUIZ_STEPS_LIST.NAME,
  QUIZ_STEPS_LIST.WELLNESS_PROFILE,
  QUIZ_STEPS_LIST.EVENT_COMING,
  QUIZ_STEPS_LIST.WHEN_EVENT,
];

export const ABOUT_YOU_SECTION_BREAKS = [
  QUIZ_STEPS_LIST.LIFE_EVENTS,
  QUIZ_STEPS_LIST.BEST_NO_DIET,
  QUIZ_STEPS_LIST.HEIGHT,
  QUIZ_STEPS_LIST.CURRENT_WEIGHT,
  QUIZ_STEPS_LIST.TARGET_WEIGHT,
  QUIZ_STEPS_LIST.AGE,
  QUIZ_STEPS_LIST.NAME,
  QUIZ_STEPS_LIST.WELLNESS_PROFILE,
  QUIZ_STEPS_LIST.YOUR_BEST,
  QUIZ_STEPS_LIST.EVENT_COMING,
  QUIZ_STEPS_LIST.WHEN_EVENT,
];

export const ABOUT_YOU_SECTION_JTBD = [
  QUIZ_STEPS_LIST.ABOUT_YOU_BREAKS,
  QUIZ_STEPS_LIST.HOW_FAST_REACH_GOAL,
  QUIZ_STEPS_LIST.HEIGHT,
  QUIZ_STEPS_LIST.CURRENT_WEIGHT,
  QUIZ_STEPS_LIST.TARGET_WEIGHT,
  QUIZ_STEPS_LIST.AGE,
  QUIZ_STEPS_LIST.NAME,
  QUIZ_STEPS_LIST.WELLNESS_PROFILE,
  QUIZ_STEPS_LIST.EVENT_COMING,
  QUIZ_STEPS_LIST.WHEN_EVENT,
];

export const FINAL_SCREENS = [
  QUIZ_STEPS_LIST.LAST_PLAN_LONG,
  QUIZ_STEPS_LIST.MILLION_USERS,
  QUIZ_STEPS_LIST.PLAN_READY_CHART_LONG,
  QUIZ_STEPS_LIST.ENTER_EMAIL,
];

export const FINAL_SCREENS_BREAKS = [
  QUIZ_STEPS_LIST.LAST_PLAN_LONG,
  QUIZ_STEPS_LIST.YOUR_COMMUNITY,
  QUIZ_STEPS_LIST.MILLION_USERS,
  QUIZ_STEPS_LIST.ENTER_EMAIL,
  QUIZ_STEPS_LIST.PLAN_READY_CHART_LONG,
];

export const FINAL_SCREENS_JTBD = [
  QUIZ_STEPS_LIST.LAST_PLAN_LONG,
  QUIZ_STEPS_LIST.MILLION_USERS,
  QUIZ_STEPS_LIST.ENTER_EMAIL,
];

export const PILATES_PATH = [
  YOUR_GOAL_PILATES_SECTION,
  CURRENT_LEVEL,
  LIFESTYLE_SECTION,
  NUTRITION_SECTION,
  ABOUT_YOU_SECTION,
  FINAL_SCREENS,
];

export const PILATES_BREAKS_PATH = [
  YOUR_GOAL_PILATES_SECTION_BREAKS,
  CURRENT_LEVEL_BREAKS,
  LIFESTYLE_SECTION_BREAKS,
  NUTRITION_SECTION,
  ABOUT_YOU_SECTION_BREAKS,
  FINAL_SCREENS_BREAKS,
];

export const JTBD_PATH = [
  YOUR_GOAL_PILATES_SECTION_JTBD,
  CURRENT_LEVEL_JTBD,
  LIFESTYLE_SECTION_JTBD,
  NUTRITION_SECTION_JTBD,
  ABOUT_YOU_SECTION_JTBD,
  FINAL_SCREENS_JTBD,
];
