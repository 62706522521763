import { TpRadioGroupProps } from '@mentalgrowth/ui-kit-web';
import { ChangeEvent } from 'react';

import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import {
  MultipleOptionsSelectQuizStepOption,
  SingleOptionSelectQuizStepOption,
} from '@features/Quiz/PilatesOnboarding/types';
import { TpMessageKeys, useIntl } from '@features/intl';

export type Option =
  | SingleOptionSelectQuizStepOption
  | MultipleOptionsSelectQuizStepOption;

type TpUiKitRadioGroupComponentProps = {
  name: string;
  value: string;
  options: Option[];
  handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
  goToNextStep: () => void;
  stepName: string;
  variant?: TpRadioGroupProps['variant'];
  startIconClassName?: string;
  labelClassName?: string;
};

export const UiKitRadioGroupComponent = (
  props: TpUiKitRadioGroupComponentProps,
) => {
  const {
    name,
    value,
    options,
    handleRadioChange,
    goToNextStep,
    stepName,
    variant,
    labelClassName,
    startIconClassName,
  } = props;

  const intl = useIntl();

  return (
    <UiKitRadioGroup
      className="options-container"
      name={name}
      defaultValue={value}
      onChange={handleRadioChange}
      variant={variant}
      onClick={goToNextStep}
    >
      {options.map(
        ({
          value,
          label,
          image,
          labelId,
          labelDefaultMessage,
          ...otherProps
        }) => (
          <UiKitRadioOption
            key={`${stepName}_${value}`}
            label={
              labelId
                ? intl.formatMessage(
                    {
                      id: labelId as TpMessageKeys,
                      defaultMessage: labelDefaultMessage,
                    },
                    {
                      br: <br />,
                    },
                  )
                : label
            }
            value={value}
            startIcon={image}
            startIconClassName={startIconClassName}
            labelClassName={labelClassName}
            {...otherProps}
          />
        ),
      )}
    </UiKitRadioGroup>
  );
};
