import classNames from 'classnames';
import Head from 'next/head';
import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';

import { TpButtonVariant } from 'src/components/common/button/button';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { IMAGE_SOURCE } from 'src/utils/preload-iamges';

import { TpColoredBackgroundList } from '@features/Quiz/types';
import { FormattedMessage } from '@features/intl';

import styles from './StepContainer.module.scss';

const backgroundMapping = {
  RED: {
    bodyClassName: styles.redBodyBackground,
    themeColor: '#ffc9c6',
  },
  BLUE: {
    bodyClassName: styles.blueBodyBackground,
    themeColor: '#7a80b5',
  },
  YELLOW: {
    bodyClassName: styles.yellowBodyBackground,
    themeColor: '#f8ae01',
  },
  CYAN: {
    bodyClassName: styles.cyanBodyBackground,
    themeColor: '#357196',
  },
  PURPLE: {
    bodyClassName: styles.purpleBodyBackground,
    themeColor: '#A69EC5',
  },
  MAGENTA: {
    bodyClassName: styles.magentaBodyBackground,
    themeColor: '#A34F7C',
  },
  LIGHT_BLUE: {
    bodyClassName: styles.lightBlueBodyBackground,
    themeColor: '#EBEBFF',
  },
  GREEN: {
    bodyClassName: styles.greenBodyBackground,
    themeColor: '#519B79',
  },
  LOCAL_BLUE: {
    bodyClassName: styles.localBlueBodyBackground,
    themeColor: '#cde7ff',
  },
  PINK: {
    bodyClassName: styles.pinkBodyBackground,
    themeColor: '#FCE9E6',
  },
  LIGHT_PURPLE: {
    bodyClassName: styles.lightPurpleBodyBackground,
    themeColor: '#f1eeff',
  },
  PURPLE_ANEMONE: {
    bodyClassName: styles.purpleAnemoneBodyBackground,
    themeColor: '#875FF4',
  },
  default: {
    bodyClassName: styles.whiteBodyBackground,
    themeColor: '#ffffff',
  },
  customBg: {
    bodyClassName: styles.customBg,
    themeColor: '#ffffff',
  },
};

export const StepContainer = ({
  className,
  contentContainerClassName,
  background,
  nextButtonText,
  onNextPress,
  children,
  nextButton = false,
  disabled = false,
  isQuestionStep = true,
  customBgImageProperty,
}: PropsWithChildren<{
  className?: string;
  contentContainerClassName?: string;
  background?: TpColoredBackgroundList;
  nextButtonText?: ReactNode;
  onNextPress?: () => void;
  submitButtonVariant?: TpButtonVariant;
  nextButton?: boolean;
  isQuestionStep?: boolean;
  disabled?: boolean;
  customBgImageProperty?: string;
}>) => {
  const { bodyClassName, themeColor } = useMemo(
    () =>
      backgroundMapping[
        customBgImageProperty ? 'customBg' : (background ?? 'default')
      ],
    [background, customBgImageProperty],
  );

  useEffect(() => {
    const body = document.body;
    body.classList.add(bodyClassName);
    const resultUrl = `url('${IMAGE_SOURCE}${customBgImageProperty}')`;
    body.style.setProperty('--body-bg', resultUrl ?? '#fff');

    return () => {
      body.classList.remove(bodyClassName);
      body.style.removeProperty('--body-bg');
    };
  }, [bodyClassName, customBgImageProperty]);

  return (
    <div className={classNames(styles.container, className)}>
      <Head>
        <meta name="theme-color" content={themeColor} />
      </Head>
      <div
        className={classNames(
          styles.contentContainer,
          contentContainerClassName,
          isQuestionStep && styles.optionsContainer,
        )}
      >
        {children}
      </div>
      {nextButton && onNextPress && (
        <UiKitButton
          disabled={disabled}
          fullWidth
          className={styles.nextButton}
          onClick={onNextPress}
          data-testid="next-button"
        >
          {nextButtonText ?? (
            <FormattedMessage
              defaultMessage="Continue"
              id="Onboarding.MillionPeople.Button"
            />
          )}
        </UiKitButton>
      )}
    </div>
  );
};

StepContainer.displayName = 'StepContainer';
