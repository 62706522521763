import { DEFAULT_LOCALE, LANG_TO_LOCALE } from './contants';
import type { TpLocale, TpMessages } from './types';

export const getTranslationsByLang = (locale: string): Promise<TpMessages> =>
  import(`./locales/${locale}.json`);

export const getMessages = async (lang: TpLocale): Promise<TpMessages> => {
  try {
    if (LANG_TO_LOCALE[lang]) {
      return await getTranslationsByLang(LANG_TO_LOCALE[lang]);
    }
    return await getTranslationsByLang(LANG_TO_LOCALE[DEFAULT_LOCALE]);
  } catch (error) {
    console.error(error);
    return await getTranslationsByLang(LANG_TO_LOCALE[DEFAULT_LOCALE]);
  }
};
