import { Image } from 'src/components/common/Image';

import { AdvantagesList } from '@features/PersonalPlan/components/AdvantagesList';
import { FormattedMessage } from '@features/intl';

import { ReactNode } from 'react';
import styles from './AdvantagesSection.module.scss';

export const AdvantagesSection = ({
  discountPercent = 20,
  periodUnit = 'month',
  periodDuration = 1,
  text,
}: {
  discountPercent?: number;
  periodUnit?: string;
  periodDuration?: number;
  text?: ReactNode;
}) => (
  <div className={styles.host}>
    <h2 className={styles.title}>
      If you pay today, <br /> you instantly get
    </h2>

    <div className={styles.surface}>
      <AdvantagesList />

      <div className={styles.freeHost}>
        <div className={styles.imageWrapper}>
          <Image src="free-bonus.svg" alt="free-bonus" width={45} height={51} />
        </div>
        <div className={styles.aside}>
          <h4 className={styles.freeTitle}>
            <FormattedMessage
              defaultMessage="+ Free bonus"
              id="Onboarding.Paywall.Main.Bonus.Title"
            />
          </h4>
          <p className={styles.description}>
            {text || (
              <>
                {' '}
                Extra {discountPercent}% discount on your {periodDuration}-
                {periodUnit} personal plan
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  </div>
);
