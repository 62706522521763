import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { WeightUnits } from '@features/Quiz/constants';

import WeightStep from './WeightForm/weight';

type TpQuizStepQuestionProps = {
  goToNextStep: (eventValue?: any, eventUnit?: string) => void;
};

export const Weight = observer(({ goToNextStep }: TpQuizStepQuestionProps) => {
  const {
    quizStore: { weightValue, weightUnits, setWeight },
  } = useStores();

  const selectedValue = weightValue;
  const selectedUnit = weightUnits;

  const onChange = (value: number, unit: WeightUnits) => {
    setWeight(value, unit);
  };

  return (
    <StepContainer isQuestionStep>
      <WeightStep
        value={selectedValue}
        units={selectedUnit}
        onChange={onChange}
        onNextPress={(value, units) =>
          goToNextStep({
            eventName: 'onboarding_current_weight_chosen',
            properties: { answer: value, unit: units },
          })
        }
      />
    </StepContainer>
  );
});
