import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import MultipleOptionsPilates from '@features/Quiz/components/MultipleOptionsPilates';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './BadHabits.module.scss';

export const BadHabits = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { getMultipleOptionsSelectStepValue, setBadHabits },
    } = useStores();
    const { options, stepName, skipButtonText, skipButtonId } = step;
    const selectedValues = getMultipleOptionsSelectStepValue(stepName);
    const onChange = (value: string[] | undefined | null) => {
      setBadHabits(value);
    };

    const submitHandler = useCallback(() => {
      if (handleEventSend) {
        handleEventSend({
          eventName: 'onboarding_bad_habits_chosen',
          options: {
            answer:
              selectedValues?.length === 0 ? 'none of above' : selectedValues,
          },
        });
      }
      goToNextStep();
    }, [goToNextStep, handleEventSend, selectedValues]);

    return (
      <StepContainer isQuestionStep>
        <MultipleOptionsPilates
          suffix={stepName}
          //TODO: replace TpPilatesQuizStepQuestionProps with generic
          options={options}
          value={selectedValues}
          onChange={onChange}
          onSubmit={submitHandler}
          nextButtonId="Onboarding.Habits.Button"
          startIconClassName={styles.image}
          skipButtonText={skipButtonText}
          skipButtonId={skipButtonId}
          goToNextStep={goToNextStep}
          optionsContainerClassName={styles.optionsContainer}
        />
      </StepContainer>
    );
  },
);
