import * as Sentry from '@sentry/react';
import axios from 'axios';

import { ENDPOINT_MICROSERVICE } from 'src/constants/api';
import { isDevelopment } from 'src/utils';

import { ANALYTICS_ENDPOINT, ANALYTICS_ENDPOINT_V2 } from '@features/Analytics';

const axiosMicroservice = axios.create({
  baseURL: ENDPOINT_MICROSERVICE,
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosMicroservice.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;
    Sentry.withScope((scope) => {
      scope.setExtras({
        url: error.config.url,
        method: error.config.method,
        headers: error.config.headers,
        body: error.config.data,
        status,
        response: error.response?.data,
      });
      Sentry.captureException(error);
    });
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
);

const axiosAnalytics = axios.create({
  baseURL: isDevelopment
    ? 'https://dev.analytics.mentalgrowth.app/api/v1/'
    : ANALYTICS_ENDPOINT,
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosAnalytics.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
);

const axiosAnalyticsV2 = axios.create({
  baseURL: isDevelopment
    ? 'https://analytics.v2.mentalgrowth.app/api/v1/'
    : ANALYTICS_ENDPOINT_V2,
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosAnalyticsV2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
);

export { axiosMicroservice, axiosAnalytics, axiosAnalyticsV2 };
