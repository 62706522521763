import classNames from 'classnames';
import { HTMLProps } from 'react';

import styles from './accent-progress.module.scss';

export const AccentProgress = ({
  className,
  withTransition = true,
  ...props
}: { withTransition?: boolean } & HTMLProps<HTMLProgressElement>) => (
  <progress
    className={classNames(
      styles.container,
      withTransition && styles.containerWithTransition,
      className,
    )}
    {...props}
  />
);
