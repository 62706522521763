import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOptionDoubleImage } from '@features/Quiz/components/SingleOptionDoubleImage';

import { TpQuizStepQuestionProps } from '../../types';

export const BestShapeV2 = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setBestShape },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOptionDoubleImage
        stepName={stepName}
        handleEventSend={handleEventSend}
        goToNextStep={goToNextStep}
        options={options}
        name="best-shape"
        setterFunction={setBestShape}
        leftImage="best-shape-v2-left.webp"
        eventName="onboarding_perfect_shape_v2_chosen"
        customBgImageProperty="best-shape-v2-bg.webp"
      />
    );
  },
);
