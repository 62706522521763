import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOptionDoubleImage } from '@features/Quiz/components/SingleOptionDoubleImage';

import { TpQuizStepQuestionProps } from '../../types';

export const DailyDrinkV2 = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setDailyDrinking },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOptionDoubleImage
        stepName={stepName}
        handleEventSend={handleEventSend}
        goToNextStep={goToNextStep}
        options={options}
        name="daily-drink"
        setterFunction={setDailyDrinking}
        leftImage="water-pilates-left.webp"
        eventName="onboarding_drink_water_v2_chosen"
        customBgImageProperty="daily-drink-v2-bg.webp"
      />
    );
  },
);
