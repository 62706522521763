import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import { useIntl } from '@features/intl';

import classNames from 'classnames';
import { TpQuizStepQuestionProps } from '../../types';
import styles from './EnergyLevel.module.scss';

export const EnergyLevel = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setEnergyLevelPilates, getSingleOptionSelectStepValue },
    } = useStores();
    const intl = useIntl();
    const { options, stepName } = step;
    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setEnergyLevelPilates(event.target.value);
        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_energy_level_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [handleEventSend, setEnergyLevelPilates],
    );

    return (
      <div className={styles.container}>
        <UiKitRadioGroup
          name="energy-level"
          defaultValue={value}
          onChange={handleRadioChange}
          variant="rectangle"
          onClick={goToNextStep}
          className={classNames('options-container', styles.host)}
        >
          {options.map(
            ({ value, label, image, labelId, labelDefaultMessage }) => (
              <UiKitRadioOption
                key={`${stepName}_${value}`}
                label={
                  labelId
                    ? intl.formatMessage(
                        {
                          id: labelId,
                          defaultMessage: labelDefaultMessage,
                        },
                        {
                          br: <br />,
                        },
                      )
                    : label
                }
                value={value}
                hostClassName={styles.optionHost}
                startIcon={image}
                labelClassName={styles.label}
              />
            ),
          )}
        </UiKitRadioGroup>
        <Image
          src="energy-pilates.webp"
          alt="logo"
          fill
          style={{ objectFit: 'contain' }}
        />
      </div>
    );
  },
);
