import { Typography } from '@mentalgrowth/ui-kit-web';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useCallback } from 'react';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitRadioGroup } from 'src/components/common/ui-kit-radio-group';
import { UiKitRadioOption } from 'src/components/common/ui-kit-radio-option';

import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpQuizStepQuestionProps } from '../../types';
import styles from './GoalJTBD.module.scss';

const options = [
  {
    value: 'lose_weight',
    label: 'Lose weight',
    subLabel: 'Reduce arms, legs and belly fat',
    image: <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />,
  },
  {
    value: 'get_fit',
    label: 'Get fit',
    subLabel: 'Build a strong and toned body',
    image: (
      <Image
        width={40}
        height={40}
        src="emoji-man-lifting-weights.webp"
        alt=""
      />
    ),
  },
  {
    value: 'improve_health',
    label: 'Improve health',
    image: <Image width={40} height={40} src="emoji-heart.webp" alt="" />,
    subLabel: 'Improve energy levels and sleep',
  },
];

export const GoalJTBD = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setGoalPilates, getSingleOptionSelectStepValue },
    } = useStores();

    const { stepName } = step;

    const value = getSingleOptionSelectStepValue(stepName);

    const handleRadioChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setGoalPilates(event.target.value);
        goToNextStep();

        if (handleEventSend) {
          handleEventSend({
            eventName: 'onboarding_goal_jtbd_chosen',
            options: { answer: event.target.value },
          });
        }
      },
      [goToNextStep, handleEventSend, setGoalPilates],
    );

    return (
      <StepContainer
        isQuestionStep
        background="PURPLE_ANEMONE"
        contentContainerClassName={styles.stepContainer}
      >
        <div className={styles.headImage}>
          <div className={styles.imageGrid}>
            <Image
              src="goal-jtbd-reviews.webp"
              alt="goal-jtbd-reviews"
              fill
              priority
            />
            <Image
              src="goal-jtbd-rating.webp"
              alt="goal-jtbd-rating"
              fill
              priority
            />
          </div>
        </div>
        <div>
          <Typography as="h1" className={styles.title}>
            Choose your main <br /> fitness goal
          </Typography>

          <Typography as="p" className={styles.description}>
            And we&apos;ll help you achieve it
          </Typography>
          <UiKitRadioGroup
            name="goal-jtbd"
            defaultValue={value}
            onChange={handleRadioChange}
            onClick={goToNextStep}
          >
            {options.map(({ image, ...otherProps }) => (
              <UiKitRadioOption
                key={`${stepName}_${otherProps.value}`}
                labelClassName={styles.labelClassName}
                textClassName={styles.textClassName}
                subLabelClassName={styles.subLabelClassName}
                startIcon={image}
                {...otherProps}
              />
            ))}
          </UiKitRadioGroup>
        </div>
      </StepContainer>
    );
  },
);
