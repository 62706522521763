import Cookies from 'js-cookie';

import { post } from 'src/api/api';

import { PAYMENT_ENDPOINT } from '@features/Paywall/api/payments';

const TEST_EVENT_CODE = process.env.NEXT_PUBLIC_TEST_EVENTS_CODE as string;

type TpEventName =
  | 'AddToWishlist'
  | 'AddToCart'
  | 'AddPaymentInfo'
  | 'ViewContent'
  | 'CompleteRegistration'
  | 'InitiateCheckout'
  | 'Purchase';

type TpPostPath =
  | 'event_start_onboard'
  | 'event_add_to_cart'
  | 'event_completed_registration'
  | 'event_add_payment_info'
  | 'event_user_init_checkout'
  | 'event_add_to_wish_list'
  | 'event_purchase';

type TpPostOptions = {
  email?: string;
  subscription_id?: string | number;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  stripe_subscription_id?: string | number;
  gender?: string;
  currency?: string;
};

type TpFacebookEvent = {
  eventName: TpEventName;
  postPath: TpPostPath;
  options?: { [key: string]: any };
  eventId?: string;
  postOptions?: TpPostOptions;
};

export const fbAnalytics = (props: TpFacebookEvent) => {
  const {
    eventName,
    eventId: eventIdProperty,
    options = {},
    postPath,
    postOptions = {},
  } = props;
  const externalId = Cookies.get('extId');
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');
  const randomInt = Math.floor(Math.random() * 100_000_000);

  const eventId = eventIdProperty || `${eventName}.${randomInt}`;

  const sendAnalyticRequest = async () => {
    try {
      await post({
        endpoint: PAYMENT_ENDPOINT,
        path: postPath,
        body: {
          fbp,
          fbc,
          event_id: eventId,
          external_id: externalId,
          origin: 'youth',
          test_event_code: TEST_EVENT_CODE,
          ...postOptions,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-ignore
  window?.fbq?.('track', eventName, options, {
    eventID: eventId,
    extern_id: externalId,
  });
  (fbp || externalId) &&
    process.env.NODE_ENV === 'production' &&
    sendAnalyticRequest().then((r) => r);
};
