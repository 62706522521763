import { BreaksScreen } from '@features/Quiz/components/BreaksScreen';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpSummaryStepProps } from '../../types';
import styles from './NutritionBreak.module.scss';

export const NutritionBreak = ({ step, goToNextStep }: TpSummaryStepProps) => (
  <StepContainer
    isQuestionStep={false}
    background="LIGHT_PURPLE"
    contentContainerClassName={styles.host}
  >
    <BreaksScreen
      imageWrapperClassName={styles.imageWrapperClassName}
      step={step}
      goToNextStep={goToNextStep}
      gaEventQuery="nutrition_break"
    />
  </StepContainer>
);
