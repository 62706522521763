import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOptionDoubleImage } from '@features/Quiz/components/SingleOptionDoubleImage';

import { TpQuizStepQuestionProps } from '../../types';

const options = [
  {
    value: 'gain_weight_fast_but_lose_it_slowly',
    label: 'I gain weight fast but lose it slowly',
  },
  {
    value: 'gain_and_lose_weight_easily',
    label: 'I gain and lose weight easily',
  },
  {
    value: 'struggle_to_gain_weight_or_muscle',
    label: 'I struggle to gain weight or muscle',
  },
];

export const WeightDynamic = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setFitnessExperience },
    } = useStores();

    const { stepName } = step;

    return (
      <SingleOptionDoubleImage
        stepName={stepName}
        handleEventSend={handleEventSend}
        goToNextStep={goToNextStep}
        options={options}
        name="weight-dynamic"
        setterFunction={setFitnessExperience}
        leftImage="weight-dynamic-left.webp"
        eventName="onboarding_weight_dynamic_chosen"
        customBgImageProperty="weight-dynamic-bg.webp"
      />
    );
  },
);
