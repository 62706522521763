import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';

import { TpQuizStepQuestionProps } from '../../types';

export const ExercisesAvoid = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setExercisesAvoid },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOption
        stepName={stepName}
        name="exercise-avoid"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setExercisesAvoid}
        goToNextStep={goToNextStep}
        eventName="onboarding_exercise_avoid_chosen"
      />
    );
  },
);
