import classNames from 'classnames';
import React, {
  ComponentProps,
  HTMLProps,
  ReactNode,
  useCallback,
} from 'react';

import Button from '../../button/button';
import Modal from '../modal/modal';

export interface Props
  extends ComponentProps<typeof Modal>,
    Pick<HTMLProps<HTMLFormElement>, 'onSubmit'> {
  isValid?: boolean;
  isSubmitting?: boolean;
  submitButtonText?: ReactNode;
}

const FormModal = ({
  contentClassName,
  footer,
  onSubmit,
  isValid,
  isSubmitting,
  submitButtonText,
  ...props
}: Props) => {
  const FormComponent = useCallback(
    ({
      className,
      children,
      ...props
    }: React.FormHTMLAttributes<HTMLFormElement>) => (
      <form
        className={classNames('modal-dialog', className)}
        onSubmit={onSubmit}
        {...props}
      >
        <div className={classNames('modal-content', contentClassName)}>
          {children}
        </div>
      </form>
    ),
    [contentClassName, onSubmit],
  );

  return (
    <Modal
      dialogAs={FormComponent}
      footer={
        <>
          {footer}
          <Button
            // @ts-ignore
            type="submit"
            disabled={isValid === false}
            showLoadingIndicator={isSubmitting}
          >
            {submitButtonText}
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default FormModal;
