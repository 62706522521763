import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const UserModel = types
  .model('User')
  .props({
    serverId: types.number,
    isPasswordSet: types.boolean,
    email: types.string,
    isSubscriptionActive: types.boolean,
    gender: types.maybe(types.string),
    useMetricSystem: types.maybe(types.boolean),
    token: types.maybe(types.string),
    accessCode: types.maybe(types.string),
    goal: types.maybe(types.number),
    goal_weight_units: types.maybe(types.string),
    goal_weight_value: types.maybe(types.number),
    height_units: types.maybe(types.string),
    height_value: types.maybe(types.number),
    weight_units: types.maybe(types.string),
    weight_value: types.maybe(types.number),
    user_data: types.maybe(types.frozen<Record<string, any>>()),
  })
  .actions((self) => ({
    setEmail: (value: string) => {
      self.email = value;
    },
    setPasswordSet: () => {
      self.isPasswordSet = true;
    },
  }));

type UserType = Instance<typeof UserModel>;
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export interface UserSnapshot extends UserSnapshotType {}
