import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';
import { TpQuizStepQuestionProps } from '../../types';

export const TriedPilates = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setTriedPilates },
    } = useStores();

    const { options, stepName } = step;

    return (
      <SingleOption
        stepName={stepName}
        name="tried-pilates"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setTriedPilates}
        goToNextStep={goToNextStep}
        eventName="onboarding_tried_pilates_chosen"
      />
    );
  },
);
