import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import HeightStep from 'src/components/main/quiz/height-step/height-step';

import { StepContainer } from '@features/Quiz/components/StepContainer';
import { HeightUnits } from '@features/Quiz/constants';

type TpQuizStepQuestionProps = {
  goToNextStep: (eventValue?: any, eventUnit?: string) => void;
};
export const Height = observer(({ goToNextStep }: TpQuizStepQuestionProps) => {
  const {
    quizStore: { heightValue, heightUnits, setHeight },
  } = useStores();

  const selectedValue = heightValue;
  const selectedUnit = heightUnits;

  const onChange = (value: number, unit: HeightUnits) => {
    setHeight(value, unit);
  };

  return (
    <StepContainer isQuestionStep>
      <HeightStep
        value={selectedValue}
        units={selectedUnit}
        onChange={onChange}
        onNextPress={(value, units) =>
          goToNextStep({
            eventName: 'onboarding_height_chosen',
            properties: { answer: value, unit: units },
          })
        }
      />
    </StepContainer>
  );
});
