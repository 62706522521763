import { ProgressItem } from '@features/Quiz/components/progress-item';

import styles from './program-progress.module.scss';

const PROGRAMS_PROGRESS = [
  { maxProgress: 89, label: 'Energy level', progressDurations: 4500 },
  { maxProgress: 78, label: 'Stress Management', progressDurations: 2500 },
  { maxProgress: 94, label: 'Productivity', progressDurations: 3000 },
  { maxProgress: 81, label: 'Sense of Confidence', progressDurations: 3500 },
];
export const ProgramsProgress = () => (
  <div className={styles.progressBarsContainer}>
    {PROGRAMS_PROGRESS.map(({ label, maxProgress, progressDurations }) => (
      <ProgressItem
        key={label}
        maxProgress={maxProgress}
        label={label}
        progressDurations={progressDurations}
      />
    ))}
  </div>
);
