export const COOKIES_AB_TEST_NAME = 'Youth| Cookie';
export enum CookiesAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Cookie',
}

export const BREAKS_AB_TEST_NAME = 'Onboarding Breaks';
export enum BreaksAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Onboarding Breaks',
}

export const VIDEO_REVIEWS_TEST_NAME = 'video feedback';
export enum VideoReviewsAbTestCaseName {
  DEFAULT = 'A default',
  SHOW = 'B video feedback',
}

export const ONBOARDING_JTBD_TEST_NAME =
  'DEV-866 | A/B test refresh onboarding JTBD';

export enum OnboardingJtbdAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B refresh JTBD',
}

export const INTRO_OFFER_TEST_NAME = 'DEV-971 Intro Offer';

export enum IntroOfferAbTestCaseNameInfo {
  DEFAULT = 'A default',
  NEW = 'B Intro Offer',
}
