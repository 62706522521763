import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { GOAL_OPTIONS } from '@features/Quiz/PilatesOnboarding/steps/GoalPilates';

const labels = [
  'Lose weight',
  'Build muscle',
  'Flexibility',
  'Reduce stress',
  'Posture',
];

const defaultTargetDate = dayjs().add(3, 'month').format('MMMM DD');
const todayDate = dayjs();

export const usePersonalPlan = () => {
  const { quizStore } = useStores();
  const {
    goalWeightValue,
    goalPilates,
    weightUnits,
    weightValue,
    whenEvent,
    eventComing,
    goalWeightUnits,
  } = quizStore;

  const goalOptionsTransformed = GOAL_OPTIONS.map((item, index) => ({
    ...item,
    personalPlanLabel: labels[index],
  }));

  const selectedLabel = useMemo(() => {
    const selectedItem = goalOptionsTransformed.find(
      (item) => item.value === goalPilates,
    );
    return selectedItem?.personalPlanLabel;
  }, [goalOptionsTransformed, goalPilates]);

  const weightProgress = useMemo(
    () => `${(goalWeightValue ?? 0) - (weightValue ?? 0)} ${weightUnits}`,
    [goalWeightValue, weightUnits, weightValue],
  );

  const whenEventDate = dayjs(whenEvent);
  const weightPerDay = ((weightValue ?? 70) - (goalWeightValue ?? 65)) / 90;
  const daysUntilEvent = whenEventDate.diff(todayDate, 'day');
  const calculatedWeight = (daysUntilEvent * (weightPerDay || 0)).toFixed(2);

  let totalTooltip = '';

  if (
    eventComing === 'no_special_events' ||
    eventComing === 'Other' ||
    whenEvent === null
  ) {
    totalTooltip = `We have chosen the optimal timeframe <br /> <strong>to avoid any harm to your body and mind</strong>`;
  } else if (defaultTargetDate) {
    totalTooltip = whenEventDate.isAfter(defaultTargetDate)
      ? `We predict you’ll be <strong>${calculatedWeight} ${goalWeightUnits}</strong> by <br /> <strong>${whenEventDate.format(
          'MMMM D, YYYY',
        )}</strong>`
      : `Just in time for your <br /> <strong>${eventComing}</strong>`;
  }

  return {
    goalLabel: selectedLabel,
    weightProgress,
    weightValue,
    weightUnits,
    goalWeightValue,
    defaultTargetDate,
    totalTooltip,
  };
};
