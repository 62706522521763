import { UiKitButton } from 'src/components/common/ui-kit-button';

import BMIComponent from '@features/Quiz/PilatesOnboarding/steps/WellnessProfile/BMIComponent';
import { StepContainer } from '@features/Quiz/components/StepContainer';
import { FormattedMessage, TpMessageKeys } from '@features/intl';

import { TpSummaryStepProps } from '../../types';
import styles from './WelnessProfile.module.scss';

export const WellnessProfile = ({ goToNextStep, step }: TpSummaryStepProps) => {
  const { titleId } = step;
  return (
    <StepContainer
      className={styles.container}
      isQuestionStep={false}
      background="LIGHT_BLUE"
    >
      <h2 className={styles.title}>
        <FormattedMessage
          defaultMessage="Here’s your wellness profile"
          id={titleId as TpMessageKeys}
        />
      </h2>
      <BMIComponent />
      <UiKitButton
        className={styles.submitButton}
        type="submit"
        onClick={() =>
          goToNextStep({ eventName: 'onboarding_wellness_profile_tapped' })
        }
        fullWidth
        data-testid="next-button"
      >
        <FormattedMessage
          id="Onboarding.Profile.Button"
          defaultMessage="Continue"
        />
      </UiKitButton>
    </StepContainer>
  );
};
