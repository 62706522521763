import classNames from 'classnames';
import React, { memo } from 'react';

import styles from 'src/widgets/policy/components/links.module.scss';

import { useAnalytics } from '@features/Analytics';
import { FormattedMessage } from '@features/intl';

export const TermsLink = memo(
  ({
    place,
    className,
    children,
  }: {
    className?: string;
    place: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal' | 'upsale';
    children?: React.ReactNode;
  }) => {
    const { trackGoogleEvent } = useAnalytics();
    return (
      <a
        className={classNames(styles.link, className)}
        href="https://legal.mentalgrowth.app/youth/tos.pdf"
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          trackGoogleEvent({
            eventName: 'link_open',
            options: {
              place: place,
              link_name: 'terms',
            },
          })
        }
      >
        {children || (
          <FormattedMessage
            id="Onboarding.Paywall.Main.Contact.Terms.A"
            defaultMessage="Terms of Service"
          />
        )}
      </a>
    );
  },
);

TermsLink.displayName = 'TermsLink';
