import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';
import { StepContainer } from '@features/Quiz/components/StepContainer';

import { TpQuizStepQuestionProps } from '../../types';

export const PreferWorkout = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setPreferWorkout },
    } = useStores();
    const { options, stepName } = step;

    return (
      <StepContainer>
        <SingleOption
          stepName={stepName}
          name="prefer-workout"
          options={options}
          handleEventSend={handleEventSend}
          setterFunction={setPreferWorkout}
          goToNextStep={goToNextStep}
          eventName="onboarding_prefer_workout_chosen"
        />
      </StepContainer>
    );
  },
);
