import { observer } from 'mobx-react-lite';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  MAX_WEIGHT_KG,
  MAX_WEIGHT_LBS,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LBS,
} from 'src/constants/common';
import { kgToLbs, lbsToKg } from 'src/utils/weight-units';

import { WeightUnits } from '@features/Quiz/constants';
import { TpMessageKeys, useIntl } from '@features/intl';

import { TabContent } from './tabContent';
import styles from './weight.module.scss';

const getLbsWeight = (value?: number, units?: WeightUnits) =>
  value == null || units === WeightUnits.LBS ? value : kgToLbs(value);

const getKgWeight = (value?: number, units?: WeightUnits) =>
  value == null || units === WeightUnits.KG ? value : lbsToKg(value);

export type Props = {
  value?: number;
  units?: WeightUnits;
  onChange: (value: number, unit: WeightUnits) => void;
  onNextPress?: ((value: number, units: WeightUnits) => void) | undefined;
};

const WeightStep = observer(
  ({ value, units, onChange, onNextPress }: Props) => {
    const intl = useIntl();

    const formatPlaceholder = (id: TpMessageKeys, defaultMessage: string) =>
      intl.formatMessage({
        asString: true,
        id,
        defaultMessage,
      }) as string;

    const renderTabContent = (
      unit: WeightUnits,
      controlMin: number,
      controlMax: number,
      placeholderId: TpMessageKeys,
      defaultPlaceholder: string,
      getWeight: (value?: number, units?: WeightUnits) => number | undefined,
    ) => (
      <TabContent
        onChange={(currentWeight) => onChange(currentWeight, unit)}
        controlMin={controlMin}
        controlMax={controlMax}
        controlPlaceholder={formatPlaceholder(
          placeholderId,
          defaultPlaceholder,
        )}
        currentWeight={getWeight(value, units)}
        unit={unit}
        onNextPress={onNextPress}
      />
    );

    return (
      <div className={styles.container}>
        <Tabs
          id="weight-step"
          className={styles.tabs}
          defaultActiveKey={units ?? WeightUnits.LBS}
        >
          <Tab eventKey={WeightUnits.LBS} title="lbs">
            {renderTabContent(
              WeightUnits.LBS,
              MIN_WEIGHT_LBS,
              MAX_WEIGHT_LBS,
              'Onboarding.Weight.Сlue',
              'Weight (lbs)',
              getLbsWeight,
            )}
          </Tab>
          <Tab eventKey={WeightUnits.KG} title="kg">
            {renderTabContent(
              WeightUnits.KG,
              MIN_WEIGHT_KG,
              MAX_WEIGHT_KG,
              'Onboarding.Weight.Сlue:79',
              'Weight (kg)',
              getKgWeight,
            )}
          </Tab>
        </Tabs>
      </div>
    );
  },
);

export default WeightStep;
