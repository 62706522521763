import classNames from 'classnames';

import { UiKitButton } from 'src/components/common/ui-kit-button';

import { TpPilatesQuizStep } from '@features/Quiz/PilatesOnboarding/types';
import { StepContainer } from '@features/Quiz/components/StepContainer';
import { TpColoredBackgroundList } from '@features/Quiz/types';
import { FormattedMessage } from '@features/intl';

import { TpGoToNextStep } from '../../types';
import styles from './BreaksScreen.module.scss';

type TpProps = {
  step: TpPilatesQuizStep;
  goToNextStep: TpGoToNextStep;
  gaEventQuery?: string;
  backgroundColor?: TpColoredBackgroundList;
  imageWrapperClassName?: string;
};

export const BreaksScreen = ({
  step,
  goToNextStep,
  backgroundColor,
  gaEventQuery,
  imageWrapperClassName,
}: TpProps) => (
  <>
    <StepContainer background={backgroundColor} isQuestionStep={false}>
      <div className={classNames(styles.imageWrapper, imageWrapperClassName)}>
        {step.image}
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {step.title || (
            <FormattedMessage
              defaultMessage={step.defaultTitleMessage ?? ''}
              id={step.titleId!}
              values={{
                br: (
                  <b style={{ color: '#875FF4' }}>
                    <FormattedMessage
                      defaultMessage={step.defaultTitleMessageMark ?? ''}
                      id={step.titleIdMark!}
                    />
                  </b>
                ),
              }}
            />
          )}
        </h2>
        <h3 className={styles.subtitle}>
          {step.subtitle || (
            <FormattedMessage
              defaultMessage={step.defaultSubtitleMessage ?? ''}
              id={step.subtitleId!}
            />
          )}
        </h3>
      </div>
    </StepContainer>

    {gaEventQuery && (
      <UiKitButton
        style={{ alignSelf: 'center' }}
        type="submit"
        fullWidth
        onClick={() =>
          goToNextStep({
            eventName: 'onboarding_button_tapped',
            properties: { onboarding_variant: gaEventQuery },
          })
        }
        data-testid="next-button"
      >
        {step.nextButton || (
          <FormattedMessage
            defaultMessage={step.nextButtonText ?? ''}
            id={step.nextButtonTextId!}
          />
        )}
      </UiKitButton>
    )}
  </>
);
