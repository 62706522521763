import classNames from 'classnames';
import ImageTag from 'next/image';
import type { ImageProps } from 'next/image';

import { IMAGE_SOURCE } from 'src/utils/constants';

import styles from './Image.module.scss';

export type TpImageProps = ImageProps & {
  className?: string;
};

const customLoader = ({ src }: { src: string }) => `${IMAGE_SOURCE}${src}`;

export const Image = (props: TpImageProps) => {
  const { src, className, ...otherProps } = props;

  return (
    <span className={classNames(styles.container, className)}>
      <ImageTag src={src} loader={customLoader} {...otherProps} />
    </span>
  );
};
