import { observer } from 'mobx-react-lite';

import { Image } from 'src/components/common/Image';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';

import { TpQuizStepQuestionProps } from '../../types';

const options = [
  {
    value: 'feel_more_confident',
    label: 'Feel more confident in my body',
    image: <Image width={40} height={40} src="emoji-sunglasses.webp" alt="" />,
  },
  {
    value: 'feel_more_healthier',
    label: 'Feel more healthier and more energetic',
    image: <Image width={40} height={40} src="emoji-arm-strong.webp" alt="" />,
  },
  {
    value: 'fit_in_my_clothes_better',
    label: 'Fit in my clothes better',
    image: <Image width={40} height={40} src="emoji-clothes.webp" alt="" />,
  },
  {
    value: 'get_back_in_shape',
    label: 'Get back in shape after giving birth',
    image: <Image width={40} height={40} src="emoji-baby-bottle.webp" alt="" />,
  },
  {
    value: 'other',
    label: 'Other',
    image: (
      <Image width={40} height={40} src="emoji-champion-cup.webp" alt="" />
    ),
  },
];

export const StayFocused = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setStayFocused },
    } = useStores();
    const { stepName } = step;

    return (
      <SingleOption
        stepName={stepName}
        name="stay-focused"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setStayFocused}
        goToNextStep={goToNextStep}
        eventName="onboarding_stay_focused_chosen"
      />
    );
  },
);
