import { observer } from 'mobx-react-lite';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { SingleOption } from '@features/Quiz/components/SingleOption';

import { TpQuizStepQuestionProps } from '../../types';

export const PhysicalBuild = observer(
  ({ step, goToNextStep, handleEventSend }: TpQuizStepQuestionProps) => {
    const {
      quizStore: { setPhysicalBuild },
    } = useStores();
    const { options, stepName } = step;

    return (
      <SingleOption
        doubleImageWidth
        stepName={stepName}
        name="physical-build"
        options={options}
        handleEventSend={handleEventSend}
        setterFunction={setPhysicalBuild}
        goToNextStep={goToNextStep}
        eventName="onboarding_current_body_chosen"
      />
    );
  },
);
