import { FormattedMessage as Message } from 'react-intl';

import { TpFormattedMessage } from '../../types';

export const FormattedMessage = (props: TpFormattedMessage) => {
  const { defaultMessage, id, asString, ...otherProps } = props;

  return asString ? (
    <Message
      data-trans={id}
      id={id}
      defaultMessage={defaultMessage}
      {...otherProps}
    />
  ) : (
    <span data-trans={id}>
      <Message
        data-trans={id}
        id={id}
        defaultMessage={defaultMessage}
        {...otherProps}
      />
    </span>
  );
};
