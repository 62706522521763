export enum MobileOperatingSystem {
  ANDROID,
  IOS,
  UNKNOWN,
}

let mobileOperatingSystemMemoized: MobileOperatingSystem | undefined;

export function getMobileOperatingSystem() {
  if (mobileOperatingSystemMemoized != null) {
    return mobileOperatingSystemMemoized;
  }

  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    mobileOperatingSystemMemoized = MobileOperatingSystem.ANDROID;
  } else if (/iPod|iPhone|iPad/.test(userAgent)) {
    mobileOperatingSystemMemoized = MobileOperatingSystem.IOS;
  } else {
    mobileOperatingSystemMemoized = MobileOperatingSystem.UNKNOWN;
  }

  return mobileOperatingSystemMemoized;
}

export enum IosDeviceType {
  IPOD,
  IPHONE,
  IPAD,
  UNKNOWN,
}

let iosDeviceTypeMemoized: IosDeviceType | undefined;

export function getIosDeviceType() {
  if (iosDeviceTypeMemoized != null) {
    return iosDeviceTypeMemoized;
  }

  const userAgent = navigator.userAgent;

  if (/iPod/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPOD;
  } else if (/iPhone/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPHONE;
  } else if (/iPad/.test(userAgent)) {
    iosDeviceTypeMemoized = IosDeviceType.IPAD;
  } else {
    iosDeviceTypeMemoized = IosDeviceType.UNKNOWN;
  }

  return iosDeviceTypeMemoized;
}

export function isIosHandheld() {
  const iosDeviceType = getIosDeviceType();
  return (
    iosDeviceType === IosDeviceType.IPOD ||
    iosDeviceType === IosDeviceType.IPHONE
  );
}
